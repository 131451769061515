import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Common from '@/utils/common';
import * as Config from '@/config'
import { getApply } from '@/api/applies';
import { useLoadingStore } from '@/stores/loading';
import { useProjectStore } from '@/stores/project';
import { useAreaListStore } from '@/stores/areaList';
import { getAreaList } from '@/api/areas';
import { useUserStore } from '@/stores/user';

export interface Apply {
  id?: number,
  amount: number,
  consumption_tax: number,
  data_apply_products: ApplyProduct[]
}

export interface ApplyProduct {
  id?: number,
  sales_product_id: number,
  quantity: number,
  unit_price: number,
  remarks?: string,
}

export default function ApplyAreaList() {
  const navigate = useNavigate();
  const params = useParams();
  const setLoading = useLoadingStore(state => state.setLoading);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const [areaList, setAreaList] = useAreaListStore(state => [
    state.areaList,
    state.setAreaList,
  ]);
  const user = useUserStore((state) => state.user);

  const [apply, setApply] = useState<Apply>();
  const [applyAreas, setApplyAreas] = useState<any[]>([]);

  const headerTop = (document.querySelector("header")?.offsetHeight ?? 0)
    + (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  useEffect(() => {
    const dataId = params.dataId;
    const selectedProjectCategoryId = selectedProjectCategory?.id;

    if (dataId && selectedProjectCategoryId) {
      setLoading(true);
      let promises = [
        getApply({
          data_id: parseInt(dataId),
          project_category_id: selectedProjectCategoryId,
        }).catch(error => error),
      ];
      let areas: any[] = [];
      if (areaList) {
        areas = areaList.area_list;
      } else {
        promises.push(getAreaList({
          project_category_id: selectedProjectCategoryId,
        }).catch(error => error));
      }
      Promise.all(promises).then((res) => {
        if (res.length > 1) {
          const area_list = res[1].data.area_list;
          if (area_list && area_list.length > 0) {
            areas = area_list;
            setAreaList(res[1].data);
          }  
        }
        let apply = res[0].data?.apply ?? {
          amount: 0,
          consumption_tax: 0,
          data_apply_products: [],
        };
        setApply(apply);
        setApplyAreas(apply.data_apply_products.map((product: ApplyProduct) => {
          return {
            areas: Common.getFullAreas(product.sales_product_id, areas),
            quantity: product.quantity,
            remarks: product.remarks,
          };
        }));
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onClickEdit = () => {
    navigate(Common.applyAreaListEditUrl(params.projectCategoryId, params.dataId));
  }

  return (
    <div>
      <div className="uk-flex uk-flex-right uk-flex-middle sub-header" style={{top: headerTop}}>
        {Common.canApplyArea(parseInt(params.projectCategoryId ?? '', 10), user) === Config.RESTRICTION_HISTORY_APPLY_AREA_EDIT.value &&
          <button className="uk-button--m uk-button-refer uk-margin-small-top" onClick={onClickEdit}>編集</button>
        }
      </div>
      <div className="container uk-margin-small-top">
        <div className="info-tbl">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  請求金額
                </td>
                <td className="content">
                  ¥ {Common.formatPrice(apply?.amount ?? 0)}
                </td>
                <td className="label">
                  消費税
                </td>
                <td className="content">
                  ¥ {Common.formatPrice(apply?.consumption_tax ?? 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="apply-tbl uk-margin-top">
            <thead>
              <tr>
                <th></th>
                <th>申込エリア</th>
                <th>単価</th>
                <th>数量</th>
                <th>金額</th>
                <th>備考</th>
              </tr>
            </thead>
            <tbody>
              {applyAreas.map((applyArea, index) => {
                return (
                  <tr key={index}>
                    <td className="uk-text-center">
                      {index + 1}
                    </td>
                    <td>{applyArea.areas.map((area: any) => area.name).join(' - ')}</td>
                    <td className="uk-text-right">¥ {Common.formatPrice(applyArea.areas[applyArea.areas.length - 1].amount ?? 0)}</td>
                    <td className="uk-text-right">{applyArea.quantity}</td>
                    <td className="uk-text-right">¥ {Common.formatPrice((applyArea.areas[applyArea.areas.length - 1].amount ?? 0) * applyArea.quantity)}</td>
                    <td>{applyArea.remarks}</td>
                  </tr>
                )
              })
              }
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="header">
                  金額合計（税抜）
                </td>
                <td colSpan={2} className="uk-text-right">
                  ¥ {Common.formatPrice(applyAreas.map((applyArea) => applyArea.areas[applyArea.areas.length - 1].amount * applyArea.quantity).reduce((a, b) => a + b, 0))}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}