import React, { useCallback, useEffect, useState } from 'react';
import CustomSelect from '@/components/CustomSelect';
import { useLoadingStore } from "@/stores/loading";
import { importCsv } from "@/api/data";
import { ERROR_MESSAGE_E23, importOptions, MESSAGE_NO_E39, SUCCESS_MESSAGE_E24 } from "@/config";
import ConfirmModal from "@/components/ConfirmModal";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface error {
  importType?: string,
  file?: string,
}

export default function DataImport() {
  const setLoading = useLoadingStore(state => state.setLoading);

  const [isShowModal, setIsShowModal] = useState(false);
  const [importType, setImportType] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<error | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  const onDrop = useCallback(( files: File[] ) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  }, []);
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    multiple: false,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const handleSelectChange = ( event: any ) => {
    setImportType(event.target.value);
  };

  const handleFileImportChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const onClickClear = () => {
    setFile(null);
    setResultMessage(null);
  }

  const onClickImport = async ( e: any ) => {
    setResultMessage(null);
    let messages: any = {}
    if (!file) {
      messages["file"] = "必須項目です。";
    }
    if (!importType) {
      messages["importType"] = "必須項目です。";
    }
    if (!file || !importType) {
      e.preventDefault();
      setError(messages);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    await importCsv(formData, importType)
      .then(( result ) => {
        // console.log(result);
        setResultMessage(SUCCESS_MESSAGE_E24);
      }).catch(( error ) => {
        // console.log(error);
        if (!error.response) {
          setResultMessage(MESSAGE_NO_E39)
          return;
        }
        //エラーファイルダウンロード処理
        const contentType = error.response.headers["content-type"];
        if (contentType === "text/csv; charset=UTF-8" && error.response.status === 400) {
          const blob = new Blob([error.response.data], { type: contentType });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = file.name.replace(`.csv`, `(error).csv`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(downloadUrl);
        }

        if (error.response.data["message"]) {
          setResultMessage(error.response.data.message);
        } else {
          setResultMessage(ERROR_MESSAGE_E23);
        }
      }).finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    resultMessage && setIsShowModal(true);
  }, [resultMessage])

  return (
    <div>
      <div className="precaution">※は必須です</div>
      <form>
        <div className="admin-tbl uk-margin-small-top">
          <table className="uk-width-1-1">
            <tr>
              <td>
                インポート先選択<span className="error">※</span>
                {error?.importType && <span className="precaution">{error.importType}</span>}
              </td>
              <td>
                <div className="width-40p uk-flex">
                  <CustomSelect
                    name="import"
                    placeholder="インポート先を選択してください"
                    options={importOptions}
                    onChange={handleSelectChange}
                    required
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                ファイル選択<span className="error">※</span>
                {error?.file && <span className="precaution">{error.file}</span>}
              </td>
              <td>
                <div
                  {...getRootProps()}
                  className="file-select uk-form-custom"
                >
                  <input
                    type="file"
                    {...getInputProps()}
                    onChange={handleFileImportChange}
                  />
                  <button
                    className="uk-button--m uk-button-cancel"
                    type="button"
                  >
                    ファイル選択
                  </button>
                  <div>{file?.name}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </form>
      <div className="uk-flex uk-flex-center uk-margin-top">
        <button className="uk-button--m uk-button-cancel" onClick={onClickClear}>クリア</button>
        <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickImport}>インポート</button>
      </div>
      {resultMessage &&
          <ConfirmModal
              text={resultMessage}
              confirmButtonText="OK"
              isShow={isShowModal}
              onConfirm={() => {
                setIsShowModal(false);
                setResultMessage(null);
              }}
          />
      }
    </div>
  );
}