import {RefObject, useEffect, useRef} from "react";

export const useFixedColumns = (tableRef: RefObject<HTMLTableElement>) => {

  const updateFixedColumnPositions = () => {
    const table = tableRef.current;
    if (!table) return;

    const headerCells = table.querySelectorAll('th');
    let leftPosition = 0;

    for (let i = 0; i < 6; i++) {
      if (headerCells[i]) {
        const width = headerCells[i].offsetWidth;
        table.style.setProperty(`--left-${i + 1}`, `${leftPosition}px`);
        leftPosition += width;
      }
    }
  };

  useEffect(() => {
    updateFixedColumnPositions();
    window.addEventListener('resize', updateFixedColumnPositions);

    return () => {
      window.removeEventListener('resize', updateFixedColumnPositions);
    };
  }, []);
}