import React, { useState } from "react";
import * as Config from "@/config";
import * as PfAPI from "@/api/pf";
import { useLoadingStore } from "@/stores/loading";
import { useMessageModalStore } from "@/stores/message";
import { useFormContext } from "react-hook-form";
import { useMapDrawing } from "@/stores/mapDrawing";

interface MapDrawingErrors {
  prefecture?: string;
  city?: string;
  confirm?: string;
}

export const ViewMapButton = ( { attribute }: { attribute: any } ) => {
  const [mapDrawingErrors, setMapDrawingErrors] = useState<MapDrawingErrors | null>(null);
  const setLoading = useLoadingStore(( state ) => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const { setP_id, setCoordinate, currentLocation } = useMapDrawing();
  const { setValue } = useFormContext<{
    attributes: Array<any>
  }>();
  const onClickMapConfirm = async () => {
    if (!(currentLocation.featureKey.length > 0)) {
      setMapDrawingErrors({ confirm: Config.MESSAGE_NO_E72 })
      return;
    }
    setLoading(true);
    const authRes = await PfAPI.authentication();
    let coordinateInfo;
    await PfAPI.getCoordinate(authRes, currentLocation.featureKey).then(result => {
      if (!result) {
        setMapDrawingErrors({ confirm: Config.MESSAGE_NO_E72 })
        setLoading(false);
        return;
      }
      setValue(`attributes.${attribute.id}`, JSON.stringify(currentLocation));
      coordinateInfo = result;
    }).catch(() => {
      setMessage(Config.MESSAGE_NO_E39);
      setLoading(false);
      return;
    })
    setCoordinate(coordinateInfo);
    PfAPI.getP_id(coordinateInfo, authRes).then(result => {
      typeof result === 'string' && setP_id(result)
    }).catch(() => {
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <button
        className="uk-button--m uk-button-refer uk-position-absolute uk-position-center-right uk-margin-small-right"
        type="button"
        onClick={onClickMapConfirm}
      >
        地図表示
      </button>

      {mapDrawingErrors?.confirm && <div className="error">{`${mapDrawingErrors.confirm}`}</div>}
    </>
  )
}