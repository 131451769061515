import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';
import {useLoadingStore} from '@/stores/loading'
import {axios} from '@/lib/axios'
import {User, useUserStore} from '@/stores/user'
import * as Config from '@/config'
import * as Common from '@/utils/common'

type SubHeaderProps = {
  title?: string,
  breadCrumbs: { title: string, path?: string }[],
  showPrompt?: boolean,
}

// TODO: Headerに移動 → 要検討: パンくずリストの生成(useParamsで取得できない)
export default function SubHeader(props: SubHeaderProps) {
  const navigate = useNavigate();
  const setLoading = useLoadingStore(state => state.setLoading);
  const [user, setUser] = useUserStore(state => [state.user, state.setUser]);

  const [destination, setDestination] = useState('');
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);

  const canAdmin = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
      case Config.PERMISSION_ROLL_ADMIN:
        return true;
      case Config.PERMISSION_ROLL_USER:
      default:
        return false;
    }
  }

  const onClickLink = (pathname: string) => {
    if (props.showPrompt) {
      setDestination(pathname);
    } else {
      navigate(pathname);
    }
  }

  const showBreadCrumbs = () => {
    return (
      <div className="uk-breadcrumb">
        {props.breadCrumbs.map((breadCrumb) => {
          if (breadCrumb.path == null) {
            return <li>{breadCrumb.title}</li>;
          } else {
            return <li><a onClick={() => onClickLink(breadCrumb.path!)}>{breadCrumb.title}</a></li>;
          }
        })}
      </div>
    )
  }

  const onClickReport = () => {
    navigate("/report");
  }

  const onClickBilling = () => {
    navigate("/customer");
  }

  const onClickProgress = () => {
    navigate("/progress");
  }

  const onClickAdmin = () => {
    navigate("/admin");
  }

  const onClickLogout = () => {
    setModalConfirm({callback: () => {
        setLoading(true);
        axios.post('/api/v1/users/logout', {
        }).then(result => {
          setUser(undefined);
          navigate('/login');
        }).catch(error => {
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  }

  return (
    <div>
      <div className="uk-flex-between uk-flex">
        {showBreadCrumbs()}
        <div>
          <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickReport}>帳票</button>
          {Common.canBilling(user) && <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickBilling}>顧客一覧画面</button>}
          <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickProgress}>進捗一覧画面</button>
          {canAdmin(user) && <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickAdmin}>管理画面</button>}
          <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickLogout}>ログアウト</button>
        </div>
      </div>

      {props.title != null &&
        <div className="sub-header-title">
          <div className="stick"></div>
          <div>{props.title}</div>
        </div>}

      <ConfirmModal
        text="画面遷移しますか？入力したデータは登録されません。"
        confirmButtonText="OK"
        isShow={destination != ''}
        onConfirm={() => {
          setDestination('');
          navigate(destination);
        }}
        onCancel={() => {
          setDestination('');
        }}
      />
      <ConfirmModal
        text={Config.MESSAGE_NO_E25}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if(modalConfirm)modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  )
}