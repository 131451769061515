import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import DataImport from './DataImport';
import { DataExport } from "@/containers/admin/DataManagement/DataExport";
import ConfirmModal from "@/components/ConfirmModal";
import { useUserStore } from "@/stores/user";
import { canDataExportManagement, canDataImportManagement } from "@/utils/common";
import * as Common from "@/utils/common";
import * as Config from "@/config";

export default function DataManagement() {
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const [selectedTab, setSelectedTab] = useState("")
  const [modalMessage, setModalMessage] = useState<{ type: {}, message: string } | null>(null)

  const canDataManagement = Common.canDataExportManagement(user) === Config.RESTRICTION_HISTORY_DATA_EXPORT_OK.value
    || Common.canDataImportManagement(user) === Config.RESTRICTION_HISTORY_DATA_IMPORT_OK.value;
  const canImport = canDataImportManagement(user) === Config.RESTRICTION_HISTORY_DATA_IMPORT_OK.value;
  const canExport = canDataExportManagement(user) === Config.RESTRICTION_HISTORY_DATA_EXPORT_OK.value;

  const breadCrumbsTitle = ( value: string ) => {
    switch (value) {
      case "import":
        setSelectedTab("インポート")
        break
      case "export":
        setSelectedTab("エクスポート")
        break
      default:
        break
    }
  }

  const breadCrumbs = [
    { title: '管理画面', path: '/admin' },
    { title: selectedTab }
  ];

  useEffect(() => {
    if (!canDataManagement) {
      navigate('/admin');
    }
    if (canImport) {
      setSelectedTab("インポート")
    } else if (canExport) {
      setSelectedTab("エクスポート")
    }
  }, [])

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={selectedTab}/>
      </div>
      <div className="container">
        <ul className="uk-tab uk-margin-small-top" data-uk-tab="{connect:'#data-tab'}">
          {canImport &&
              <li onClick={() => breadCrumbsTitle("import")} className="uk-active uk-data">
                  <Link to="/admin/data">インポート</Link>
              </li>
          }
          {canExport &&
              <li onClick={() => breadCrumbsTitle("export")} className="uk-active uk-data">
                  <Link to="/admin/data">エクスポート</Link>
              </li>
          }
        </ul>
        <ul id="data-tab" className="uk-switcher">
          {canImport && <li><DataImport/></li>}
          {canExport && <li><DataExport setModalMessage={setModalMessage}/></li>}
        </ul>
      </div>
      <ConfirmModal
        text={modalMessage?.message ?? ""}
        confirmButtonText="OK"
        isShow={!!modalMessage?.message}
        onConfirm={() => setModalMessage(null)}
      />
    </div>
  );
}