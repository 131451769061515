import { axios } from '@/lib/axios';
import {UserInfo} from "@/containers/admin/Users/types";

export const getLoginUserInfo = () => {
  return axios.post('/api/v1/users/getLoginUserInfo')
}

export const getUsers = ( project_id: number ) => {
  return axios.get('/api/v1/users/get-users', {
    params: { project_id }
  })
}

export const getUserData = ( user_id: string | undefined = undefined ): Promise<any> => {
  const params = user_id !== undefined ? { user_id } : {};
  return axios.get('/api/v1/users/get-user-data', {
    params
  });
}

export const getDuplicateData = ( user_id: string | undefined ): Promise<any> => {
  return axios.get('/api/v1/users/get-duplicate-data', {
    params: { user_id }
  })
}

export const createUser = ( user_info: UserInfo ): Promise<any> => {
  return axios.post('/api/v1/users/create-user', user_info)
}

export const updateUser = ( user_info: UserInfo ): Promise<any> => {
  return axios.post('/api/v1/users/update-user', user_info)
}

export const deleteUser = ( user_id: number, project_id: number ): Promise<any> => {
  return axios.delete('/api/v1/users/delete-user', {
    params: { user_id, project_id }
  })
}

export const resetPassword = ( user_id: number, project_id: number ): Promise<any> => {
  return axios.get('api/v1/users/reset-password', {
    params: { user_id, project_id }
  })
}

export const searchUsers = ( keyword: string ): Promise<any> => {
  return axios.get('api/v1/users/search', {
    params: { keyword }
  });
}