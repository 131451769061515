import create from 'zustand';

type MessageModalStore = {
    message: string;
    setMessage: (message: string) => void;
};

export const useMessageModalStore = create<MessageModalStore>((set) => ({
    message: "",
    setMessage: (message) =>
        set((state) => ({
            message: message,
        })),
}));
