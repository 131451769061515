import React, {useEffect, useState} from 'react';
import CustomSelect from "@/components/CustomSelect";
import {getDrmApiUsagesData, getDrmPfBaseData} from "@/api/customer";
import {useLoadingStore} from "@/stores/loading";
import ConfirmModal from "@/components/ConfirmModal";
import {
  IS_BILLING_FLG,
  MESSAGE_NO_E39,
} from "@/config";
import {useNavigate, useParams} from 'react-router-dom';
import {DrmPfApiUsagesOptions, useDrmPfApiUsages} from "@/containers/customer/drmPf/usages/useDrmPfApiUsages";
import {Bills, Fees, Logs, Months} from "@/containers/customer/drmPf/types";
import {DrmPfCommonState, useCustomerStore} from "@/stores/customer";

export default function APIUsagesDetail() {
  const UsagesOptions = DrmPfApiUsagesOptions();
  const {CurrentDate} = useDrmPfApiUsages();
  const customerId = useParams().customerId;
  const setLoading = useLoadingStore(state => state.setLoading);
  const [setDrmPfCommonState, drmPfCommonState] = useCustomerStore(state => [state.setDrmPfCommonState, state.drmPfCommonState]);

  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState<Logs[]>([]);
  const [fees, setFees] = useState<Fees[]>([]);
  const [bills, setBills] = useState<Bills[]>([]);
  const [month, setMonth] = useState<string | undefined>(CurrentDate);
  const [billingFlg, setBillingFlg] = useState<string | null>(null);
  const [selectMonth, setSelectMonths] = useState<Months[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  const navigate = useNavigate();

  const onClickToggle = () => setOpen((prev) => !prev);
  const handleChange = (e: any) => {
    setMonth(e.target.value);
    updateDrmApiData(e.target.value);
  }

  function updateDrmApiData(month: string | undefined) {
    setLoading(true)
    const response = getDrmApiUsagesData(month, Number(customerId));
    response.then(result => {
      setLogs(result.data.displayLogData);
      setBills(result.data.billData);
      setFees(result.data.apiGroup);
      setSelectMonths(result.data.selectMonths);
      console.log(result.data);
      if (result.data.logData[0]['is_billing'] != null) {
        const billig_flg = IS_BILLING_FLG.find((output, index) => {
          return output.value == result.data.logData[0]['is_billing'];
        });
        setBillingFlg(billig_flg?.label || '');
      }
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getDrmPfBaseData(customerId).then(result => {
      const drmpf = result?.data?.drmpf[0];
      setDrmPfCommonState({
        customerNumber: drmpf.customer_number,
        modified: drmpf.modified,
        modified_by: drmpf.modified_by
      })
    }).catch(error => {
      if (!error.response) {
        setErrorMessage(MESSAGE_NO_E39);
      }
    });
    updateDrmApiData(month);
  }, []);

  return (
    <div className="info-tbl_wrap uk-margin-small-top">
      <table className="API-table uk-width-1-1">
        <tbody>
        <tr>
          <th className="label">照会年月</th>
          <td className="content">
            <div className="uk-inline">
              <CustomSelect
                name="query"
                className=""
                options={selectMonth}
                value={CurrentDate()}
                onChange={handleChange}
              />
            </div>
            <span className="uk-margin-small-left">※暫定</span>
          </td>
        </tr>
        </tbody>
      </table>

      <div className="uk-width-1-1 uk-overflow-auto">
        <table className="API-table uk-margin-small-top">
          <thead>
          <tr>
            {UsagesOptions.drm_pf_api_summary_th.map((item, index) => {
              const replaced_label = item.label.split(/(\n)/).map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.match(/\n/) ? <br/> : item}
                  </React.Fragment>
                );
              });
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className={`label uk-text-center ${item.bold && "uk-text-bold"}`}
                    style={style}
                    rowSpan={item.rowspan}
                    colSpan={item.colspan}
                    key={index}
                >
                  {replaced_label}
                </th>
              );
            })}
          </tr>
          <tr>
            {UsagesOptions.drm_pf_api_summary_th_2nd.map((item, index) => {
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
              );
            })}
          </tr>
          </thead>
          <tbody>
          {Object.values(bills).length !== 0 && Object.values(bills).map((item, index) => (
            <tr key={index}>
              <td className="uk-text-center">{item.paymentType}</td>
              <td className="uk-text-right">¥ {item.basicFee}</td>
              <td className="uk-text-right">{item.validTotalAll} 件</td>
              <td className="uk-text-right">¥ {item.undiscountPriceTotal}</td>
              <td className="uk-text-right">¥ {item.discountAmount}</td>
              <td className="uk-text-right">{item.realDiscountRate} %</td>
              <td className="uk-text-right">¥ {item.discountPriceTotal}</td>
              <td className="uk-text-right">{item.rate} %</td>
              <td className="uk-text-right">{item.termDiscounts} %</td>
              <td className="uk-text-right">¥ {item.allDiscountPriceTotal}</td>
              <td className="uk-text-right uk-text-bold">¥ {item.billingAmount}</td>
              <td className="uk-text-right uk-text-bold">{item.tax} %</td>
              <td className="uk-text-right uk-text-bold">¥ {item.includeTax}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <div className="uk-margin-small-top toggle-wrapper">
        <button type="button"
                className="uk-button uk-button-text uk-text-primary"
                onClick={onClickToggle}
        >
          {open ? "▲" : "▼"} API利用料 内訳
        </button>
        <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${open ? "visible" : "hidden"}`}>
          <table className="API-table uk-width-auto">
            <thead>
            <tr>
              {UsagesOptions.drm_pf_api_details_th.map((item, index) => {
                const style: React.CSSProperties = {
                  width: item.width
                };
                return (
                  <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {Object.values(fees).length !== 0 && Object.values(fees).map((item, index) => (

              <tr key={item.api_id}>
                <td>{item.api_id}</td>
                <td className="uk-text-right">{item.total} 件</td>
                <td className="uk-text-right">{item.temporary_free_total} 件</td>
                <td className="uk-text-right">{item.valid_total} 件</td>
                <td className="uk-text-right">¥ {item.unit_price}</td>
                <td className="uk-text-right">¥ {item.subtotal}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <p className="uk-text-right uk-margin-remove-bottom">
        ※こちらの小計はボリュームディスカウント前の金額となります。
      </p>
      <div className="uk-width-1-1 uk-overflow-auto">
        <table className="API-table">
          <thead>
          <tr>
            {UsagesOptions.drm_pf_api_usages_th.map((item, index) => {
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
              );
            })}
          </tr>
          </thead>
          <tbody>
          {logs.length !== 0 && logs.map((item, index) => (
            <tr key={index}>
              <td>{item.start_date}</td>
              <td>{item.end_date}</td>
              <td>{item.ip_address}</td>
              <td>{item.api_id}</td>
              <td className="uk-text-center">{item.processed_result}</td>
              <td className="uk-text-center">{item.processed_kind}</td>
              <td className="uk-text-center">{item.edition_information}版</td>
              <td className="uk-text-right">{item.data_number} 件</td>
              <td className="uk-text-right">¥ {item.unit_price}</td>
              <td className="uk-text-center">{item.is_billing_str}</td>
              {item.is_temporary_free === 1 ? <td className="uk-text-right"> -{item.data_number} 件</td> :
                <td className="uk-text-right"></td>}
              <td className="uk-text-right">¥ {item.subtotal}</td>
            </tr>
          ))}

          </tbody>
        </table>
      </div>
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
                navigate("/drm_pf/info");
              }}
          />
      }
    </div>
  );
}
