import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import * as Config from '@/config'
import {axios} from '@/lib/axios'
import {useLocation, useParams} from 'react-router-dom'
import {useProjectStore} from '@/stores/project'
import {useMessageModalStore} from '@/stores/message'
import {AttributeInputMethods} from '@/containers/data/Attributes/AttributeInput'
import {useLoadingStore} from '@/stores/loading'
import moment from "moment";
import {getReports} from '@/api/reports'

interface DetailProps {
  pdf: string;
}

export interface DetailMethods {
  submit: () => Promise<any>;
}

export const FormDetail = forwardRef<DetailMethods, DetailProps>(( props, ref ) => {
  useImperativeHandle(ref, () => ({
    submit,
  }));
  const { pdf } = props
  const location = useLocation();
  const params = useParams();
  const dataId = params.dataId;
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const selectedProjectCategoryId = selectedProjectCategory?.id;
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);

  const applyPdf = Config.ApplyPdf[params.applyPdf as keyof typeof Config.ApplyPdf] ?? Config.ApplyPdf.draft;

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [history, setHistory] = useState<any[]>([]);


  useEffect(() => {
    createApplyPdf();
    getReport();
  }, [location.pathname]);

  function createApplyPdf() {
    let requstUrl;
    switch (applyPdf) {
      case Config.ApplyPdf.draft:
        requstUrl = 'downloadDraft';
        break;
      case Config.ApplyPdf.estimate:
        requstUrl = 'downloadEstimate';
        break;
      case Config.ApplyPdf.delivery:
        requstUrl = 'downloadDelivery';
        break;
      case Config.ApplyPdf.invoice:
        requstUrl = 'downloadInvoice';
        break;
    }
    axios.get(`/api/v1/datas/${requstUrl}`, {
      params: {
        data_id: dataId,
        project_category_id: selectedProjectCategoryId,
      }
    }).then(result => {
      setFileName(result.data.filename)
      setUrl(result.data.signedUrl);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    });
  }

  function getReport() {
    setLoading(true);
    // TODO: コード整理
    let requestCode;
    switch (applyPdf) {
      case Config.ApplyPdf.draft:
        requestCode = Config.REPORT_CODE_DRAFT;
        break;
      case Config.ApplyPdf.estimate:
        requestCode = Config.REPORT_CODE_ESTIMATE;
        break;
      case Config.ApplyPdf.delivery:
        requestCode = Config.REPORT_CODE_DELIVERY;
        break;
      case Config.ApplyPdf.invoice:
        requestCode = Config.REPORT_CODE_INVOICE;
        break;
    }
    getReports({ data_id: dataId, report_code: requestCode }).then((result) => {
      console.log(result);
      setHistory(result.data.data_files);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function post() {
    setLoading(true);
    const postData = {
      tmpPath: fileName,
      report_code: Config.getApplyPdfReportCode(applyPdf),
      data_file: {
        data_id: params.dataId,
        project_category_id: selectedProjectCategoryId,
        remarks: ""
      },
    };
    return axios.post('/api/v1/dataFiles/saveApplyPdf', postData
    ).then(result => {
      getReport();
      return true;
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E37);
      return false;
    }).finally(() => {
      setLoading(false);
    });
  }

  async function submit() {
    return await post();
  }

  return (
    <div className="uk-flex uk-margin-small-top">
      {url && <iframe src={url} className="form-pdf" />}
      <div className="file-history-tbl-wrap uk-margin-left">
        <table className="file-history-tbl">
          <thead>
            <tr>
              <th colSpan={4}>ファイル保存履歴</th>
            </tr>
            <tr>
              <th>版</th>
              <th>保存日</th>
              <th>保存者</th>
              <th>ファイル名</th>
            </tr>
          </thead>
          <tbody>
            {history && history.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{moment(item.created).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{item.created_user ? `${item.created_user.last_name} ${item.created_user.first_name}` : '' }</td>
                  <td>{item.filename}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});
