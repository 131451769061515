import { axios } from "@/lib/axios";

/** お知らせ */
export interface Information {
  id: number;
  /** お知らせタイトル */
  title: string;
  /** お知らせ内容 */
  details: string;
  /** DRM表示フラグ */
  display_drmdx: boolean;
  /** マイページ表示フラグ */
  display_mypage: boolean;
  /** 表示開始日 */
  start_date: string;
  /** 表示終了日 */
  end_date: string;
  /** 最終更新日時 */
  modified: string;
  /** 最終更新者 */
  modified_by: string;
}

/**
 * お知らせ1件分の情報取得
 */
export const getInformation = (id: number) => {
  return axios.get<Information>("/api/v1/Information/getInformation", {
    params: { id },
  });
};

/**
 * 一覧画面表示用のお知らせ
 */
export interface InformationList {
  information_list: Information[];
}
/** 一覧画面用のお知らせ取得　*/
export const getInformationList = () => {
  return axios.get<InformationList>("/api/v1/Information/getInformationList");
};

/**
 * ログイン画面表示用のお知らせ
 */
export interface LoginInformation {
  information_list: Pick<Information, "id" | "title" | "details">[];
}

/**
 * ログイン画面表示用のお知らせ取得
 */
export const getLoginInformation = () => {
  return axios.get<LoginInformation>(
    "/api/v1/Information/getLoginInformationList"
  );
};

/**
 * お知らせ登録のリクエストパラメータ
 */
export interface PostInformationParams {
  /** 指定されていれば id のお知らせを更新 */
  id?: number;
  /** お知らせタイトル */
  title: string;
  /** お知らせ内容 */
  details: string;
  /** DRM-DX に表示するかどうか */
  display_drmdx: boolean;
  /** マイページに表示するかどうか */
  display_mypage: boolean;
  /** 表示開始日（例: 2024-04-01) */
  start_date: string;
  /** 表示終了日（例: 2025-03-31） */
  end_date: string;
}
/** お知らせ登録・更新 */
export const postInformation = (params: PostInformationParams) => {
  return axios.post("/api/v1/Information/postInformation", params);
};
