import Axios from "axios";
import { PF_DRM_BASE_URL, PF_DRM_PASSWORD, PF_DRM_USER_NAME, SYSTEM_ID, } from "@/config";
import * as Common from "@/utils/common";

export const axios = Axios.create({
  baseURL: PF_DRM_BASE_URL,
});

export const authentication = () => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const formData = new FormData();
  formData.append("authentication_type", "1");
  formData.append("user_name", PF_DRM_USER_NAME);
  formData.append("password", PF_DRM_PASSWORD);
  return axios.post("/API/State/v8.0/Authentication", formData, { headers });
};

export const getCoordinateInformation = (
  tokenKey: string,
  inquiryKey: string
) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    TOKEN_KEY: tokenKey,
  };
  const formData = new FormData();
  formData.append("system_id", SYSTEM_ID);
  formData.append("inquiry_key", inquiryKey);
  return axios.post("/API/Inquiry/v8.0/GetCoordinateInformation", formData, {
    headers,
  });
};

export const getNearestLink = (
  tokenKey: string,
  pointX: number,
  pointY: number
) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    TOKEN_KEY: tokenKey,
  };
  const formData = new FormData();
  formData.append("road_classification", "2");
  formData.append("point_x", String(pointX));
  formData.append("point_y", String(pointY));
  return axios.post("/API/Link/v8.0/GetNearestLink", formData, { headers });
};

export const getRelatingLinks = (
  tokenKey: string,
  relatingLineGeojson: any
) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    TOKEN_KEY: tokenKey,
  };
  const formData = new FormData();
  formData.append("road_classification", "2");
  formData.append("relating_buffer", "5");
  formData.append("relating_line_geojson", JSON.stringify(relatingLineGeojson));
  return axios.post("/API/Link/v8.0/GetRelatingLinks", formData, { headers });
};

export const getNodeAndLinkPIDFromPolygon = ( tokenKey: string, data: any ) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    TOKEN_KEY: tokenKey,
  };
  const formData = new FormData();
  formData.append("road_classification", "2");
  formData.append("spatial_search_erea", JSON.stringify(data));
  return axios.post("/API/Consolidate/v8.0/GetNodeAndLinkPIDFromPolygon", formData, {
    headers,
  });
};

export const getCoordinate = async ( authRes: any, featureKey: string ) => {
  const coordinateRes = await getCoordinateInformation(authRes.data.key, featureKey);
  if (!coordinateRes.data.coordinate_info){
    return null;
  }
  const coordinateInfo = JSON.parse(coordinateRes.data.coordinate_info);
  if (!coordinateInfo || !coordinateInfo.features || coordinateInfo.features.length == 0) {
    return new Error();
  }
  return coordinateInfo;
}

export const getP_id = async ( coordinateInfo: any, authRes: any ) => {
  const { points, polygons, lines } = Common.parseGeoJson(coordinateInfo);
  let promises = [
    ...points.map(point => getNearestLink(authRes.data.key, point.x, point.y)),
    ...polygons.map(polygon => getNodeAndLinkPIDFromPolygon(authRes.data.key, polygon)),
    ...lines.map(line => getRelatingLinks(authRes.data.key, line)),
  ];
  const pIdRes = await Promise.all(promises);
  let error = false;
  const pIds = pIdRes.map(res => {
    const { data } = res;
    console.log(data)
    if (data.permanent_id) {
      return [data.permanent_id];
    } else if (data.link_pid_list) {
      return JSON.parse(data.link_pid_list);
    } else {
      error = true;
      return [];
    }
  }).flat(1);
  if (error) {
    return new Error();
  }
  return pIds.join(',');
}
