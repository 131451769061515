import {axios} from '@/lib/axios';

export const getDrmApiUsagesData = (month: string | undefined, id: number): Promise<any> => {
  return axios.get(`/api/v1/customers/getDrmPfApiUsagesData`, {
    params: {month, id}
  })
}

export const getDrmPfBaseData = (id: string | undefined): Promise<any> => {
  return axios.get(`/api/v1/customers/getDrmPfBaseData`, {
    params: {id}
  })
}

// すべての利用履歴を取得
export const getUsageHistory = (month: string | undefined): Promise<any> => {
  return axios.get(`/api/v1/customers/getUsageHistory`, {
  })
}