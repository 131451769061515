import React, {useEffect, useMemo, useState} from 'react';
import SubHeader from '@/components/SubHeader'
import {FormProvider, SubmitHandler, useForm, useFormContext, useWatch} from 'react-hook-form'
import * as Config from '@/config'
import * as Common from '@/utils/common'
import {axios} from '@/lib/axios'
import {ProjectCategory} from '@/stores/project'
import {Tooltip} from 'react-tooltip'
import {CATEGORY_CODE_FAQ, CATEGORY_CODE_SALES_APPLY, REPORT_OUTPUTS_STATUSES} from '@/config'
import {Link} from 'react-router-dom'
import moment from 'moment'
import qs from 'qs'
import {useMessageModalStore} from '@/stores/message'
import {useLoadingStore} from '@/stores/loading'
import ConfirmModal from '@/components/ConfirmModal'
import {useInterval} from '@/utils/UseInterval'

const SELECT_REPORT = [Config.REPORT_COLLECT_LIST, Config.REPORT_MANAGE_LIST];
export default function Report() {
  const methods = useForm();
  const {
    control,
    reset,
    getValues,
    setValue,
    register,
    formState: { errors, isDirty },
  } = methods;

  const breadCrumbs = [
    { title: '帳票' }
  ];

  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  const [outPutList, setOutPutList] = useState<Array<any>>([]);
  const targetList = outPutList.filter((o: any) => o.deleted === null).map((o: any) => o.id);
  const [checkIds, setCheckIds] = useState(new Set<number>());

  useInterval(() => {
    getList();
  }, 60 * 1000);

  useEffect(() => {
    getList()
    return () => {
    };
  }, []);

  function getList() {
    axios.get('/api/v1/dataReportOutputs/list', {}).then(result => {
      const outputs = result.data.dataReportOutputs;
      setOutPutList(outputs);
      const resetIds = new Set<number>(outputs.filter((o: any) => checkIds.has(o.id) && !o.deleted).map((o: any) => o.id));
      setCheckIds(resetIds);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
    });
  }

  function deleteIds() {
    setModalConfirm({
      callback: () => {
        setLoading(true);
        axios.post('/api/v1/dataReportOutputs/deleteByIds', {
          ids: Array.from(checkIds),
        }).then(result => {
          getList();
        }).catch(error => {
          setMessage(Config.MESSAGE_NO_E39);
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  }

  const onClear = () => {
    reset();
  }

  const onClickOutput = () => {
    setLoading(true);
    const data = getValues();
    console.log("data");
    console.log(data);
    const reportList = SELECT_REPORT.find(v => v.value === data.report_type);
    let condition_label = "地方整備局=";
    if (Array.isArray(data.region)) {
      condition_label += reportList?.region.filter(region => data.region.find((selectedRegion: any) => region.value === selectedRegion)).map(m => m.label).join('、')
    }

    axios.post('/api/v1/dataReportOutputs/output', {
      name: reportList?.label,
      condition_label: condition_label,
      conditions: {
        report_type: data.report_type,
        region: data.region,
      }
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E37);
    }).finally(() => {
      getList();
      setLoading(false);
    });
  }

  const onClickDownload = () => {
    axios.post('/api/v1/dataReportOutputs/downloads', {
      ids: Array.from(checkIds),
    }).then(result => {
      result.data.s3Urls.forEach((s3Url: any, index: number) => {
        axios.get(s3Url.url, {
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = s3Url.filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
        // fetch(s3Url.url)
        //   .then(response => {
        //     if (response.ok) {
        //       return response.blob();
        //     }
        //     throw new Error('Network response was not ok.');
        //   })
        //   .then(blob => {
        //     const link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(blob);
        //     link.setAttribute('download', s3Url.filename);
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //   })
        //   .catch(error => {
        //     setMessage(Config.MESSAGE_NO_E39);
        //   });
        //
        // setTimeout(() => {
        //   const link = document.createElement('a');
        //   link.href = s3Url.url;
        //   link.setAttribute('download', '');
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);
        // }, index * 300);
      });
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
    });
  }

  const onClickCheck = ( id: number ) => {
    if (checkIds.has(id)) {
      checkIds.delete(id);
      setCheckIds(new Set(checkIds));
    } else {
      checkIds.add(id);
      setCheckIds(new Set(checkIds));
    }
  }

  const onClickCheckAll = () => {
    if (checkIds.size === targetList.length) {
      setCheckIds(new Set<number>());
    } else {
      setCheckIds(new Set<number>(targetList));
    }
  }

  const onClickDeleteIds = () => {
    deleteIds();
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={"帳票"}/>
      </div>

      <div className="container">
        <FormProvider {...methods}>
          <OutputContainer
            onClickOutput={onClickOutput}
            onClear={onClear}
          />
        </FormProvider>

        <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
          <div className="uk-inline">
            <button className="uk-button--m uk-button-refer" onClick={onClickDownload} disabled={checkIds.size === 0}>
              ダウンロード</button>
          </div>
          <div>
            <button className="uk-button--m uk-button-cancel" onClick={onClickDeleteIds}
                       disabled={checkIds.size === 0}>一括削除</button>
          </div>
        </div>
        <OutputListContainer outPutList={outPutList} targetList={targetList} checkIds={checkIds} onClickCheck={onClickCheck} onClickCheckAll={onClickCheckAll} />
        <div className="uk-text-right">1週間で自動削除されます</div>
      </div>

      <ConfirmModal
        text={Config.MESSAGE_NO_E05}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if (modalConfirm) modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  );
}

/**
 * 出力
 */
interface OutputProps {
  onClickOutput: () => void;
  onClear: () => void;
}
const OutputContainer = ( props: OutputProps ) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext()

  const selectedReportType = useWatch({
    control,
    name: 'report_type',
  });

  const filteredRegion = useMemo(() => {
    // console.log("selectedReportType")
    // console.log(selectedReportType)
    if (selectedReportType) return SELECT_REPORT.find(( report: any ) => report.value === selectedReportType)?.region ?? [];
    else return [];
  }, [selectedReportType]);

  useEffect(() => {
    if (isDirty) {
      setValue('region', '');
    }
  }, [selectedReportType]);

  const onSubmit: SubmitHandler<any> = ( data ) => {
    props.onClickOutput();
  }

  return (
    <div className="">
      <div className="precaution">※は必須です</div>
      <div className="info-tbl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="uk-width-1-1">
            <tbody>
            <tr>
              <td className="label">
                帳票名
                <span className="error">※{errors.report_type && `${errors.report_type.message}`}</span>
              </td>
              <td className="content">
                <select
                  className={`uk-width-1-1 ${selectedReportType ? '' : 'placeholder'} ${errors.report_type ? "error-form" : ""}`}
                  defaultValue={""} {...register(`report_type`, { required: '必須項目です' })}>
                  <option value="" disabled>選択してください</option>
                  {SELECT_REPORT.map(( o: any ) => {
                    return <option key={`report_type_${o.value}`} value={o.value}>{o.label}</option>
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td className="label">
                地方整備局
                <span className="error">※{errors.region && `${errors.region.message}`}</span>
              </td>
              <td className="content">
                {filteredRegion.map(( o: any ) => (
                  <label className="inline-label">
                    <input type="checkbox" {...register('region', { required: '必須項目です' })} value={o.value}/>
                    {o.label}
                  </label>
                ))}
              </td>
            </tr>
            </tbody>
          </table>
          <div className="uk-flex uk-flex-center mt-5">
            <button type="button" onClick={props.onClear}
                    className="uk-button--m uk-button-cancel">クリア
            </button>
            <button type="submit" className="uk-button--m uk-button-refer uk-margin-left">出力</button>
          </div>
        </form>
      </div>
    </div>
  )
}



/**
 * 出力結果
 */
interface OutputListContainerProps {
  outPutList: Array<any>,
  targetList: Array<number>,
  checkIds: Set<number>,
  onClickCheck: ( id: number ) => void,
  onClickCheckAll: () => void,
}

const OutputListContainer = ( props: OutputListContainerProps ) => {

  return (
    <div className="mt-5">
      <Tooltip id="report-tooltip" style={{ zIndex: 3 }}/>
      <table className="report-tbl">
        <thead>
        <tr className="header">
          <th style={{width: 30}}>
            <div className="uk-flex uk-flex-center">
              <input
                type="checkbox"
                onChange={props.onClickCheckAll}
                checked={props.targetList.length > 0 && props.checkIds.size === props.targetList.length}/>
            </div>
          </th>
          <th>登録日時</th>
          <th>グループ名</th>
          <th>登録者</th>
          <th>帳票名</th>
          <th>絞り込み条件</th>
          <th>ステータス</th>
          <th>出力完了日時</th>
          <th>最終更新日時</th>
          <th>最終更新者</th>
        </tr>
        </thead>
        <tbody>
        {props.outPutList.map((( data: any ) => {
          const checked = props.checkIds.has(data.id);
          return (
            <tr className={`${checked ? "selected" : ""} ${data.deleted ? "deleted" : ""}`}>
              <td className={`${checked ? "selected" : ""}`}>
                <div className="uk-flex uk-flex-center">
                  <input type="checkbox" onChange={() => {
                    props.onClickCheck(data.id)
                  }} checked={checked} disabled={data.deleted}/>
                </div>
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={moment(data.created).format(Config.DATE_FORMAT)}>
                {moment(data.created).format(Config.DATE_FORMAT)}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.created_user.group.name}>
                {data.created_user.group.name}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={`${data.created_user.last_name}${data.created_user.first_name}`}>
                {`${data.created_user.last_name}${data.created_user.first_name}`}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.name}>
                {data.name}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.condition_label}>
                {data.condition_label}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.status}>
                {Config.REPORT_OUTPUTS_STATUSES[data.status]}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.output_time ? moment(data.output_time).format(Config.DATE_FORMAT) : Config.REPORT_OUTPUTS_STATUSES[data.status]}>
                {data.output_time ? moment(data.output_time).format(Config.DATE_FORMAT) : ""}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={moment(data.modified).format(Config.DATE_FORMAT)}>
                {moment(data.modified).format(Config.DATE_FORMAT)}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={`${data.modified_user.last_name}${data.modified_user.first_name}`}>
                {`${data.modified_user.last_name}${data.modified_user.first_name}`}
              </td>
            </tr>
          )
        }))}
        </tbody>
      </table>
    </div>
  )
}