import {axios} from '@/lib/axios';
import {GetDataBasicMapCitiesParams, PostDataBasicMapCertificationData} from "@/components/Attributes/BasicMap/types";

//市町村ステータスマスタ
export const getBasicMapConfirmationStatuses = () => {
  return axios.get('/api/v1/basicMap/getBasicMapConfirmationStatuses')
}

//作成会社・検定ステータスマスタ
export const getBasicMapCertificationStatuses = (kind: number) => {
  return axios.get('/api/v1/basicMap/getBasicMapCertificationStatuses', {
    params: {kind}
  })
}

//市町村マスタ
export const getDicCitiesFromArea = (area_id: number) => {
  return axios.get('/api/v1/basicMap/getDicCitiesFromArea', {
    params: {area_id}
  })
}

//版番号マスタ
export const getBasicMapEditions = () => {
  return axios.get('/api/v1/basicMap/getBasicMapEditions')
}

//更新対象市町村
export const getDataBasicMapCities = (params: GetDataBasicMapCitiesParams) => {
  return axios.get('/api/v1/basicMap/getDataBasicMapCities', {
    params: params
  })
}

//検定情報取得
export const getDataBasicMapCertification = (data_id: number | undefined) => {
  return axios.get('/api/v1/basicMap/getDataBasicMapCertification', {
    params: {data_id}
  })
}

//検定情報登録
export const postDataBasicMapCertification = (data: PostDataBasicMapCertificationData[]) => {
  return axios.post('/api/v1/basicMap/postDataBasicMapCertification', {formData: data})
}

//インポートチェックリスト
export const getBasicMapImportList = () => {
  return axios.post('/api/v1/basicMap/getBasicMapImportList')
}
//インポートチェック
export const checkBasicMapImportFile = (import_file: FormData) => {
  return axios.post('/api/v1/basicMap/checkBasicMapImportFile', import_file, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
//インポート
export const importBasicMapFile = (tmpPath: string | null, name?: string) => {
  return axios.post('/api/v1/basicMap/importBasicMapFile', {tmpPath: tmpPath, name: name})
}

