import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import ConfirmModal from "@/components/ConfirmModal";
import {useUserStore} from "@/stores/user";
import {canDataExportManagement, canDataImportManagement} from "@/utils/common";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import BasicMapImport from "@/containers/admin/BasicMap/BasicMapImport";

export default function BasicMapManagement() {
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const [modalMessage, setModalMessage] = useState<{ type: {}, message: string } | null>(null)
  const canBasicMapManagement = Common.canBasicMapManagement(user) === Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK.value;

  const breadCrumbs = [
    {title: '管理画面', path: '/admin'},
    {title: "インポート"}
  ];

  useEffect(() => {
    if (!canBasicMapManagement) {
      navigate('/admin');
    }
  }, [])

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="基盤地図管理"/>
      </div>
      <div className="container">
        <ul className="uk-tab uk-margin-small-top" data-uk-tab="{connect:'#data-tab'}">
          <li className="uk-active uk-data">
            <Link to="/admin/data">インポート</Link>
          </li>

        </ul>
        <ul id="data-tab" className="uk-switcher">
          <li><BasicMapImport/></li>
        </ul>
      </div>
      <ConfirmModal
        text={modalMessage?.message ?? ""}
        confirmButtonText="OK"
        isShow={!!modalMessage?.message}
        onConfirm={() => setModalMessage(null)}
      />
    </div>
  );
}