import React, { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { User, useUserStore } from "@/stores/user";
import { Project, ProjectCategory, useProjectStore } from "@/stores/project";
import { useLoadingStore } from '@/stores/loading'
import qs from 'qs'
import { getProjects } from "@/api/projects";
import {axios} from '@/lib/axios'
import * as Config from '@/config'

export default function Header() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = matchPath("/progress/:projectCategoryId/*", pathname)?.params || null;
  const query = qs.parse(search.substring(1));

  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);
  const [selectedProject, setSelectedProject, selectedProjectCategory, setSelectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.setProject,
    state.projectCategory,
    state.setProjectCategory,
  ]);

  const getSelectCondition = (pathname : string) => {
    if (matchPath("/progress", pathname)) {
      return {isShowSelectProject: true, disabledSelectProject: false, isShowSelectCategory: true, disabledSelectCategory: false};
    } else if (matchPath("/progress/*", pathname)
      || (matchPath("/password_change", pathname) && user && user.is_changed_password === 1)) {
      // パスワード変更
      // 属性画面
      // ファイル画面
      return {isShowSelectProject: true, disabledSelectProject: true, isShowSelectCategory: true, disabledSelectCategory: true};
    } else if (matchPath("/admin/users", pathname)
      || matchPath("/report", pathname)) {
      // ユーザーマスタ一覧
      // 帳票出力
      return {isShowSelectProject: true, disabledSelectProject: false, isShowSelectCategory: false, disabledSelectCategory: false};
    } else if (matchPath("/admin/data", pathname)) {
      // インポート
      // エクスポート
      return {isShowSelectProject: false, disabledSelectProject: false, isShowSelectCategory: false, disabledSelectCategory: false};
    } else if (matchPath("/admin", pathname) || matchPath("/admin/*", pathname)) {
      // 管理画面
      // ユーザーマスタ編集
      // グループマスタ一覧/編集
      return {isShowSelectProject: true, disabledSelectProject: true, isShowSelectCategory: false, disabledSelectCategory: false};
    } else {
      // ログイン
      // 初期パスワード
      // 顧客一覧
      return {isShowSelectProject: false, disabledSelectProject: false, isShowSelectCategory: false, disabledSelectCategory: false};
    }
  }
  const {isShowSelectProject, disabledSelectProject, isShowSelectCategory, disabledSelectCategory} = getSelectCondition(pathname);

  const [projects, setProjects] = useState<Project[]>([]);
  const [projectCategories, setProjectCategories] = useState<ProjectCategory[]>([]);

  const setInit = (
    project: Project[] = [],
    projectCategories: ProjectCategory[] = [],
    selectedProject: Project | undefined = undefined,
    selectedProjectCategory: ProjectCategory | undefined = undefined ) => {
    setProjects(project);
    setProjectCategories(projectCategories);
    setSelectedProject(selectedProject);
    setSelectedProjectCategory(selectedProjectCategory);
  }


  function getProjectWithCategory( user: User ) {
    setLoading(true);
    getProjects().then(result => {
      const projects = result.data.projects as Array<Project>;
      let projectCategories: ProjectCategory[] = [], initProject = undefined, initProjectCategory = undefined;
      const urlParam = params?.projectCategoryId ?? query?.project_category_id as string;
      if (urlParam) {
        loop:
          for (const project of projects) {
            for (const projectCategory of project.project_categories) {
              if (projectCategory.id === parseInt(urlParam)) {
                initProject = project;
                projectCategories = project.project_categories;
                initProjectCategory = projectCategory;
                break loop;
              }
            }
          }
      } else {
        initProject = projects.find(p => p.id === user.last_display_project_id) || projects[0];
        projectCategories = initProject.project_categories;
        initProjectCategory = initProject.project_categories[0];
      }
      setInit(projects, projectCategories, initProject, initProjectCategory);
    }).catch(error => {
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (user) {
      getProjectWithCategory(user);
    } else {
      setInit();
    }
    return () => {
    };
  }, [user]);

  const handleProjectChange = ( value: string ) => {
    const projectId = parseInt(value);
    const project = projects.find(p => p.id === projectId);
    setSelectedProject(project || undefined);
    setProjectCategories(project?.project_categories || []);
    setSelectedProjectCategory(project?.project_categories?.[0] || undefined);
    axios.post(`/api/v1/users/setLastDisplayProjectId`,{projectId});
  };

  const handleCategoryChange = ( value: string ) => {
    const selectedId = Number(value);
    const selectedItem = projectCategories.find(( c ) => c.id === selectedId);
    setSelectedProjectCategory(selectedItem || undefined);
  };

  const onClickUser = () => {
    if (pathname !== '/password_change') {
      navigate('/password_change');
    }
  }

  return (
    <header>
      <div>
        {isShowSelectProject && projects.length > 0 && selectedProject &&
            <select
                value={selectedProject.id}
                onChange={e => handleProjectChange(e.target.value)}
                name="project"
                disabled={disabledSelectProject}
            >
              {projects.map(p => (
                <option key={`projects_${p.id}`} value={p.id}>{p.name}</option>
              ))}
            </select>
        }
        {isShowSelectCategory && projectCategories.length > 0 && selectedProjectCategory &&
            <select
                value={selectedProjectCategory.id}
                onChange={e => handleCategoryChange(e.target.value)}
                className="uk-margin-left"
                name="category"
                disabled={disabledSelectCategory}
            >
              {projectCategories.map(c => (
                <option key={`projectCategories_${c.id}`} value={c.id}>{c.name}</option>
              ))}
            </select>
        }
        {Config.HEADER_ENV !== "" && <span className="uk-margin-small-left env-label">{Config.HEADER_ENV}</span>}
      </div>
      {user?.is_changed_password === 1 &&
          <div>
              <ul className="header-right-item">
                  <li className="header-logintime">最終ログイン日時：{user.logged_in_date}</li>
                  <li className="header-username">
                      <a onClick={onClickUser}>ユーザ名：{user.last_name + user.first_name}</a>
                  </li>
              </ul>
          </div>
      }
    </header>
  );
}