import React, {useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FormDetail} from "./FormDetail";
import {useProjectStore} from '@/stores/project'
import * as Config from '@/config'
import * as Common from '@/utils/common'
import {ApplyPdf} from '@/config'
import {AttributeInputMethods} from '@/containers/data/Attributes/AttributeInput'
import { getReports } from "@/api/reports";
import { useLoadingStore } from "@/stores/loading";
import { useMessageModalStore } from "@/stores/message";
import { useUserStore } from "@/stores/user";

export default function Forms() {
  const childRef = useRef<AttributeInputMethods>(null);
  const params = useParams();
  const user = useUserStore((state) => state.user);

  const applyPdf = ApplyPdf[params.applyPdf as keyof typeof ApplyPdf] ?? Config.ApplyPdf.draft;

  const onClickSave = async () => {
    if (childRef.current) {
      const result = await childRef.current.submit();
    }
  }
  const dataId = params.dataId;
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const selectedProjectCategoryId = selectedProjectCategory?.id;
  const [reports, setReports] = useState<any>({});
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="container uk-margin-top">
        <ul className="uk-tab">
          <li className={`${applyPdf === Config.ApplyPdf.draft ? "uk-active" : ""}`}>
            <Link to={Common.formsPdfUrl(params.projectCategoryId, params.dataId, Config.ApplyPdf.draft)}>起案書</Link>
          </li>
          <li className={`${applyPdf === Config.ApplyPdf.estimate ? "uk-active" : ""}`}>
            <Link to={Common.formsPdfUrl(params.projectCategoryId, params.dataId, Config.ApplyPdf.estimate)}>見積書</Link>
          </li>
          <li className={`${applyPdf === Config.ApplyPdf.delivery ? "uk-active" : ""}`}>
            <Link to={Common.formsPdfUrl(params.projectCategoryId, params.dataId, Config.ApplyPdf.delivery)}>納品書</Link>
          </li>
          <li className={`${applyPdf === Config.ApplyPdf.invoice ? "uk-active" : ""}`}>
            <Link to={Common.formsPdfUrl(params.projectCategoryId, params.dataId, Config.ApplyPdf.invoice)}>請求書</Link>
          </li>
          {Common.canForm(parseInt(params.projectCategoryId ?? '', 10), user) === Config.RESTRICTION_HISTORY_FORM_EDIT.value &&
            <div className="right">
              <button className="uk-button--l uk-button-refer uk-margin-left" onClick={onClickSave}>
                ファイルを保存
              </button>
            </div>
          }
        </ul>
        <ul id="sub-tab">
          <li>
            <FormDetail
              pdf="起案書(印刷PV).pdf"
              ref={childRef}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
