import { axios } from '@/lib/axios';
import { PostFaqData } from "@/containers/data/Public/PublicEditItem";

export const getFaqInformation = ( data_id: number ) => {
  return axios.get('/api/v1/dataFaqs/getFaqInformation', {
    params: { data_id }
  })
}

export const postFaqInformation = ( post_data: PostFaqData ) => {
  return axios.post('/api/v1/dataFaqs/postFaqInformation', post_data)
}

export const deleteFaq = ( faq_id: number ) => {
  return axios.delete('/api/v1/dataFaqs/deleteFaq', {
    params: { faq_id }
  })
}