import {
  PostInformationParams,
  getInformation,
  postInformation,
} from "@/api/information";
import ConfirmModal from "@/components/ConfirmModal";
import { CheckBox } from "@/components/Form/CheckBox";
import { TextArea } from "@/components/Form/TextArea";
import { TextBox } from "@/components/Form/TextBox";
import { MESSAGE_NO_E35, MESSAGE_NO_E37, MESSAGE_NO_E39 } from "@/config";
import { useLoadingStore } from "@/stores/loading";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubHeader from "../../../components/SubHeader";
import { getLastInformation } from "./shared/getLastInformation";
import { useInformationList } from "./shared/useInformationList";
import { useAuthCheck } from "./shared/useAuthCheck";

const DISPLAY_DRM_CHECKED_VALUE = "drm";
const DISPLAY_MYPAGE_CHECKED_VALUE = "mypage";

interface Form {
  title: string;
  details: string;
  display: {
    drm: typeof DISPLAY_DRM_CHECKED_VALUE | false;
    mypage: typeof DISPLAY_MYPAGE_CHECKED_VALUE | false;
  };
  startDate: string;
  endDate: string;
}

const InformationEdit: FC = () => {
  useAuthCheck();

  const location = useLocation();
  const navigate = useNavigate();
  const informationId = useParams().id;
  const setLoading = useLoadingStore((state) => state.setLoading);
  const isDuplicate = location.pathname.includes("/duplicate");

  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [formData, setFormData] = useState<Form>();
  useEffect(() => {
    if (!informationId) return;

    setLoading(true);

    getInformation(Number(informationId))
      .then((res) => {
        const data = res.data;
        if (isDuplicate) {
          setFormData({
            title: data.title,
            details: data.details,
            display: {
              drm: false,
              mypage: false,
            },
            startDate: "",
            endDate: "",
          });
        } else {
          setFormData({
            title: data.title,
            details: data.details,
            display: {
              drm: data.display_drmdx ? DISPLAY_DRM_CHECKED_VALUE : false,
              mypage: data.display_mypage
                ? DISPLAY_MYPAGE_CHECKED_VALUE
                : false,
            },
            startDate: data.start_date,
            endDate: data.end_date,
          });
        }
      })
      .catch(() => {
        setErrorMessage(MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [informationId, isDuplicate, setLoading]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    criteriaMode: "all",
    defaultValues: {},
    values: formData,
  });

  const title = informationId && !isDuplicate ? "編集" : "新規作成";

  const breadCrumbs = [
    { title: "管理画面", path: "/admin" },
    { title: "お知らせ一覧", path: "/admin/information" },
    { title },
  ];

  const onClickCancel = () => {
    setIsShowBackModal(true);
  };

  const onClickSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setLoading(true);

    const postData: PostInformationParams = {
      id: informationId && !isDuplicate ? Number(informationId) : undefined,
      title: data.title,
      details: data.details,
      display_drmdx: data.display.drm === DISPLAY_DRM_CHECKED_VALUE,
      display_mypage: data.display.mypage === DISPLAY_MYPAGE_CHECKED_VALUE,
      start_date: data.startDate,
      end_date: data.endDate,
    };

    postInformation(postData)
      .then((_res) => {
        navigate(-1);
      })
      .catch((_error) => {
        setErrorMessage(MESSAGE_NO_E37);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const informationList = useInformationList(setLoading, () =>
    setErrorMessage(MESSAGE_NO_E39)
  );
  const lastInformation = getLastInformation(informationList);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="お知らせ管理" />
        <div className="uk-text-right uk-margin-small-bottom">
          最終更新ユーザ: {lastInformation?.modified_by} 最終更新:{" "}
          {moment(lastInformation?.modified).format("YYYY-MM-DD HH:mm")}
        </div>
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div className="precaution">※は必須です</div>
          <div>
            <button
              className="uk-button--m uk-button-cancel"
              onClick={onClickCancel}
            >
              キャンセル
            </button>
            <button
              className="uk-button--m uk-button-refer uk-margin-left"
              onClick={onClickSave}
            >
              保存
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tbody>
                <tr>
                  <td>
                    表示場所<span className="error">※</span>
                    {errors.display && (
                      <span className="error">必須項目です</span>
                    )}
                  </td>
                  <td>
                    <div className="width-40p uk-flex">
                      <CheckBox
                        name="display"
                        options={[
                          { label: "DRM-DX", value: DISPLAY_DRM_CHECKED_VALUE },
                          {
                            label: "マイページ",
                            value: DISPLAY_MYPAGE_CHECKED_VALUE,
                          },
                        ]}
                        rules={{
                          validate: {
                            required: (_: any, formValue: Form) => {
                              const displayDrm = formValue.display.drm;
                              const displayMyPage = formValue.display.mypage;

                              if (!displayDrm && !displayMyPage) return false;

                              return true;
                            },
                          },
                        }}
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    表示期間<span className="error">※</span>
                    {(errors.startDate || errors.endDate) && (
                      <span className="error">必須項目です</span>
                    )}
                  </td>
                  <td>
                    <div className="width-40p uk-flex">
                      <TextBox
                        inputType="date"
                        name="startDate"
                        max={watch("endDate")}
                        register={register}
                        rules={{
                          required: true,
                        }}
                        errors={errors}
                      />
                      <span style={{ margin: "0 10px" }}>~</span>
                      <TextBox
                        inputType="date"
                        name="endDate"
                        min={watch("startDate")}
                        register={register}
                        rules={{
                          required: true,
                        }}
                        errors={errors}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    タイトル<span className="error">※</span>
                    {errors.title?.type === "required" && (
                      <span className="error">必須項目です</span>
                    )}
                    {errors.title?.type !== "required" && (
                      <span className="error">{errors.title?.message}</span>
                    )}
                  </td>
                  <td>
                    <div className="uk-width-1-1 uk-flex">
                      <TextBox
                        name="title"
                        register={register}
                        rules={{
                          required: true,
                          maxLength: { value: 50, message: MESSAGE_NO_E35(50) },
                        }}
                        errors={errors}
                        placeholder="タイトルを入力してください"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    お知らせ内容<span className="error">※</span>
                    {errors.details?.type === "required" && (
                      <span className="error">必須項目です</span>
                    )}
                    {errors.details?.type !== "required" && (
                      <span className="error">{errors.details?.message}</span>
                    )}
                  </td>
                  <td>
                    <div className="uk-width-1-1 uk-flex">
                      <TextArea
                        name="details"
                        register={register}
                        rules={{
                          required: true,
                          maxLength: {
                            value: 200,
                            message: MESSAGE_NO_E35(200),
                          },
                        }}
                        errors={errors}
                        placeholder="お知らせ内容を入力してください"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="前の画面へ"
        isShow={isShowBackModal}
        onConfirm={() => {
          navigate(-1);
        }}
        onCancel={() => {
          setIsShowBackModal(false);
        }}
      />
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={!!errorMessage}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
};

export default InformationEdit;
