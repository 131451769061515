import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useBeforeUnload, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import { getApply, postApplyAreas } from "@/api/applies";
import { useLoadingStore } from "@/stores/loading";
import { useProjectStore } from "@/stores/project";
import { useAreaListStore } from "@/stores/areaList";
import { getAreaList } from "@/api/areas";
import { Apply, ApplyProduct } from "./ApplyAreaList";
import { ApplyAreaForm } from "@/components/ApplyAreaForm";
import ConfirmModal from "@/components/ConfirmModal";
import { useBlockerStore } from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";

export default function ApplyAreaListEdit() {
  const headerTop = (document.querySelector("header")?.offsetHeight ?? 0)
    + (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  const navigate = useNavigate();
  const params = useParams();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const location = useLocation();
  const setBlocker = useBlockerStore(state => state.setBlocker);

  const [selectedProjectCategory] = useProjectStore((state) => [
    state.projectCategory,
  ]);
  const [areaList, setAreaList] = useAreaListStore((state) => [
    state.areaList,
    state.setAreaList,
  ]);

  const methods = useForm<Apply>({
    defaultValues: {
      amount: 0,
      consumption_tax: 0,
      data_apply_products: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "data_apply_products",
  });
  const watchApplyAreas = useWatch({
    control: methods.control,
    name: "data_apply_products",
  });

  const [areas, setAreas] = useState<any[]>([]);
  const [apply, setApply] = useState<Apply>();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);

  const totalAmount = useMemo(() => {
    if (watchApplyAreas && areas.length > 0) {
      return watchApplyAreas
        .map((applyArea: ApplyProduct): number => {
          return (
            (Common.getArea(areas, applyArea.sales_product_id)?.amount ?? 0) *
            applyArea.quantity
          );
        })
        .reduce((a: number, b: number) => a + b, 0);
    }
    return 0;
  }, [watchApplyAreas]);

  const enableDeleteButton = useMemo(() => {
    if (watchApplyAreas && areas.length > 0) {
      return watchApplyAreas.some((applyArea: any): boolean => {
        return applyArea.checked;
      });
    }
    return false;
  }, [watchApplyAreas]);

  useBeforeUnload(useCallback(() => {
    if (params.dataId) navigator.sendBeacon(`${Config.API_URL}/api/v1/datas/unlock/${params.dataId}`);
  }, []));

  useEffect(() => {
    setBlocker(true);
    fetchData();
  }, [location.pathname]);

  const fetchData = () => {
    const dataId = params.dataId;
    const selectedProjectCategoryId = selectedProjectCategory?.id;

    if (dataId && selectedProjectCategoryId) {
      setLoading(true);
      let promises = [
        getApply({
          data_id: parseInt(dataId),
          project_category_id: selectedProjectCategoryId,
        }).catch((error) => error),
      ];
      let areas: any[] = [];
      if (areaList) {
        areas = areaList.area_list;
      } else {
        promises.push(
          getAreaList({
            project_category_id: selectedProjectCategoryId,
          }).catch((error) => error)
        );
      }
      Promise.all(promises)
        .then((res) => {
          if (res.length > 1) {
            const area_list = res[1].data?.area_list;
            if (area_list && area_list.length > 0) {
              areas = area_list;
              setAreaList(res[1].data);
            }
          }
          setAreas(areas);
          let apply = res[0].data?.apply ?? {
            amount: 0,
            consumption_tax: 0,
            data_apply_products: [],
          };
          setApply(apply);
          setupForm(apply, areas);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const setupForm = (apply: Apply, areas: any[]) => {
    let data_apply_products = apply.data_apply_products.map((product: ApplyProduct) => {
      const fullAreas = Common.getFullAreas(product.sales_product_id, areas);
      if (fullAreas.length == 1) {
        return {
          id: product.id,
          category_id: fullAreas[0].id,
          sales_product_id: fullAreas[0].id,
          quantity: product.quantity,
          unit_price: product.unit_price,
          remarks: product.remarks,
        };
      } else if (fullAreas.length == 2) {
        return {
          id: product.id,
          category_id: fullAreas[0].id,
          sales_product_id: fullAreas[1].id,
          quantity: product.quantity,
          unit_price: product.unit_price,
          remarks: product.remarks,
        };
      } else {
        return {
          id: product.id,
          category_id: fullAreas[0].id,
          sales_product_id2: fullAreas[1].id,
          sales_product_id: fullAreas[2].id,
          quantity: product.quantity,
          unit_price: product.unit_price,
          remarks: product.remarks,
        };
      }
    });
    if (data_apply_products.length == 0) {
      data_apply_products = [
        {
          id: undefined,
          category_id: 0,
          sales_product_id: 0,
          quantity: 0,
          unit_price: 0,
          remarks: "",
        }
      ]
    }
    methods.reset({
      amount: apply.amount,
      consumption_tax: apply.consumption_tax,
      data_apply_products,
    });
  };

  const onClickAdd = () => {
    append({
      sales_product_id: 0,
      quantity: 0,
      unit_price: 0,
    });
  };

  const onClickDelete = () => {
    setIsShowDeleteModal(true);
  };

  const deleteApplyAreas = () => {
    const checkedIndexs = watchApplyAreas
      .map((item: any, index: number) => (item.checked ? index : -1))
      .filter((item: any) => item !== -1)
      .reverse();
    for (const index of checkedIndexs) {
      remove(index);
    }
  };

  const onClickCancel = () => {
    setBlocker(false);
    setIsShowCancelModal(true);
  };

  const submit = (data: any) => {
    if (!apply) {
      return;
    }
    setBlocker(false);
    setLoading(true);
    data = { ...data, id: apply.id, data_id: params.dataId };
    postApplyAreas(data)
      .then((res) => {
        console.log(res);
        navigate(
          Common.applyAreaListUrl(params.projectCategoryId, params.dataId)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <div>
          <div className="sub-header" style={{top: headerTop}}>
            <div className="uk-flex-between uk-flex uk-flex-middle uk-margin-small-top">
              <div className="precaution">※編集を行った場合の金額は自動計算はされません</div>
              <div>
                <button
                  type="button"
                  className="uk-button--m uk-button-cancel"
                  onClick={onClickCancel}
                >
                  キャンセル
                </button>
                <button
                  className="uk-button--m uk-button-refer uk-margin-left"
                  type="submit"
                >
                  保存
                </button>
              </div>
            </div>
          </div>
          <div className="container uk-margin-small-top">
            <div className="info-tbl">
              <table>
                <tbody>
                  <tr>
                    <td className="label">請求金額</td>
                    <td className="content">
                      <input
                        type="number"
                        className={`uk-width-1-1 ${
                          methods.formState.errors.amount ? "error-form" : ""
                        }`}
                        {...methods.register(`amount`, {
                          required: Config.MESSAGE_NO_E08,
                        })}
                      />
                      {methods.formState.errors.amount && (
                        <div className="error">{`${methods.formState.errors.amount.message}`}</div>
                      )}
                    </td>
                    <td className="label">消費税</td>
                    <td className="content">
                      <input
                        type="number"
                        className={`uk-width-1-1 ${
                          methods.formState.errors.amount ? "error-form" : ""
                        }`}
                        {...methods.register(`consumption_tax`, {
                          required: Config.MESSAGE_NO_E08,
                        })}
                      />
                      {methods.formState.errors.consumption_tax && (
                        <div className="error">{`${methods.formState.errors.consumption_tax.message}`}</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="uk-margin-top">
              <button
                className="uk-button--m uk-button-cancel"
                onClick={onClickDelete}
                disabled={!enableDeleteButton}
                type="button"
              >
                一括削除
              </button>
            </div>
            <div>
              <table className="apply-tbl uk-margin-small-top">
                <thead>
                  <tr>
                    <th></th>
                    <th>申込エリア</th>
                    <th>単価</th>
                    <th>数量</th>
                    <th>金額</th>
                    <th>備考</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((item: any, index: number) => {
                    return (
                      <ApplyAreaForm
                        applyArea={item}
                        index={index}
                        length={fields.length}
                        areas={areas}
                      />
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4} className="header">
                      金額合計（税抜）
                    </td>
                    <td colSpan={2} className="uk-text-right">
                      ¥ {Common.formatPrice(totalAmount)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="uk-flex uk-flex-right uk-margin-small-top">
              <button
                className="uk-button--m uk-button-refer"
                onClick={onClickAdd}
              >
                追加
              </button>
            </div>
          </div>
          <ConfirmModal
            text="選択したデータを一括削除しますか？"
            confirmButtonText="OK"
            isShow={isShowDeleteModal}
            onConfirm={() => {
              setIsShowDeleteModal(false);
              deleteApplyAreas();
            }}
            onCancel={() => {
              setIsShowDeleteModal(false);
            }}
          />
          <ConfirmModal
            text="登録をキャンセルしますか？入力したデータは登録されません。"
            confirmButtonText="OK"
            isShow={isShowCancelModal}
            onConfirm={() => {
              setIsShowCancelModal(false);
              navigate(Common.applyAreaListUrl(params.projectCategoryId, params.dataId));
            }}
            onCancel={() => {
              setIsShowCancelModal(false);
            }}
          />
          <BlockModal />
        </div>
      </form>
    </FormProvider>
  );
}
