import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBeforeUnload, useLocation, useNavigate, useParams } from "react-router-dom";
import { useLoadingStore } from '@/stores/loading';
import { useUserStore, User } from '@/stores/user'
import * as API from '@/api/approval';
import * as Common from '@/utils/common';
import * as Config from '@/config';
import { ApproveForm, DecisionUser } from './types';
import { FormProvider, useForm } from "react-hook-form";
import UserSearchBar from '@/components/UserSearchBar';
import moment from "moment";
import { useBlockerStore } from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";
import ConfirmModal from "@/components/ConfirmModal";

export default function ApproveEdit() {
  const headerTop = (document.querySelector("header")?.offsetHeight ?? 0)
    + (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  const navigate = useNavigate();
  const params = useParams();
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);
  const location = useLocation();
  const setBlocker = useBlockerStore(state => state.setBlocker);

  const [data, setData] = useState<any | null>();
  const [decisionUsers, setDecisionUsers] = useState<DecisionUser[]>([]);
  const [createUser, setCreateUser] = useState<User>();
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);

  const isApplied = useMemo(() => {
    return data?.approval_request_status_id === Config.APPROVAL_REQUEST_STATUS_APPLIED_ID;
  }, [data]);

  // 作成者 or 起案者
  const isCreator = useMemo(() => {
    if (data && user) {
      return data.create_user_id === user.id || data.draft_user_id == user.id;
    }
    return !data;
  }, [data, user]);

  // システム管理者 or 決済管理権限
  const isAdmin = useMemo(() => {
    if (data && user) {
      return (
        user.roll === Config.PERMISSION_ROLL_SYSADMIN ||
        (user.user_restrictions.find(
          (restriction) =>
            restriction.category_restriction_code ===
              Config.RESTRICTION_HISTORY_APPROVE &&
            restriction.category_restriction_value ===
              Config.RESTRICTION_HISTORY_APPROVE_ADMIN.value &&
            String(restriction.project_category_id) === params.projectCategoryId
        )
          ? true
          : false)
      );
    }
    return false;
  }, [data, user]);

  const methods = useForm<ApproveForm>();

  useBeforeUnload(useCallback(() => {
    if (params.dataId) navigator.sendBeacon(`${Config.API_URL}/api/v1/datas/unlock/${params.dataId}`);
  }, []));

  useEffect(() => {
    setBlocker(true);
    fetchData();
  }, [location.pathname]);

  function fetchData() {
    const projectCategoryId = params.projectCategoryId;
    const dataId = params.dataId;
    if (!projectCategoryId || !dataId) return;
    setLoading(true);
    API.getDataApprovalRequest({
      data_id: parseInt(dataId, 10),
      project_category_id: parseInt(projectCategoryId, 10),
    }).then(res => {
      const { dataApprovalRequest, decisionUsers } = res.data;
      if (dataApprovalRequest) {
        setCreateUser(dataApprovalRequest.create_user);
        initValues(dataApprovalRequest, decisionUsers);
      } else {
        setCreateUser(user);
        methods.setValue('create_user_id', user?.id);
        methods.setValue('draft_date', moment().format('YYYY-MM-DD'));
      }
      setDecisionUsers(decisionUsers);
      setData(dataApprovalRequest);
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setLoading(false);
    });
  }

  function initValues(data: any, decisionUsers: DecisionUser[]) {
    methods.setValue('create_user_id', data.create_user_id);
    methods.setValue('draft_user_id', data.draft_user_id);
    methods.setValue('draft_date', data.draft_date);
    methods.setValue('execution_date', data.execution_date);
    methods.setValue('decision_date', data.decision_date);
    methods.setValue('send_date', data.send_date);
    methods.setValue('decision_content', data.decision_content);
    methods.setValue('remarks', data.remarks);
    for (let i = 0; i < decisionUsers.length; i++) {
      const approvalDecisionUser = data.data_approval_decision_users?.find((user: any) => user.decision_users_id === decisionUsers[i].id);
      if (approvalDecisionUser) {
        methods.setValue(`data_approval_decision_users.${i}.is_decision_user`, approvalDecisionUser.is_decision_user === 1);
        methods.setValue(`data_approval_decision_users.${i}.id`, approvalDecisionUser.id);
        methods.setValue(`data_approval_decision_users.${i}.comments`, approvalDecisionUser.comments);
        methods.setValue(`data_approval_decision_users.${i}.approval_decision_status_id`, approvalDecisionUser.approval_decision_status_id);
      }
    }    
  }

  const gernerateLinkableWidget = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  const onClickCancel = () => {
    setBlocker(false);
    setIsShowCancelModal(true);
  };

  const onSubmit = (submitData: any) => {
    setBlocker(false);

    let filter = data?.data_approval_decision_users?.filter((item: any) => item.decision_users_id == user?.id) ?? [];
    if (!isAdmin && isApplied && filter.length > 0) {
      const approvalDecisionUser = filter[0];
      const index = decisionUsers.findIndex(item => item.id == approvalDecisionUser.decision_users_id);
      setLoading(true);
      API.postDataApprovalDecisionUser({
        id: approvalDecisionUser.id,
        comments: submitData.data_approval_decision_users[index].comments,
      }).then(res => {
        if (res.data.result) {
          navigate(Common.approveDetailUrl(params.projectCategoryId, params.dataId));
        }
      }).catch(e => {
        console.log(e);
      }).finally(() => {
        setLoading(false);
      });
      return;
    }

    if (data?.id) {
      submitData.id = data.id;
    }

    const requestParams = {
      ...submitData,
      data_approval_decision_users: submitData.data_approval_decision_users?.map((item: any, index: number) => {
        return {
          ...item,
          is_decision_user: item.is_decision_user ? 1 : 0,
          approval_decision_status_id: item.approval_decision_status_id ?? Config.APPROVAL_DECISION_STATUS_YET_ID,
          decision_users_id: decisionUsers[index].id,
        }
      }).filter((item: any) => item),
      data_id: params.dataId,
      project_category_id: params.projectCategoryId,
      approval_request_status_id: data?.approval_request_status_id ?? Config.APPROVAL_REQUEST_STATUS_YET_ID,
    };

    setLoading(true);
    API.postDataApprovalRequest(requestParams).then((res) => {
      if (res.data.result) {
        navigate(Common.approveDetailUrl(params.projectCategoryId, params.dataId));
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const renderDecisionUser = (decisionUser: DecisionUser, index: number) => {
    const approvalDecisionUser = data?.data_approval_decision_users?.find((user: any) => user.decision_users_id === decisionUser.id);
    const isDecisionUser = approvalDecisionUser?.is_decision_user === 1;
    const isApproved = approvalDecisionUser?.approval_decision_status_id === Config.APPROVAL_DECISION_STATUS_APPROVED_ID;
    const approvalDecisionDatetime =
      approvalDecisionUser?.approval_decision_datetime
        ? moment(approvalDecisionUser?.approval_decision_datetime).format(
            "YYYY/MM/DD HH:mm"
          )
        : "";
    const decisionUserName = `${decisionUser.user.last_name} ${decisionUser.user.first_name}`;
    const isMe = decisionUser.user_id === user?.id;
    const showComment = isApplied && isDecisionUser && (isMe || isAdmin);
    const isDisabled = !isDecisionUser && isApplied;

    let content = <></>;
    if (data) {
      if ((isAdmin || isCreator) && !isApplied) {
        content = (
          <label>
            <input 
              type="checkbox" 
              {...methods.register(`data_approval_decision_users.${index}.is_decision_user`)}
            />
            {decisionUserName}
          </label>
        )
      } else {
        content = (
          <div className="uk-flex uk-flex-middle uk-margin-small-right">
            <div className="uk-width-small uk-margin-small-left">
              {decisionUserName}
            </div>
            <div className="uk-text-nowrap uk-margin-small-left">
              {isApproved ? `承認済み` : "未承認"}
            </div>
            {isApproved && (
              <div className="uk-text-nowrap uk-margin-small-left">
                {approvalDecisionDatetime}
              </div>
            )}
          </div>
        );
      }
    } else {
      content = (
        <label>
          <input 
            type="checkbox" 
            {...methods.register(`data_approval_decision_users.${index}.is_decision_user`)}
          />
          {decisionUserName}
        </label>
      )
    }

    return (
      <tr key={`decision_user_${index}`}>
        <input type="hidden" name={`data_approval_decision_users.${index}.id`} />
        <input type="hidden" name={`data_approval_decision_users.${index}.approval_decision_status_id`} />
        <td className="label">{ decisionUser.position_name }</td>
        <td className={`content ${isDisabled ? 'disabled' : ''}`}>
          {content}
        </td>
        <td className={`${isDisabled ? 'disabled' : ''}`}>
          {showComment && renderEditComment(index)}
        </td>
      </tr>
    );
  }

  function renderEditComment(index: number) {
    const error = methods.formState.errors.data_approval_decision_users?.[index]?.comments?.message;
    return (
      <>
        <input 
          className={`uk-width-full ${
            error ? "error-form" : ""
          }`}
          {...methods.register(`data_approval_decision_users.${index}.comments`, {
            maxLength: {
              value: 50,
              message: Config.MESSAGE_NO_E35(50),
            },
          })}
        />
        {error && <div className="error">{ error }</div>}
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div>
          <div className="sub-header" style={{top: headerTop}}>
            <div className="uk-flex uk-flex-right uk-margin-small-top">
              <button
                className="uk-button--m uk-button-cancel"
                type="button"
                onClick={onClickCancel}
              >
                キャンセル
              </button>
              <button
                className="uk-button--m uk-button-refer uk-margin-left"
                type="submit"
              >
                保存
              </button>
            </div>
          </div>
          <div className="container">
            {Object.keys(methods.formState.errors).length > 0 && <div className="error-box">入力エラーがあります</div>}
            <div className="uk-flex uk-flex-right uk-margin-small-top">
              <table className="uk-width-1-2">
                <tr>
                  <td className="uk-width-1-6 header">作成者</td>
                  <td className="uk-width-1-3"><input type="hidden" name="create_user_id"/>{createUser?.last_name} {createUser?.first_name}</td>
                  <td className="uk-width-1-6 header">起案者</td>
                  <td className="uk-width-1-3">
                    {isApplied && <>{data?.draft_user?.last_name} {data?.draft_user?.first_name}</>}
                    {!isApplied && (
                      <UserSearchBar name="draft_user_id" defaultUser={data?.draft_user} />
                    )}
                  </td>
                </tr>
              </table>
            </div>
            <div className="approve-tbl">
              <table className="uk-width-1-1">
                <thead>
                  <tr>
                    <th colSpan={2} className="uk-width-1-2 uk-text-center">
                      決裁者
                    </th>
                    <th className="uk-width-1-2 uk-text-center">コメント</th>
                  </tr>
                </thead>
                <tbody>
                  {decisionUsers.map(renderDecisionUser)}
                </tbody>
              </table>
            </div>
            <div className="approve-tbl">
              <table className="uk-width-1-1">
                <tbody>
                  <tr>
                    <td className="label">起案</td>
                    <td className="content">
                      {isApplied && (
                        data?.draft_date ?? ''
                      )}
                      {!isApplied && (
                        <input 
                          disabled={isApplied}
                          type="date"
                          className={`uk-width-1-1 ${
                            methods.formState.errors.draft_date?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('draft_date')}
                        />
                      )}
                      {methods.formState.errors.draft_date && <span className="error">{methods.formState.errors.draft_date.message?.toString()}</span>}
                    </td>
                    <td className="label">施行</td>
                    <td className="content">
                      {isApplied && (
                        data?.execution_date ?? ''
                      )}
                      {!isApplied && (
                        <input 
                          disabled={isApplied}
                          type="date"
                          className={`uk-width-1-1 ${
                            methods.formState.errors.execution_date?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('execution_date')}
                        />
                      )}
                      {methods.formState.errors.execution_date && <span className="error">{methods.formState.errors.execution_date.message?.toString()}</span>}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">決裁</td>
                    <td className="content">
                      {isApplied && (
                        data?.decision_date ?? ''
                      )}
                      {!isApplied && (
                        <input 
                          disabled={isApplied}
                          type="date"
                          className={`uk-width-1-1 ${
                            methods.formState.errors.decision_date?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('decision_date')}
                        />
                      )}
                      {methods.formState.errors.decision_date && <span className="error">{methods.formState.errors.decision_date.message?.toString()}</span>}
                    </td>
                    <td className="label">発送</td>
                    <td className="content">
                      {isApplied && (
                        data?.send_date ?? ''
                      )}
                      {!isApplied && (
                        <input 
                          disabled={isApplied}
                          type="date"
                          className={`uk-width-1-1 ${
                            methods.formState.errors.send_date?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('send_date')}
                        />
                      )}
                      {methods.formState.errors.send_date && <span className="error">{methods.formState.errors.send_date.message?.toString()}</span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="approve-tbl">
              <table className="uk-width-1-1">
                <tbody>
                  <tr>
                    <td className="label">決裁内容</td>
                    <td className="uk-width-4-5">
                      {isApplied && (
                        <div className="line-break h-195 uk-overflow-auto">
                          <p>{data?.decision_content}</p>
                        </div>
                      )}
                      {!isApplied && (
                        <textarea 
                          className={`uk-width-1-1 h-195 ${
                            methods.formState.errors.decision_content?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('decision_content', {
                            maxLength: {
                              value: 500,
                              message: Config.MESSAGE_NO_E35(500),
                            },
                          })}
                        />
                      )}
                      {methods.formState.errors.decision_content && <span className="error">{methods.formState.errors.decision_content.message?.toString()}</span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="approve-tbl uk-margin-bottom">
              <table className="uk-width-1-1">
                <tbody>
                  <tr>
                    <td className="label">備考</td>
                    <td className="uk-width-4-5">
                      {isApplied && (
                        <div className="line-break h-111 uk-overflow-auto">
                          {gernerateLinkableWidget(data?.remarks ?? '')}
                        </div>
                      )}
                      {!isApplied && (
                        <textarea 
                          disabled={isApplied}
                          className={`uk-width-1-1 h-111 ${
                            methods.formState.errors.remarks?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('remarks', {
                            maxLength: {
                              value: 500,
                              message: Config.MESSAGE_NO_E35(500),
                            },
                          })}
                        />
                      )}
                      {methods.formState.errors.remarks && <span className="error">{methods.formState.errors.remarks.message?.toString()}</span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ConfirmModal
            text="登録をキャンセルしますか？入力したデータは登録されません。"
            confirmButtonText="OK"
            isShow={isShowCancelModal}
            onConfirm={() => {
              setIsShowCancelModal(false);
              navigate(Common.approveDetailUrl(params.projectCategoryId, params.dataId));
            }}
            onCancel={() => {
              setIsShowCancelModal(false);
            }}
          />
          <BlockModal />
        </div>
      </form>
    </FormProvider>
  );
}
