import React from 'react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

interface Option {
  label: string;
  value: string;
}

interface props {
  name: string;
  options: Option[] | undefined;
  register: UseFormRegister<any>;
  rules?: Record<string, any>;
  errors: DeepMap<any, FieldError>;
}

export const CheckBox: React.FC<props> = ( { name, options, register, rules, errors } ) => {
  return (
    <>
      {options?.map(( option, index ) => (
        <div className={index > 0 ? "uk-margin-left" : ""} key={index}>
          <label key={index}>
            <input
              type="checkbox"
              value={option.value}
              {...register(`${name}.${option.value}`, rules)}
            />
            {option.label}
          </label>
        </div>
      ))}
      {/*{errors[name] && <p>{errors[name].message}</p>}*/}
    </>
  );
};