import create from 'zustand';

interface CustomerStore {
  queryParams: any | undefined;
  setQueryParams: (any: any | undefined) => void;
  drmPfCommonState: DrmPfCommonState;
  setDrmPfCommonState: (update: Partial<DrmPfCommonState>) => void;
  childSubmit: () => void;
  setChildSubmit: (submit: () => void) => void;
}

export interface DrmPfCommonState {
  customerNumber?: string;
  modified?: string;
  modified_by?: string;
}

const initState = {
  queryParams: undefined,
  drmPfCommonState: {} as DrmPfCommonState,
  childSubmit: () => {
  }
}

// 検索条件の保持に使用
export const useCustomerStore = create<CustomerStore>((set) => ({
    ...initState,
    setQueryParams: (queryParams) =>
      set((state) => ({
        queryParams: queryParams,
      })),
    setDrmPfCommonState: (update) => set((state) => ({
      drmPfCommonState: {...state.drmPfCommonState, ...update}
    })),
    setChildSubmit: (childSubmit) => set({childSubmit: childSubmit})
  }))
;
