import create from 'zustand';
import { immer } from 'zustand/middleware/immer'

export type UserRestrictions = {
  id: number,
  project_category_id: number,
  category_restriction_code: string,
  category_restriction_value: number,
  tab_id: number
};

export type User = {
  id: number,
  roll: string,
  last_display_project_id: number;
  last_name: string;
  first_name: string;
  email_address: string;
  logged_in_date: string;
  is_changed_password: number;
  user_restrictions: UserRestrictions[];
  has_drm_pf_credentials: boolean;
  group_id: number;
};

type UserStore = {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
  setIsChangedPassword: (isChanged: number) => void;
  setUserRestrictions: (userRestrictions: UserRestrictions[]) => void;
};

export const useUserStore = create<UserStore>()(
  immer((set) => ({
    user: undefined,

    setUser: (user) =>
      set((state) => ({
        user: user,
      })),
    setIsChangedPassword: (isChanged: number) =>
      set((state) => {
        if (state.user) state.user.is_changed_password = isChanged;
      }),

    setUserRestrictions: (userRestrictions) =>
      set((state) => {
        if (state.user) state.user.user_restrictions = userRestrictions;
      }),
  }))
);
