import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useLoadingStore} from '@/stores/loading'
import {useProjectStore} from '@/stores/project'
import {useUserStore} from '@/stores/user'
import {useForm, useWatch} from 'react-hook-form'
import {DataFileInputs, InputFile} from '@/components/Form/Files'
import {axios} from '@/lib/axios'
import * as Common from '@/utils/common'
import * as Config from '@/config'
import moment from 'moment'
import CustomSelect from '@/components/CustomSelect'
import {Document, Page} from 'react-pdf'
import {useBlockerStore} from '@/stores/blocker'
import BlockModal from '@/components/BlockModal'
import ConfirmModal from '@/components/ConfirmModal'
import {useMessageModalStore} from '@/stores/message'
import {MESSAGE_NO_E32, MESSAGE_NO_E37} from '@/config'

// TODO: 共通化？
export default function FileEdit() {
  const hiddenFileInput = useRef<HTMLInputElement>(null); // ファイル選択ボタンの装飾

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const user = useUserStore(state => state.user);
  const [isBlocker, setBlocker] = useBlockerStore(state => [state.isBlocker, state.setBlocker]);
  const {
    register,
    reset,
    getValues,
    clearErrors,
    setError,
    control,
    setValue,
    formState: {errors}
  } = useForm();

  const [inputFile, setInputFile] = useState<InputFile>();
  const [fileAttributes, setFileAttributes] = useState<any[]>([]);
  const [dataFile, setDataFile] = useState<any | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const selectedFileAttribute = useWatch({
    control,
    name: `.file_attribute_code`,
  });

  const [isCancelEditConfirm, setIsCancelEditConfirm] = useState(false);
  const [isFileChange, setIsFileChange] = useState(false);

  function getInputs() {
    setLoading(true);
    axios.get('/api/v1/fileAttributes/inputs', {
      params: {
        project_category_id: selectedProjectCategory?.id,
        data_files_id: params.fileId,
      }
    }).then(result => {
      setFileAttributes(result.data.fileAttributes);
      const dataFile = result.data.dataFile;
      const s3Urls = result.data.s3Urls;
      dataFile["s3FilePath"] = s3Urls[dataFile["id"]]["filepath"];
      dataFile["s3Thumbnail"] = s3Urls[dataFile["id"]]["thumbnail"];
      setDataFile(dataFile);
      reset({
        filename: dataFile.filename,
        file_attribute_code: dataFile.file_attribute_code,
        remarks: dataFile.remarks,
      })
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  const uploadFile = async (inputFile: InputFile, formAttributes: any, formInputs: any[]) => {
    const formData = new FormData();
    Object.entries(formInputs).forEach(([key, value]) => {
      if (key === "key") {
        formData.append(key, value + inputFile.tempPath);
      } else formData.append(key, value);
    });
    formData.append('file', inputFile.file);

    const response = await axios.post(formAttributes.action, formData,  {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  };

  async function post() {
    setErrorMessage("");
    clearErrors();
    setLoading(true);
    const inputData = getValues();
    let fileData = {};
    if (inputFile) {
      console.log(Common.byteConverter(inputFile.file.size, "MB"))
      if (Common.byteConverter(inputFile.file.size, "MB") > 500) {
        setErrorMessage(Config.MESSAGE_NO_E40);
        setLoading(false);
        return
      }

      try {
        const s3 = await axios.get('/api/v1/s3/getSignedPostUrl', {}).then(result => {
          return result.data;
        });
        const results = await uploadFile(inputFile, s3.formAttributes, s3.formInputs);
      } catch (error) {
        setErrorMessage("アップロードに失敗しました。");
        setLoading(false);
        return
      }
      fileData = {
        original_filename: inputFile.file.name,
        tempPath: inputFile.tempPath,
      };
    }
    const postData = {
      id: params.fileId,
      data_id: params.dataId,
      isUpload: inputFile !== undefined,
      data_file: {
        ...inputData,
        ...fileData,
      },
    };
    const result = await axios.post('/api/v1/dataFiles/edit', postData
    ).then(result => {
      return result.data.result;
    }).catch(error => {
      if (error?.response?.data?.errors) {
        const errors = error?.response?.data?.errors;
        setErrorMessage("入力エラーがあります");
        for (const key in errors) {
          setError(`${key}`, {type: 'sever', message: Common.getNestedValue(errors[key])})
        }
      } else if (error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setMessage(Config.MESSAGE_NO_E37);
      }
      return false;
    });
    setLoading(false);
    if (result)
      navigate(Common.filesListUrl(params.projectCategoryId, params.dataId));
  }

  useEffect(() => {
    getInputs();
    setBlocker(Config.isDataEdit(Config.Data.FileEdit));
  }, [location.pathname]);

  const onClickCancel = () => {
    setBlocker(false);
    setIsCancelEditConfirm(true);
  }

  const onClickSave = () => {
    setBlocker(false);
    post();
  }

  const onClickFileSelect = () => {
    if (hiddenFileInput.current)
      hiddenFileInput.current.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files)
      setUploadInfo(e.target.files[0]);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files)
      setUploadInfo(e.dataTransfer.files[0]);
  };

  const setUploadInfo = (file: File) => {
    const name = `${user?.last_name}${user?.first_name}`;
    const date = new Date();

    const inputFile: InputFile = {
      file: file,
      tempPath: file.name + "_" + Math.random().toString(36).substring(2, 15),
      preview: URL.createObjectURL(file),
    };

    setInputFile(inputFile);
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="sub-header">
        <div className="uk-flex-between uk-flex uk-flex-middle uk-margin-small-top">
          <div className="uk-inline">
          </div>
          <div>
            <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickCancel}>キャンセル</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
          </div>
        </div>
      </div>
      <div className="container">
        {errorMessage.length > 0 && <div className="error-box">{errorMessage}</div>}
        <div className="file-tbl-wrap uk-margin-small-top">
          {dataFile &&
              <div className="file-tbl">
                  <table>
                      <tr>
                          <td rowSpan={5} className="media">
                              <div
                                  onDrop={onDrop}
                                  onDragOver={onDragOver}
                                  className="media_flex"
                              >
                                {inputFile ?
                                  <>
                                    {inputFile.file.type === 'application/pdf' ?
                                      <Document file={inputFile.preview} renderMode="canvas">
                                        <Page pageNumber={1} width={120}  height={90} />
                                      </Document>
                                      :
                                      <div className="media_img">
                                        {inputFile.file.type.startsWith('image/') ?
                                          <img src={inputFile.preview} />
                                          : <span>No Image</span>}
                                      </div>}
                                  </>
                                  :
                                  <>
                                  {dataFile.s3Thumbnail !== "" ?
                                    <img src={dataFile.s3Thumbnail} />: <span>No Image</span>
                                  }
                                  </>
                                }
                                  <button className="uk-button--m uk-button-cancel" onClick={() => setIsFileChange(true)}>ファイル選択</button>
                                  <input type="file" className="uk-hidden" onChange={onFileChange} ref={hiddenFileInput} />
                              </div>
                          </td>
                          <td className="label">ファイル名</td>
                          <td className="content">{inputFile ? "自動取得" : dataFile.filename}</td>
                          <td className="label">元ファイル名</td>
                          <td className="content">{inputFile ? inputFile.file.name : dataFile.original_filename}</td>
                      </tr>
                      <tr>
                          <td className="label">属性<span className="error">※</span></td>
                          <td colSpan={3} className="content">
                              <select className={`uk-width-1-3 ${selectedFileAttribute ? '' : 'placeholder'} ${errors.file_attribute_code ? "error-form" : ""}`}
                                      defaultValue={""} {...register(`file_attribute_code`)}>
                                  <option value="" disabled>属性を選択してください</option>
                                {fileAttributes.map((o: any) => {
                                  return <option value={o.code}>{o.code + ': ' + o.name}</option>
                                })}
                              </select>
                            {errors.file_attribute_code && <div className="error">{`${errors.file_attribute_code.message}`}</div>}
                          </td>
                      </tr>
                      <tr>
                          <td className="label">備考</td>
                          <td colSpan={3} className="content">
                              <textarea
                                  className={`uk-width-1-1 ${errors.remarks ? "error-form" : ""}`}
                                  {...register(`remarks`)}
                              />
                            {errors.remarks && <div className="error">{`${errors.remarks.message}`}</div>}
                          </td>
                      </tr>
                      <tr>
                          <td className="label">作成日時</td>
                          <td className="content">{moment(dataFile.created).format(Config.DATE_FORMAT)}</td>
                          <td className="label">作成者</td>
                          <td className="content">{dataFile.created_user && `${dataFile.created_user.last_name}${dataFile.created_user.first_name}`}</td>
                      </tr>
                      <tr>
                          <td className="label">更新日時</td>
                          <td className="content">{moment(dataFile.modified).format(Config.DATE_FORMAT)}</td>
                          <td className="label">更新者</td>
                          <td className="content">{dataFile.modified_user && `${dataFile.modified_user.last_name}${dataFile.modified_user.first_name}`}</td>
                      </tr>
                  </table>
              </div>
          }
        </div>
      </div>
      <BlockModal />
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="OK"
        isShow={isCancelEditConfirm}
        onConfirm={() => {
          setIsCancelEditConfirm(false);
          navigate(Common.filesListUrl(params.projectCategoryId, params.dataId));
        }}
        onCancel={() => {
          setIsCancelEditConfirm(false);
          setBlocker(Config.isDataEdit(Config.Data.FileEdit));
        }}
      />
      <ConfirmModal
        text={Config.MESSAGE_NO_E32}
        confirmButtonText="OK"
        isShow={isFileChange}
        onConfirm={() => {
          onClickFileSelect();
          setIsFileChange(false);
        }}
        onCancel={() => {
          setIsFileChange(false);
        }}
      />
    </div>
  );
}