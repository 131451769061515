import React, { Fragment } from 'react';
import MessageList from '../../../components/MessageList';
import { Faq, Option, TicketComment } from "@/containers/data/Public/Public";
import ReactMarkdown from "react-markdown";

interface DetailProps {
  faq: Faq
  faqStatuses: Option[]
  faqTypes: Option[]
  faqKinds: Option[]
  ticketComments: TicketComment[]
}

export default function PublicDetailItem( props: DetailProps ) {
  const { faq, faqStatuses, faqTypes, faqKinds, ticketComments } = props

  const status = () => faqStatuses.find(status => status.id === faq.faq_status_id)
  const types = () => faqTypes.filter(( type ) => {
    return faq.faq_types?.some(( value ) => value.id === type.id && value.checked)
  })
  const kinds = () => faqKinds.filter(( kind ) => {
    return faq.faq_kinds?.some(( value ) => value.id === kind.id && value.checked)
  })

  return (
    <div className="info-tbl-wrap uk-margin-top mb-40">
      <div className="info-tbl">
        <table className="uk-width-1-2">
          <tr>
            <td className="label">ステータス</td>
            <td className="content">
              {status()?.name}
            </td>
          </tr>
        </table>
      </div>

      <div className="info-tbl">
        <table className="uk-width-1-1">
          <tr>
            <td className="label">情報区分</td>
            <td className="content">
                <span className="uk-margin-right">
              {types().map(( type, index ) => (
                <Fragment key={`type_${type.id}`}>{index > 0 && ','}{type.name}</Fragment>
              ))}
                </span>
            </td>
            <td className="label">情報種別</td>
            <td className="content">
                <span className="uk-margin-right">
              {kinds().map(( kind, index ) => (
                <Fragment key={`kind_${kind.id}`}>{index > 0 && ','}{kind.name}</Fragment>
              ))}
                </span>
            </td>
          </tr>
        </table>
      </div>

      <div className="info-tbl">
        <table className="uk-width-1-1">
          <tr>
            <td className="label">管理番号</td>
            <td>
              {String(faq.id).padStart(5, '0')}
            </td>
          </tr>
          <tr>
            <td className="label">公開タイトル</td>
            <td colSpan={3}>{faq.title}</td>
          </tr>
          <tr>
            <td className="label">公開質問</td>
            <td colSpan={3}>
              <div className="min-h-87 line-break"><ReactMarkdown>{faq?.question}</ReactMarkdown></div>
            </td>
          </tr>
          <tr>
            <td className="label">公開内容</td>
            <td colSpan={3}>
              <div className="min-h-87 line-break"><ReactMarkdown>{faq?.answer}</ReactMarkdown></div>
            </td>
          </tr>
        </table>
      </div>

      <div className="info-tbl">
        <table className="uk-width-1-1">
          <tr>
            <td className="label">備考</td>
            <td colSpan={3}>
              <div className="min-h-87">{faq.remarks}</div>
            </td>
          </tr>
        </table>
      </div>
      <div className="uk-flex uk-flex-right">最終更新ユーザ：{faq.modified_by} 最終更新:{faq.modified}</div>

      <div className="uk-margin-top">
        <MessageList ticketComments={ticketComments}/>
      </div>
    </div>
  );
}