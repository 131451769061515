import * as Config from '@/config'
import { axios } from '@/lib/axios';
import { useMapDrawing } from "@/stores/mapDrawing";
import { DRM_PF_FREE_MAP_URL } from "@/config";

function usePostPf() {
  const formDataForMap = async ( attribute: any, value: string ) => {
    type FormData = {
      id: string
      password: string
      accessFlg: string
      geojsons?: any
      p_ids?: string
    }
    let drm_pf_login_id = "";
    let drm_pf_password = "";
    await axios.get('/api/v1/users/getPfAuth').then(result => {
      drm_pf_login_id = result.data.drm_pf_login_id;
      drm_pf_password = result.data.drm_pf_password;
    })

    let formData: FormData = {
      id: drm_pf_login_id,
      password: drm_pf_password,
      accessFlg: '1',
    };
    const attrValue = value.replace(/[\n\r\t ]+/g, '');
    attribute.attribute_setting_values.forEach(( setting_value: any ) => {
      switch (setting_value.attribute_setting_code) {
        case Config.ATTRIBUTE_SETTINGS_CODE_GEO_JSON:
          formData = {
            ...formData,
            accessFlg: '2',
            geojsons: `{"feature_1":${attrValue}}`,
            // geojsons: `{"feature_1":${sampleGeoJson}}`
          };
          break;
        case Config.ATTRIBUTE_SETTINGS_CODE_P_ID:
          formData = {
            ...formData,
            accessFlg: '3',
            p_ids: `{"p_ids":"${attrValue}"}`,
            // p_ids: `{"p_ids":"${sampleP_id}"}`
          };
          break;
      }
    });
    console.log(formData.geojsons)
    return formData;
  }

  const postExternalSite = ( url: string, data: { [key: string]: any } ) => {
    // 一時的なフォームを作成
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    form.target = '_blank'; // 新しいタブで結果を開く

    // input要素を作成し、フォームに追加
    Object.keys(data).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    });

    // フォームをbodyに追加し、送信
    document.body.appendChild(form);
    form.submit();

    // 送信後にフォームを削除
    document.body.removeChild(form);
  }
  const openFreeViewer = () => {
    window.open(`${Config.DRM_PF_FREE_MAP_URL}`, "_blank");
  }
  return { formDataForMap, postExternalSite, openFreeViewer };
}


export default usePostPf;
