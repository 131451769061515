import ConfirmModal from "@/components/ConfirmModal";
import useAutosizeTextArea from "@/utils/useAutosizeTextArea";
import React, { Fragment, useEffect,  useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import clipLIcon from "@/assets/images/clip_l.png";
import { useFieldArray, useForm } from "react-hook-form";
import {
  MESSAGE_NO_E08,
  MESSAGE_NO_E35,
  MESSAGE_NO_E41,
  MESSAGE_NO_E66,
  RESTRICTION_HISTORY_TICKET_VIEW
} from "@/config";
import { Ticket } from "@/containers/data/Ticket/Ticket";
import { useLoadingStore } from "@/stores/loading";
import { useUserStore } from "@/stores/user";
import { postComment } from "@/api/ticket";
import * as Common from "@/utils/common";
import { useProjectStore } from "@/stores/project";
import { useBlockerStore } from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";

interface Props {
  ticket?: Ticket
  getTicket: () => void
}

export interface PostComment {
  data_id: number
  data_ticket_id: number
  is_inquiry_user: boolean
  created_by: number
  comment: string
  attached_files?: {
    original_filename: string
    file_content: string
  }[]
}

interface ModalMessage {
  submit?: boolean
  message?: string
}

export default function MessageInputBox( { ticket, getTicket }: Props ) {
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);
  const [isBlocker, setBlocker] = useBlockerStore(state => [state.isBlocker, state.setBlocker]);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const canTicket = Common.canTicket(selectedProjectCategory?.id, user) > RESTRICTION_HISTORY_TICKET_VIEW.value

  const {
    register,
    trigger,
    watch,
    control,
    reset,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm<PostComment>({
    reValidateMode: 'onSubmit',
    values: {
      data_id: Number(ticket?.data_id),
      data_ticket_id: Number(ticket?.data_ticket_id),
      is_inquiry_user: false,
      created_by: Number(user?.id),
      comment: "",
    }
  })
  const {
    fields,
    append,
    remove
  } = useFieldArray({
    name: "attached_files",
    control
  })
  const watchTextArea = watch("comment");
  const watchFiles = watch("attached_files");

  useAutosizeTextArea("ticket-comment-textarea", watchTextArea);
  const [modalMessage, setModalMessage] =
    useState<ModalMessage>({})

  const onFileChange = ( files: FileList ) => {
    remove()
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = ( e ) => {
        const base64 = e.target?.result;
        append({ original_filename: file.name, file_content: String(base64) });
      };
      reader.readAsDataURL(file);
    });
  }

  const onClickSend = ( e: any ) => {
    if (canTicket) {
      const setMessage = watchTextArea.length > 0 ? {
        submit: true, message: MESSAGE_NO_E41
      } : {
        message: MESSAGE_NO_E66
      }
      setModalMessage(setMessage);
    }
  };

  const onConfirm = () => {
    if (canTicket) {
      modalMessage.submit && onSubmit();
      setModalMessage({});
    }
  }

  const onCancel = () => {
    setModalMessage({});
  }

  const onSubmit = () => {
    clearErrors()
    const data = getValues()
    trigger().then(( isValid ) => {
      if (!isValid) {
        console.log("バリデーションエラーがあります");
        return;
      }
      setLoading(true)
      console.log(data)
      postComment(data).then(result => {
        getTicket()
      }).catch(error => {
        setLoading(false)
      }).finally(() => {
        reset()
        remove()
      })
    });
  }

  useEffect(() => {
    const bool = !!(watchFiles && watchFiles.length > 0) || !!(watchTextArea && watchTextArea.length > 0)
    setBlocker(bool);
  }, [watchFiles, watchTextArea])

  return (
    <div className="message-input-box">
      <textarea
        id="ticket-comment-textarea"
        {...register("comment", {
          required: MESSAGE_NO_E08,
          maxLength: { value: 1000, message: MESSAGE_NO_E35(1000) }
        })}
        rows={3}
      />
      {errors.comment && <p className="precaution">{errors.comment.message}</p>}
      <hr/>
      <div className="uk-flex uk-flex-middle">
        <FileUploader
          classes="file-drop"
          name={"files"}
          handleChange={( files: any ) => onFileChange(files)}
          multiple={true}
          children={
            <>
              {fields.length > 0
                ? <div className="uk-flex">
                  {fields.map(( file, index ) => (
                    <Fragment key={index}>
                      <img
                        src={clipLIcon}
                        width={22}
                        height={22}
                        className="uk-margin-small-right"
                      />{file.original_filename}
                    </Fragment>
                  ))}
                </div>
                :
                <div className="uk-flex">
                  <img
                    src={clipLIcon}
                    width={22}
                    height={22}
                    className="uk-margin-small-right"
                  />
                  添付ファイルがある場合にはクリックして選択するか、ファイルをドロップしてください
                </div>
              }
            </>
          }
        />
        <button className="uk-button--m uk-button-refer" onClick={onClickSend} >
          送信
        </button>
      </div>
      <BlockModal/>
      <ConfirmModal
        text={modalMessage.message ?? ''}
        confirmButtonText="OK"
        isShow={!!(modalMessage.message)}
        onConfirm={onConfirm}
        onCancel={modalMessage.submit ? onCancel : undefined}
      />
    </div>
  );
}
