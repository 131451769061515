import { RESTRICTION_HISTORY_MANAGE_INFORMATION_OK } from "@/config";
import { useUserStore } from "@/stores/user";
import { canInformationManagement } from "@/utils/common";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * ユーザのアクセス権限チェックをし、権限が無ければリダイレクト
 */
export const useAuthCheck = () => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const canUser = canInformationManagement(user);

  useEffect(() => {
    if (canUser < RESTRICTION_HISTORY_MANAGE_INFORMATION_OK.value) {
      navigate("/admin");
    }
  }, [canUser, navigate]);
};
