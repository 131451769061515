import React, { useState } from "react";
import { axios } from "@/lib/axios";
import { useLoadingStore } from "@/stores/loading";
import { useNavigate } from "react-router-dom";
import * as Common from "@/utils/common";
import ConfirmModal from "@/components/ConfirmModal";
import { useUserStore } from "@/stores/user";
import * as Config from "@/config";
import { useMessageModalStore } from "@/stores/message";
import { MESSAGE_NO_E37 } from "@/config";

export default function PasswordChange() {
  const navigate = useNavigate();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore((state) => state.setMessage);
  const [user, setIsChangedPassword] = useUserStore((state) => [
    state.user,
    state.setIsChangedPassword,
  ]);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [error, setError] = useState("");
  const [hide1, setHide1] = useState(true);
  const [hide2, setHide2] = useState(true);

  const onClickEye1 = () => {
    setHide1(!hide1);
  };

  const onClickEye2 = () => {
    setHide2(!hide2);
  };

  const onClickSubmit = () => {
    setLoading(true);
    axios
      .post("/api/v1/users/changePassword", {
        password: password,
        passwordConfirm: passwordConfirm,
      })
      .then((result) => {
        if (Object.keys(result.data.errors).length > 0) {
          setError(Common.getNestedValue(result.data.errors));
        } else {
          if (!user!.is_changed_password) {
            setIsChangedPassword(1);
            navigate("/progress");
          } else {
            navigate(-1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setMessage(Config.MESSAGE_NO_E37);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickCancel = () => {
    setIsShowBackModal(true);
  };

  return (
    <div id="password-change">
      <div className="login-container">
        <div className="password-change-form">
          <div className="login-form-header">
            <p>パスワード変更</p>
          </div>
          <div className="login-form-in">
            {error !== "" && <div className="error-box">{error}</div>}
            <p>
              新しいパスワードを設定し、[パスワード変更]ボタンを押してください。パスワードには半角英数字、-（ハイフン）、__（アンダースコア）が使用可能です。6〜16文字で設定してください。
            </p>
            <span>初期パスワードは使用できません。</span>
            <div className="password">
              <p>新パスワード</p>
              <div className="uk-inline">
                <input
                  id="textPassword"
                  className="uk-input uk-form-small password-form"
                  type={hide1 ? "password" : "text"}
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  id="buttonEye"
                  className={
                    hide1
                      ? "uk-form-icon uk-form-icon-flip fa fa-eye-slash"
                      : "uk-form-icon uk-form-icon-flip fa fa-eye"
                  }
                  onClick={onClickEye1}
                ></button>
              </div>
            </div>
            <div className="password">
              <p>新パスワード（確認）</p>
              <div className="uk-inline">
                <input
                  id="textPassword"
                  className="uk-input uk-form-small password-form"
                  type={hide2 ? "password" : "text"}
                  placeholder="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <button
                  id="buttonEye"
                  className={
                    hide2
                      ? "uk-form-icon uk-form-icon-flip fa fa-eye-slash"
                      : "uk-form-icon uk-form-icon-flip fa fa-eye"
                  }
                  onClick={onClickEye2}
                ></button>
              </div>
            </div>
            <div className="uk-flex uk-flex-center uk-margin-small-top">
              {/* user.is_changed_password だと0が表示されてしまう */}
              {user?.is_changed_password === 1 && (
                <button
                  onClick={onClickCancel}
                  className="uk-button--l uk-button-cancel"
                >
                  キャンセル
                </button>
              )}
              <button
                onClick={onClickSubmit}
                className="uk-button--l uk-button-refer uk-margin-small-left"
              >
                パスワード変更
              </button>
            </div>
          </div>
        </div>

        <ConfirmModal
          text="登録をキャンセルしますか？入力したデータは登録されません。"
          confirmButtonText="前の画面へ"
          isShow={isShowBackModal}
          onConfirm={() => {
            navigate(-1);
          }}
          onCancel={() => {
            setIsShowBackModal(false);
          }}
        />
      </div>
    </div>
  );
}
