import React from 'react';
import { Document, Page } from 'react-pdf';
import {useFormContext, useWatch} from 'react-hook-form'

interface DataFile {
  fileAttributes: any[],
  index: number,
  inputFile: InputFile,
}
export interface InputFile {
  file: File,
  tempPath: string,
  preview: string,
}
export interface DataFileInputs {
  filename: string,
  file_attribute_code: string,
  remarks: string,
}
export const FileInput = ({fileAttributes, index, inputFile}: DataFile) => {
  const { register, control } = useFormContext();
  const { formState: { errors }, } = useFormContext<{dataFiles: Array<DataFileInputs>}>();
  const name = `dataFiles.${index}`;
  const error = errors.dataFiles?.[index];

  const selectedFileAttribute = useWatch({
    control,
    name: `${name}.file_attribute_code`,
  });

  return (
    <>
      <tr>
        <th rowSpan={5} className="media">
          <div className="media_flex">
              {inputFile.file.type === 'application/pdf' ?
                <Document file={inputFile.preview} renderMode="canvas">
                  <Page pageNumber={1} width={120}  height={90} />
                </Document>
                :
                <div className="media_img">
                  {inputFile.file.type.startsWith('image/') ?
                    <img src={inputFile.preview}/>
                    : <span>No Image</span>}
                </div>
              }
          </div>
        </th>
        <th className="label">ファイル名</th>
        <td className="content">自動取得</td>
        {/*<td className="content">*/}
        {/*  <input*/}
        {/*    key={'filename'}*/}
        {/*    className={`uk-width-1-1 ${error?.filename ? "error-form" : ""}`}*/}
        {/*    {...register(`${name}.filename`)}*/}
        {/*  />*/}
        {/*  {error?.filename && <div className="error">{`${error?.filename?.message}`}</div>}*/}
        {/*</td>*/}
        <th className="label">元ファイル名</th>
        <td className="content">{inputFile.file.name}</td>
      </tr>
      <tr>
        <th className="label">属性<span className="error">※</span></th>
        <td colSpan={3} className="content">
          <select className={`uk-width-1-3 ${selectedFileAttribute ? '' : 'placeholder'} ${error?.file_attribute_code ? "error-form" : ""}`}
                  defaultValue={""} {...register(`${name}.file_attribute_code`)}>
            <option value="" disabled>属性を選択してください</option>
            {fileAttributes.map((o: any) => {
              return <option value={o.code}>{o.code + ': ' + o.name}</option>
            })}
          </select>
          {error?.file_attribute_code && <div className="error">{`${error?.file_attribute_code?.message}`}</div>}
        </td>
      </tr>
      <tr>
        <th className="label">備考</th>
        <td colSpan={3} className="content">
                  <textarea
                    className={`uk-width-1-1 ${error?.remarks ? "error-form" : ""}`}
                    {...register(`${name}.remarks`)}
                  />
          {error?.remarks && <div className="error">{`${error?.remarks?.message}`}</div>}
        </td>
      </tr>
      <tr>
        <th className="label">作成日時</th>
        <td className="content">自動取得</td>
        <th className="label">作成者</th>
        <td className="content">自動取得</td>
      </tr>
      <tr>
        <th className="label">更新日時</th>
        <td className="content">自動取得</td>
        <th className="label">更新者</th>
        <td className="content">自動取得</td>
      </tr>
    </>
  );
}

// export interface ThumbnailViewerProps {
//   filename: File,
// }
// const ThumbnailViewer = (file: File) => {
//   return (
//     <div className="uk-flex uk-flex-center">
//       <div className="media_img">
//         {file && file.type === 'application/pdf' ?
//           <Document file={ URL.createObjectURL(file)} renderMode="canvas">
//             <Page pageNumber={1} width={120}  height={90} />
//           </Document>
//           : file && file.type.startsWith('image/') ?
//             <img src={URL.createObjectURL(file)} />
//             : <span>No Image</span>}
//       </div>
//     </div>
//   )
// }


export const FileInputDummy = () => {
  return (
    <div className="file-tbl">
      <table>
        <tr>
          <th rowSpan={5} className="media">
            <div className="uk-flex uk-flex-center">
              <div className="media_img">
              </div>
            </div>
          </th>
          <th className="label">ファイル名</th>
          <td className="content">自動取得</td>
          <th className="label">元ファイル名</th>
          <td className="content">自動取得</td>
        </tr>
        <tr>
          <th className="label">属性<span className="error">※</span></th>
          <td colSpan={3} className="content">
            ファイル選択後、属性を選択を入力
          </td>
        </tr>
        <tr>
          <th className="label">備考</th>
          <td colSpan={3} className="content">
            ファイル選択後、属性を選択を入力
          </td>
        </tr>
        <tr>
          <th className="label">作成日時</th>
          <td className="content">自動取得</td>
          <th className="label">作成者</th>
          <td className="content">自動取得</td>
        </tr>
        <tr>
          <th className="label">更新日時</th>
          <td className="content">自動取得</td>
          <th className="label">更新者</th>
          <td className="content">自動取得</td>
        </tr>
      </table>
    </div>
  );
}