import {basicMapStore} from "@/stores/basicMap";

export function useSearchBasicMap() {

  //検索結果ステータス
  type Status = {
    certification_status?: number;
    submission_status?: number;
    data_id: number;
  }
  type BasicMapStatus = {
    certification_status: Status;
    submission_status: Status;
  }
  const getSearchResultBasicMapStatus = (basicMapStatus: BasicMapStatus[], dataId: number) => {
    const {certificationStatus, submissionStatus} = basicMapStore();
    const filteredResult = basicMapStatus.find((basicMap) => {
      return basicMap.certification_status.data_id === dataId;
    });
    const certification = certificationStatus.find((item) => {
      return item.code === filteredResult?.certification_status.certification_status
    })?.name
    const submission = submissionStatus.find((item) => {
      return item.code === filteredResult?.submission_status.submission_status
    })?.name

    return {certification, submission}
  }

  return {getSearchResultBasicMapStatus}
}