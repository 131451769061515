import {useEffect} from "react";
import {axios} from "@/lib/axios";
import {useUserStore} from "@/stores/user";
import {useAxiosRouteStore} from '@/stores/axiosRoute';
import {useLoadingStore} from '@/stores/loading';
import Login from './containers/auth/Login';
import Header from './components/Header';
import PasswordChange from './containers/auth/PasswordChange';
import {
  createBrowserRouter, createRoutesFromElements, Outlet,
  Route, RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import * as Config from '@/config'
import ProgressList from './containers/progress/ProgressList';
import CustomersList from './containers/customer/CustomersList';
import Admin from './containers/admin/Admin';
import GroupList from './containers/admin/Groups/GroupList';
import UserList from './containers/admin/Users/UserList';
import GroupEdit from './containers/admin/Groups/GroupEdit';
import UserEdit from './containers/admin/Users/UserEdit';
import DataManagement from './containers/admin/DataManagement/DataManagement';
import Data from '@/containers/data/Data'
import TicketSearch from "@/containers/data/Ticket/TicketSearch";
import {useProjectStore} from '@/stores/project'
import ConfirmModal from '@/components/ConfirmModal'
import {useBlockerStore} from '@/stores/blocker'
import {useMessageModalStore} from '@/stores/message'

import BasicInfoNew from './containers/customer/basicinfo/BasicInfoNew';
import BasicInfoDetail from './containers/customer/basicinfo/BasicInfoDetail';
import BasicInfoEdit from './containers/customer/basicinfo/BasicInfoEdit';
import DrmPfDetail from './containers/customer/drmPf/basic/DrmPfDetail';
import DrmPfEdit from './containers/customer/drmPf/basic/DrmPfEdit';
import {getLoginUserInfo} from "@/api/users";
import InformationList from "./containers/admin/Information/InformationList";
import InformationEdit from "./containers/admin/Information/InformationEdit";
import Report from '@/containers/report/Report';
import DicDataManagement from '@/containers/admin/Dic/DicDataManagement'
import BasicMapManagement from "@/containers/admin/BasicMap/BasicMapManagement";
import DrmPf from "@/containers/customer/drmPf/DrmPf";
import APIUsagesDetail from "@/containers/customer/drmPf/usages/APIUsagesDetail";
import DocumentManagement from "@/containers/admin/Documents/DocumentManagement";
import APIUsageHistory from "@/containers/customer/UsageHistory";

function App() {
  const user = useUserStore(state => state.user);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);

  useEffect(() => {
    document.title = Config.TITLE;
  }, []);

  // TODO: react-router v6対応
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout/>}>
        <Route path="/" element={<Login/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/password_change" element={(user && selectedProjectCategory) && <PasswordChange/>}/>
        <Route path="/progress" element={(user && selectedProjectCategory) && <ProgressList/>}/>
        <Route path="/customer" element={(user && selectedProjectCategory) && <CustomersList/>}/>
        <Route path="/customer/basicinfo/new" element={(user && selectedProjectCategory) && <BasicInfoNew/>}/>
        <Route path="/customer/basicinfo/detail/:customerId"
               element={(user && selectedProjectCategory) && <BasicInfoDetail/>}/>
        <Route path="/customer/basicinfo/edit/:customerId"
               element={(user && selectedProjectCategory) && <BasicInfoEdit/>}/>

        <Route path="/customer/drmpf" element={(user && selectedProjectCategory) && <DrmPf/>}>
          <Route path="detail/:customerId" element={<DrmPfDetail/>}/>
          <Route path="edit/:customerId" element={<DrmPfEdit/>}/>
          <Route path="api/:customerId" element={<APIUsagesDetail/>}/>
        </Route>

        <Route path="/customer/apiUsage" element={(user && selectedProjectCategory) && <APIUsageHistory/>}/>

        <Route path="/report" element={(user && selectedProjectCategory) && <Report/>}/>
        {/* 長くなってきたらDataにRoute移動 */}
        <Route path="/progress/:projectCategoryId/new/attributes"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.AttributeNew}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/attributes"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.AttributeDetail}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/attributes/:tabId"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.AttributeDetail}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/attributes/edit"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.AttributeEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/attributes/:tabId/edit"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.AttributeEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/attributes/copy"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.AttributeCopy}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/files/new"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.FileNew}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/files/edit/:fileId"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.FileEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/files"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.FileList}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/apply_areas"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.ApplyAreaList}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/apply_areas/edit"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.ApplyAreaListEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/ticket"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.Ticket}/>}/>
        <Route path="/progress/:projectCategoryId/ticket/search"
               element={(user && selectedProjectCategory) && <TicketSearch/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/public"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.PublicDetail}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/public/:faqId"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.PublicDetail}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/public/new"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.PublicEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/public/:faqId/edit"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.PublicEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/approve"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.ApproveDetail}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/approve/edit"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.ApproveEdit}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/forms"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.Form}/>}/>
        <Route path="/progress/:projectCategoryId/:dataId/forms/:applyPdf"
               element={(user && selectedProjectCategory) && <Data isType={Config.Data.Form}/>}/>
        <Route path="/admin" element={(user && selectedProjectCategory) && <Admin/>}/>
        <Route path="/admin/groups" element={(user && selectedProjectCategory) && <GroupList/>}/>
        <Route path="/admin/groups/:id/edit" element={(user && selectedProjectCategory) && <GroupEdit/>}/>
        <Route path="/admin/groups/new" element={(user && selectedProjectCategory) && <GroupEdit/>}/>
        <Route path="/admin/users" element={(user && selectedProjectCategory) && <UserList/>}/>
        <Route path="/admin/users/:id/edit" element={(user && selectedProjectCategory) && <UserEdit/>}/>
        <Route path="/admin/users/:id/duplicate" element={(user && selectedProjectCategory) && <UserEdit/>}/>
        <Route path="/admin/users/new" element={(user && selectedProjectCategory) && <UserEdit/>}/>
        <Route path="/admin/data" element={(user && selectedProjectCategory) && <DataManagement/>}/>
        <Route path="/admin/information" element={(user && selectedProjectCategory) && <InformationList/>}/>
        <Route path="/admin/information/new" element={(user && selectedProjectCategory) && <InformationEdit/>}/>
        <Route path="/admin/information/:id/edit" element={(user && selectedProjectCategory) && <InformationEdit/>}/>
        <Route path="/admin/information/:id/duplicate"
               element={(user && selectedProjectCategory) && <InformationEdit/>}/>
        <Route path="/admin/dic" element={(user && selectedProjectCategory) && <DicDataManagement/>}/>
        <Route path="/admin/basic-map" element={(user && selectedProjectCategory) && <BasicMapManagement/>}/>
        <Route path="/admin/document/new" element={(user && selectedProjectCategory) && <DocumentManagement isType={Config.DocumentManagement.FileNew}/>}/>
        <Route path="/admin/document/edit/:fileId" element={(user && selectedProjectCategory) && <DocumentManagement isType={Config.DocumentManagement.FileEdit}/>}/>
        <Route path="/admin/document" element={(user && selectedProjectCategory) && <DocumentManagement isType={Config.DocumentManagement.FileList}/>}/>
      </Route>
    )
  );

  return <RouterProvider router={router}/>;
}

const Layout = () => {
  return (
    <>
      <Loading/>
      <Auth/>
      <MessageModal/>
      <Header/>
      <AxiosNavigationHandler/>
      <div id="modal-root"/>
      <Outlet/>
    </>
  )
}

function Auth() {
  const location = useLocation();
  const navigate = useNavigate();
  const setUser = useUserStore(state => state.setUser);
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);

  function getUserData() {
    setLoading(true);
    getLoginUserInfo().then(result => {
      setUser(result.data)
      if (location.pathname === '/login' || location.pathname === '/') {
        navigate('/progress');
      }
    }).catch(error => {
      if (!error.response) {
        setMessage(Config.MESSAGE_NO_E39);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getUserData();
  }, []);

  // 検討: 子コンポーネントに配置して表示切り替え
  return null;
}

function Loading() {
  const isLoading = useLoadingStore(state => state.isLoading);
  return isLoading ?
    <div
      className="uk-position-fixed uk-position-cover uk-flex uk-flex-center uk-flex-middle"
      style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 1000}}
    >
      <div className="uk-position-center">
        <span uk-spinner="ratio: 5"></span>
      </div>
    </div> : null
}

// axios.tsからuseNavigateが使えないため
function AxiosNavigationHandler() {
  const [axiosRoute, setAxiosRoute] = useAxiosRouteStore(state => [state.axiosRoute, state.setAxiosRoute]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (axiosRoute !== '' && location.pathname !== '/login' && location.pathname !== '/') {
      navigate('/login');
      setAxiosRoute('')
    }
    return () => {
    };
  }, [axiosRoute]);

  return null;
}

function MessageModal() {
  const [message, setMessage] = useMessageModalStore(state => [state.message, state.setMessage]);
  return (
    <ConfirmModal
      text={message}
      confirmButtonText="OK"
      isShow={message !== ""}
      onConfirm={() => {
        setMessage("");
      }}
    />
  )
}

export default App;
