import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {DataProps} from '@/containers/data/Data'
import * as Config from '@/config'
import FileNew from '@/containers/data/Files/FileNew'
import FileEdit from '@/containers/data/Files/FileEdit'
import FileList from '@/containers/data/Files/FileList'
import {pdfjs} from 'react-pdf'
import "pdfjs-dist/build/pdf.worker.entry";
import * as Common from '@/utils/common'
import {useProjectStore} from '@/stores/project'
import {useUserStore} from '@/stores/user'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export default function Files(props: DataProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const user = useUserStore(state => state.user);
  const canFile = Common.canFile(selectedProjectCategory?.id, user);

  useEffect(() => {
    if (canFile < Config.RESTRICTION_HISTORY_FILE_VIEW.value
      || ((props.isType === Config.Data.FileNew || props.isType === Config.Data.FileEdit) && canFile < Config.RESTRICTION_HISTORY_FILE_EDIT.value)) {
      console.log("Files")
      navigate('/progress');
    }
  }, [location.pathname]);

  switch (props.isType) {
    case Config.Data.FileNew:
      return <FileNew />
    case Config.Data.FileEdit:
      return <FileEdit />
    case Config.Data.FileList:
      return <FileList />
    default:
      return null;
  }
}