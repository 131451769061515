import create from 'zustand';

type BlockerStore = {
    isBlocker: boolean;
    setBlocker: (isBlocker: boolean) => void;
};

export const useBlockerStore = create<BlockerStore>((set) => ({
    isBlocker: false,
    setBlocker: (isBlocker) =>
        set((state) => ({
            isBlocker: isBlocker,
        })),
}));
