import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axios } from "@/lib/axios";
import { useLoadingStore } from "@/stores/loading";
import { useUserStore } from "@/stores/user";
import { useMessageModalStore } from "@/stores/message";
import * as Config from "@/config";
import { MESSAGE_NO_E01 } from "@/config";
import { LoginInformation, getLoginInformation } from "@/api/information";

export default function Login() {
  const navigate = useNavigate();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const setUser = useUserStore((state) => state.setUser);
  const setMessage = useMessageModalStore((state) => state.setMessage);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);

  const onClickEye = () => {
    setHide(!hide);
  };

  const onClickLogin = () => {
    setLoading(true);
    axios
      .post("/api/v1/users/login", {
        email_address: email,
        password: password,
      })
      .then((result) => {
        setUser(result.data);
        if (result.data.is_changed_password) {
          navigate("/progress");
        } else {
          navigate("/password_change");
        }
      })
      .catch((error) => {
        setMessage(Config.MESSAGE_NO_E01);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [information, setInformation] = useState<LoginInformation>();
  useEffect(() => {
    setLoading(true);
    getLoginInformation()
      .then((res) => {
        setInformation(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const infoLoading = information === undefined;
  const infoExists =
    information?.information_list && information?.information_list.length > 0;

  return (
    <div id="login">
      <div className="login-container">
        <div className="login-item login-item-information">
          <div className="login-item-header">
            <p>お知らせ</p>
          </div>
          <div className="login-item-body login-item-body-information">
            {infoExists
              ? information?.information_list?.map((information) => (
                  <section key={information.id}>
                    <p className="section-header">
                      <strong>{information.title}</strong>
                    </p>
                    <p className="section-body">{information.details}</p>
                  </section>
                ))
              : !infoLoading && <>お知らせはありません</>}
          </div>
        </div>
        <div className="login-item">
          <div className="login-item-header">
            <p>ログイン</p>
          </div>
          <div className="login-item-body login-item-body-login">
            <div className="mailadress">
              <p>メールアドレス</p>
              <input
                className="uk-input uk-form-small mailadress-form"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="password">
              <p>パスワード</p>
              <div className="uk-inline">
                <input
                  id="textPassword"
                  className="uk-input uk-form-small password-form"
                  type={hide ? "password" : "text"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  id="buttonEye"
                  className={
                    hide
                      ? "uk-form-icon uk-form-icon-flip fa fa-eye"
                      : "uk-form-icon uk-form-icon-flip fa fa-eye-slash"
                  }
                  onClick={onClickEye}
                ></button>
              </div>
            </div>
            <div className="uk-flex uk-flex-center uk-margin-small-top">
              <button
                onClick={onClickLogin}
                className="uk-button--l uk-button-refer"
              >
                ログイン
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
