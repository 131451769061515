import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import SubHeader from '../../components/SubHeader';
import ReactPaginate from 'react-paginate';
import {ProjectCategory, useProjectStore} from '@/stores/project'
import {axios} from '@/lib/axios';
import {
  SubmitHandler,
  useForm,
  FormProvider,
  useFormContext,
  UseFormRegister,
  useWatch,
  UseFormGetValues, FieldValues
} from 'react-hook-form';
import {useLoadingStore} from '@/stores/loading';
import * as Config from '@/config'
import * as Common from '@/utils/common'
import * as Attributes from '@/components/Form/Attributes'
import qs from 'qs';
import {User, useUserStore} from '@/stores/user'
import ConfirmModal from '@/components/ConfirmModal'
import {useInterval} from '@/utils/UseInterval'
import {useProgressStore} from '@/stores/progress'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {useMessageModalStore} from '@/stores/message'
import {CATEGORY_CODE_FAQ, CATEGORY_CODE_SALES_APPLY} from "@/config";
import moment from 'moment';
import {useFixedColumns} from "@/utils/useFixedColumns";
import {basicMapStore} from "@/stores/basicMap";
import {useSearchBasicMap} from "@/components/Attributes/BasicMap/useSearchBasicMap";
import {useBasicMap} from "@/utils/useBasicMap";

interface SearchResults {
  attributes: Array<any>,
  datas: Array<any>,
  tabs: Array<any>,
  dataTabStatusCounts: Array<any>,
  paging: any,
  faq_statuses: Array<any> | null,
  data_faq_status_counts: Array<any> | null,
  decisionUsers: Array<any>,
  dataApplies: Array<any>,
  basic_map_statuses: Array<any>,
}

const defaultDecision = {
  decision_user: '',
  un_applied: true,
  un_approved: true,
  approved: true,
  has_comment: true,
  no_comment: true
};

export default function ProgressList() {
  const navigate = useNavigate();
  const methods = useForm();
  const {
    control,
    reset,
    getValues,
    setValue,
    register,
    formState: {errors, isDirty},
  } = methods;
  const {pathname, search} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = qs.parse(search.substring(1), {arrayLimit: 0});

  const user = useUserStore(state => state.user);
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const [prevSelectedProjectCategory, setPrevSelectedProjectCategory] = useState(selectedProjectCategory);
  // 名前がかぶる
  const [progressQueryParams, setProgressQueryParams] = useProgressStore(state => [
    state.queryParams,
    state.setQueryParams,
  ]);
  const {fetchUpdatedCityStatus, fetchSubmissionStatus, fetchCertificationStatus} = basicMapStore();
  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;

  const pageSizeOptions = [
    {
      value: '25',
      label: '25件',
    },
    {
      value: '50',
      label: '50件',
    },
    {
      value: '100',
      label: '100件',
    },
    {
      value: '200',
      label: '200件',
    },
  ]

  const breadCrumbs = [
    {title: '進捗一覧'}
  ];
  // 検索項目
  const [areaName, setAreaName] = useState<string>("エリア");
  const [blockName, setBlockName] = useState<string>("ブロック");
  const [areas, setAreas] = useState<any[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[] | undefined>([]);
  const [decisionUsers, setDecisionUsers] = useState<any[]>([])
  const [resetForm, setResetForm] = useState<boolean>(false);
  // 検索結果
  const [searchResults, setSearchResults] = useState<SearchResults | undefined>(undefined);
  const [searchQueryParam, setSearchQueryParam] = useState<any>(undefined);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  // 検索結果の入力項目
  const [checkIds, setCheckIds] = useState(new Set<number>());
  const [selectedTabStatusesId, setSelectedTabStatusesId] = useState<number | undefined>(undefined);
  const [selectedTabId, setSelectedTabId] = useState<number | undefined>(undefined); // (query.tab_id ? parseInt(query.tab_id as string) : undefined);
  const [selectedPageSize, setSelectedPageSize] = useState<string | undefined>('100')

  // TODO: 共通化？
  const canCreate = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
        return true;
      case Config.PERMISSION_ROLL_ADMIN:
      case Config.PERMISSION_ROLL_USER:
        return user.user_restrictions.find((user_restriction) => {
          return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PROGRESS_NEW
            && user_restriction.category_restriction_value === 1
        }) !== undefined;
      default:
        return false;
    }
  }
  const canDelete = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
        return true;
      case Config.PERMISSION_ROLL_ADMIN:
      case Config.PERMISSION_ROLL_USER:
        return user.user_restrictions.find((user_restriction) => {
          return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PROGRESS_DEL
            && user_restriction.category_restriction_value === 1
        }) !== undefined;
      default:
        return false;
    }
  }

  function getSearchInputs() {
    setResetForm(false);
    setSearchQueryParam(undefined);
    setSearchResults(undefined);
    setLoading(true);
    axios.get('/api/v1/attributes/searchInputs', {
      params: {
        project_id: selectedProject?.id,
        project_category_id: selectedProjectCategory?.id,
      }
    }).then(result => {
      setAreaName(result.data.project.area_display_name);
      setBlockName(result.data.project.block_display_name);
      setAreas(result.data.areas);
      const filteredBlocks = Common.filteredBlocks(result.data.blocks, selectedProjectCategory);
      setBlocks(filteredBlocks);
      setAttributes(result.data.attributes);
      setDecisionUsers(result.data.decision_users);

      if (Object.keys(query).length > 0 && query.project_category_id == selectedProjectCategory?.id) {
        // console.log("query")
        // console.log(query)
        // if (query.attributes) query.attributes = Common.decodeAttributeParams(result.data.attributes, query);
        setSearchQueryParam(query);
      } else if (progressQueryParams && progressQueryParams.project_category_id == selectedProjectCategory?.id) {
        // console.log("progressQueryParams")
        // console.log(progressQueryParams)
        setSearchQueryParam(progressQueryParams);
      } else {
        setResetForm(true);
      }
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function doSearch(params: any, isSetForm = false) {
    // console.log(params.attributes)
    setLoading(true);
    axios.get('/api/v1/datas/search', {
      params: params
    }).then(result => {
      // console.log(result.data)
      // console.log(result)
      // config.urlにクエリが含まれていないため変換
      setSearchParams(qs.stringify(params));
      setProgressQueryParams(params);
      if (isSetForm) {
        const attributeInputs = params.attributes ? Common.decodeAttributeParams(attributes ?? [], params) : Common.attributeDefaultValues(attributes ?? []);
        reset({
          area_id: params.area_id,
          block_id: params.block_id,
          attributes: attributeInputs,
          approval_status: params.approval_status
        });
      }
      setSelectedTabStatusesId(params.tab_statuses_id);
      setSelectedTabId(params.tab_id);
      setSelectedPageSize(params.page_size);
      setCheckIds(new Set());
      setSearchResults(result.data as SearchResults);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function deleteIds() {
    setModalConfirm({
      callback: () => {
        setLoading(true);
        axios.post('/api/v1/datas/deleteByIds', {
          ids: Array.from(checkIds),
        }).then(result => {
          doSearch(searchQueryParam ?? defaultSearchParam());
        }).catch(error => {
          setMessage(Config.MESSAGE_NO_E39);
          setLoading(false);
        });
      }
    });
  }

  function clearForm() {
    reset({
      area_id: '',
      block_id: '',
      attributes: Common.attributeDefaultValues(attributes ?? []),
      approval_status: defaultDecision
    });
    navigate(pathname);
  }

  useEffect(() => {
    if (selectedProjectCategory) {
      getSearchInputs();
    }
    return () => {
    };
  }, [selectedProjectCategory]);

  // attributesの設定後
  useEffect(() => {
    if (attributes && searchQueryParam) {
      doSearch(searchQueryParam, true);
    }
    return () => {
    };
  }, [attributes, searchQueryParam]);

  useEffect(() => {
    if (attributes && resetForm) {
      clearForm();
      doSearch(defaultSearchParam());
    }
    return () => {
    };
  }, [attributes, resetForm]);

  useEffect(() => {
    if (isBasicMap) {
      fetchUpdatedCityStatus();
      fetchSubmissionStatus();
      fetchCertificationStatus();
    }
    return () => {
    };
  }, [])

  function defaultSearchParam() {
    const data = getValues();
    return {
      project_category_id: selectedProjectCategory?.id,
      area_id: data.area_id,
      block_id: data.block_id,
      attributes: Common.inputAttributeToPostParam(attributes ?? [], data)[0],
      tab_statuses_id: null,
      tab_id: null,
      page: 1,
      page_size: selectedPageSize,
      approval_status: data.approval_status
    }
  }


  const onClickNew = () => {
    navigate(Common.attributesNewUrl(selectedProjectCategory?.id));
  }

  const onClickCheck = (id: number) => {
    if (checkIds.has(id)) {
      checkIds.delete(id);
      setCheckIds(new Set(checkIds));
    } else {
      checkIds.add(id);
      setCheckIds(new Set(checkIds));
    }
  }

  const onClickCheckAll = () => {
    if (checkIds.size === searchResults?.datas.length) {
      setCheckIds(new Set<number>());
    } else {
      setCheckIds(new Set<number>(searchResults?.datas.map((data: any) => data.id)));
    }
  }

  const onClickDeleteIds = () => {
    deleteIds();
  }

  const onClickCopy = () => {
    const copy = searchResults?.datas.find((data: any) => data.id === Array.from(checkIds)[0]);
    navigate(Common.attributesCopyUrl(selectedProjectCategory?.id, copy.id));
  }

  const onSelectedTabStatusesId = (tabStatusesId: number) => {
    doSearch({
      ...progressQueryParams,
      tab_statuses_id: tabStatusesId,
      tab_id: null,
      page: 1,
    });
  }

  const onSelectedTabId = (tabId: number) => {
    doSearch({
      ...progressQueryParams,
      tab_statuses_id: null,
      tab_id: tabId,
      page: 1,
    });
  }

  const onSelectedFaqStatuses = (statusId: number) => {
    doSearch({
      ...progressQueryParams,
      faq_statuses_id: statusId,
      page: 1,
    });
  }

  const onSelectedFaq = () => {
    doSearch({
      ...progressQueryParams,
      faq_statuses_id: null,
      page: 1,
    });
  }

  const onSelectedPageSize = (pageSize: string) => {
    setSelectedPageSize(pageSize);
    if (progressQueryParams) {
      doSearch({
        ...progressQueryParams,
        page_size: pageSize,
        page: 1,
      });
    }
  }

  const onPageChange = (page: number) => {
    doSearch({
      ...progressQueryParams,
      page: page
    });
  }

  const onClear = () => {
    clearForm();
  }

  const onSearch = () => {
    const data = getValues();
    doSearch({
      project_category_id: selectedProjectCategory?.id,
      area_id: data.area_id,
      block_id: data.block_id,
      attributes: Common.inputAttributeToPostParam(attributes ?? [], data)[0],
      tab_statuses_id: null,
      tab_id: null,
      page: 1,
      page_size: selectedPageSize,
      approval_status: data.approval_status
    });
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs}/>
      </div>
      <div className="container container-progress">
        <FormProvider {...methods}>
          {attributes && <SearchContainer
              attributes={attributes}
              areas={areas}
              blocks={blocks}
              areaName={areaName}
              blockName={blockName}
              decisionUsers={decisionUsers}
              onSearch={onSearch}
              onClear={onClear}
          />}
        </FormProvider>
        <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
          <div className="uk-inline">
            <select className="table-length" defaultValue={"100"}
                    onChange={(e) => onSelectedPageSize(e.target.value)}>
              <option value="" disabled>表示件数</option>
              {pageSizeOptions.map((o: any) => {
                return <option value={o.value}>{o.label}</option>
              })}
            </select>
          </div>
          <div>
            {canDelete(user) && <button className="uk-button--m uk-button-cancel" onClick={onClickDeleteIds}
                                        disabled={checkIds.size === 0}>一括削除</button>}
            {canCreate(user) &&
                <>
                    <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickCopy}
                            disabled={checkIds.size !== 1}>複製
                    </button>
                    <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成
                    </button>
                </>
            }
          </div>
        </div>

        {searchResults &&
            <SearchResultsContainer
                searchResults={searchResults}
                areaName={areaName}
                blockName={blockName}
                checkIds={checkIds}
                onClickCheck={onClickCheck}
                onClickCheckAll={onClickCheckAll}
                onPageChange={onPageChange}
                selectedTabStatusesId={selectedTabStatusesId}
                onSelectedTabStatusesId={onSelectedTabStatusesId}
                selectedTabId={selectedTabId}
                onSelectedTabId={onSelectedTabId}
                selectedProjectCategory={selectedProjectCategory}
                onSelectedFaq={onSelectedFaq}
                onSelectedFaqStatus={onSelectedFaqStatuses}
            />}
      </div>
      {/*<button onClick={()=> {console.log(getValues())}}>debug</button>*/}

      <ConfirmModal
        text={Config.MESSAGE_NO_E05}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if (modalConfirm) modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  );
}


/**
 * 検索
 */
interface SearchProps {
  attributes: Array<any>,
  areas: Array<any>,
  blocks: Array<any>,
  areaName: string,
  blockName: string
  onSearch: () => void;
  onClear: () => void;
  decisionUsers?: any
}

const SearchContainer = (props: SearchProps) => {
  const setLoading = useLoadingStore(state => state.setLoading);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);

  const isSalesApply = selectedProjectCategory?.category_code === CATEGORY_CODE_SALES_APPLY;
  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {errors, isDirty},
  } = useFormContext()
  const selectedArea = useWatch({
    control,
    name: 'area_id',
    defaultValue: '',
  });
  const selectedBlock = useWatch({
    control,
    name: 'block_id',
    defaultValue: '',
  });
  const selectedDecisionUser = useWatch({
    control,
    name: 'approval_status.decision_user',
    defaultValue: '',
  });
  const watchApprovalStatus = useWatch({
    control,
    name: 'approval_status',
    defaultValue: '',
  });

  const filteredBlocks = useMemo(() => {
    if (selectedArea) return props.blocks.filter((block: any) => block.area_id === parseInt(selectedArea));
    else return [];
  }, [selectedArea]);

  useEffect(() => {
    if (isDirty) {
      setValue('block_id', '');
    }
  }, [selectedArea]);

  const onSubmit: SubmitHandler<any> = (data) => {
    props.onSearch();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table className="condition-tbl">
        <tbody>
        <tr className="header">
          <td colSpan={isSalesApply ? 2 : 4}>{props.areaName}</td>
          {isSalesApply &&
              <>
                  <td colSpan={1} className="uk-width-1-3">承認状況</td>
                  <td colSpan={1}>コメント</td>
              </>
          }
        </tr>
        {selectedProjectCategory?.category_code !== CATEGORY_CODE_FAQ &&
            <tr>
                <td colSpan={isSalesApply ? 2 : 4}>
                    <div className="uk-flex">
                        <div className="uk-inline">
                            <select
                                className={`${selectedArea ? '' : 'placeholder'} ${errors.area_id ? "error-form" : ""}`}
                                defaultValue={""} {...register("area_id")}>
                                <option value="">エリア選択</option>
                              {props.areas.map((o: any) => {
                                return <option value={o.id}>{o.code + ': ' + o.name}</option>
                              })}
                            </select>
                          {errors.area_id && <div className="error">{`${errors.area_id.message}`}</div>}
                        </div>
                      {!isBasicMap &&
                          <div className="uk-inline uk-margin-left">
                              <select
                                  className={`${selectedBlock ? '' : 'placeholder'} ${errors.block_id ? "error-form" : ""}`}
                                  defaultValue={""} {...register("block_id")}>
                                  <option value="">ブロック選択</option>
                                {filteredBlocks.map((o: any) => {
                                  return <option value={o.id}>{o.code + ': ' + o.name}</option>
                                })}
                              </select>
                            {errors.block_id && <div className="error">{`${errors.block_id.message}`}</div>}
                          </div>
                      }
                    </div>
                </td>
              {isSalesApply &&
                  <>
                      <td colSpan={1}>
                          <div className="uk-flex">
                              <label className="uk-margin-small-right">
                                  <input
                                      type="checkbox"
                                      {...register("approval_status.un_applied")}
                                  />
                                  未申請
                              </label>
                              <label className="uk-margin-small-right">
                                  <input
                                      type="checkbox"
                                      {...register("approval_status.un_approved")}
                                  />
                                  未承認
                              </label>
                              <label className="uk-margin-small-right">
                                  <input
                                      type="checkbox"
                                      {...register("approval_status.approved")}
                                  />
                                  承認済
                              </label>
                              <label className="uk-margin-small-right">
                                  決裁者
                              </label>
                              <select defaultValue={""}
                                      className={selectedDecisionUser ? '' : 'placeholder'} {...register("approval_status.decision_user")}>
                                  <option value="">未選択</option>
                                {props.decisionUsers?.map((d: any) => {
                                  return <option
                                    value={d.id}>{d.position_name + "：" + d.last_name + d.first_name}</option>
                                })}
                              </select>
                          </div>
                      </td>
                      <td colSpan={1}>
                          <div className="uk-flex">
                              <label className="uk-margin-small-right">
                                  <input
                                      type="checkbox"
                                      {...register("approval_status.has_comment")}
                                  />
                                  あり
                              </label>
                              <label className="uk-margin-small-right">
                                  <input
                                      type="checkbox"
                                      {...register("approval_status.no_comment")}
                                  />
                                  なし
                              </label>
                          </div>
                      </td>
                  </>
              }
            </tr>
        }
        <SearchRows attributes={props.attributes}/>
        <tr>
          <td colSpan={4}>
            <div className="uk-flex uk-flex-center">
              <button type="submit" className="uk-button--m uk-button-refer">検索</button>
              <button type="button" onClick={props.onClear}
                      className="uk-button--m uk-button-cancel uk-margin-left">クリア
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  )
}

export const SearchRows = ({attributes}: { attributes: Array<any> }) => {
  const {basicMapSearchItem} = useBasicMap();
  const selectedProjectCategory = useProjectStore(state => state.projectCategory);
  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const {updatedCityStatus, submissionStatus, certificationStatus} = basicMapStore();
  const currentAttributes = isBasicMap ? basicMapSearchItem(attributes, {
    updatedCityStatus,
    submissionStatus,
    certificationStatus
  }) : attributes;
  return (
    <>
      {currentAttributes.map((_: any, index: number) => (
        <>
          {index % 4 === 0 && (
            <tr className="header">
              {currentAttributes.slice(index, index + 4).map((attr: any) => (
                <td className="uk-width-1-4" key={`searchBox_td_1_${attr.id}`}>{attr.name}</td>
              ))}
              {/* 埋める */}
              {index + 4 >= currentAttributes.length &&
                Array.from({length: 4 - currentAttributes.slice(index, index + 4).length}).map((_, idx) => (
                  <td className="uk-width-1-4" key={`empty_td_1_${index + idx}`}></td>
                ))}
            </tr>
          )}
          {index % 4 === 0 && (
            <tr>
              {currentAttributes.slice(index, index + 4).map((attr: any) => (
                <td key={`searchBox_td_2_${attr.id}`}>{chooseComponent(attr)}</td>
              ))}
              {/* 埋める */}
              {index + 4 >= currentAttributes.length &&
                Array.from({length: 4 - currentAttributes.slice(index, index + 4).length}).map((_, idx) => (
                  <td key={`empty_td_2_${index + idx}`}></td>
                ))}
            </tr>
          )}
        </>
      ))}
    </>
  )
}

const chooseComponent = (attribute: any) => {
  if (attribute.isStatus) {
    return <Attributes.BasicMapStatus name={attribute.isStatus} options={attribute.options}/>
  }
  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_FIELD:
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_AREA:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_INT:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_DECIMAL:
      return <Attributes.InputText attribute={attribute}/>;
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      const [parentName, filterName] = Attributes.hasRelation(attribute);
      if (parentName && filterName)
        return <Attributes.HasRelation attribute={attribute} parentName={parentName} filterName={filterName}/>;
      else
        return <Attributes.NoneRelation attribute={attribute}/>;
    case Config.ATTRIBUTE_KINDS_CODE_DATE:
      return <Attributes.InputText attribute={attribute} props={{type: 'date'}}/>;
  }
}

/**
 * 検索結果
 */
interface SearchResultsContainerProps {
  areaName: string,
  blockName: string,
  searchResults: SearchResults,
  checkIds: Set<number>,
  onClickCheck: (id: number) => void,
  onClickCheckAll: () => void,
  onPageChange: (page: number) => void,
  selectedTabStatusesId: number | undefined,
  onSelectedTabStatusesId: (tabStatusesId: number) => void,
  selectedTabId: number | undefined,
  onSelectedTabId: (tabId: number) => void,
  selectedProjectCategory: ProjectCategory | undefined,
  onSelectedFaq: () => void,
  onSelectedFaqStatus: (statusId: number) => void
}

const SearchResultsContainer = (props: SearchResultsContainerProps) => {
  const user = useUserStore(state => state.user);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const {getSearchResultBasicMapStatus} = useSearchBasicMap();

  const isBasicMap = props.selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const isSalesApply = props.selectedProjectCategory?.category_code === CATEGORY_CODE_SALES_APPLY;
  const isFaq = props.selectedProjectCategory?.category_code === CATEGORY_CODE_FAQ;

  const linkAttr = props.searchResults.attributes[0];
  const linkListWidth = linkAttr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
  const linkWidth = linkListWidth ? `${linkListWidth.value}px` : '200px';

  const listAttrs = props.searchResults.attributes.slice(1);
  const decisionUsers = props.searchResults.decisionUsers;

  const progressTableRef = useRef<HTMLTableElement>(null);
  useFixedColumns(progressTableRef);

  const getTabStatusCount = (tab_statuses_id: number) => {
    const count = props.searchResults.dataTabStatusCounts.find((count: any) => count.tab_statuses_id === tab_statuses_id);
    return count?.tab_statuses_count ?? 0;
  }

  const allTabCounts = props.searchResults.dataTabStatusCounts.reduce((accumulator, value) => {
    if (accumulator[value.tab_id]) {
      accumulator[value.tab_id] += value.tab_statuses_count;
    } else {
      accumulator[value.tab_id] = value.tab_statuses_count;
    }
    return accumulator;
  }, {});

  const allFaqCounts = props.searchResults.data_faq_status_counts?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.faq_statuses_count, 0
  )

  const currentFaqStatuses = (data_id: number) => {
    return props.searchResults.faq_statuses?.find((item) => {
      return item.id === data_id
    })
  }

  const [lockedList, setLockedList] = useState<any[]>([]);

  useEffect(() => {
    getLockedList();
  }, []);

  useInterval(() => {
    getLockedList();
  }, 60 * 1000);

  const getLockedList = () => {
    const dataIds = props.searchResults.datas.map((data: any) => data.id);
    axios.get('/api/v1/datas/lockedList', {
      params: {
        data_id: dataIds,
      }
    }).then(result => {
      setLockedList(result.data.data);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
    });
  }

  const getLink = (project_category_id: any, data_id: any) => {
    if (Common.canApprove(project_category_id, user) && isSalesApply) {
      return Common.approveDetailUrl(project_category_id, data_id);
    }
    const canAttribute = Common.canShowAttribute(project_category_id, user);
    if (canAttribute === true) {
      return Common.attributesDetailUrl(project_category_id, data_id);
    } else if (canAttribute) {
      return Common.attributesDetailUrl(project_category_id, data_id, canAttribute.tab_id);
    }
    if (Common.canFile(project_category_id, user) > 0)
      return Common.filesListUrl(project_category_id, data_id);
    return '';
  }

  return (
    <>
      <div className="progress-tbl-wrap mt-5">
        <Tooltip id="progress-tooltip" style={{zIndex: 3}}/>
        <table className={`progress-tbl ${isBasicMap && "fixed-columns"}`} ref={progressTableRef}>
          <thead>
          <tr className="header">
            <th rowSpan={2}>
              <div className="border-box"/>
              <div className="uk-flex uk-flex-center">
                <input
                  type="checkbox"
                  onChange={props.onClickCheckAll}
                  checked={props.searchResults.datas.length > 0 && props.checkIds.size === props.searchResults.datas.length}/>
              </div>
            </th>
            <th rowSpan={2} style={{minWidth: linkWidth, maxWidth: linkWidth}}>
              <div className="border-box"/>
              {linkAttr.name}</th>
            {isSalesApply &&
                <>
                    <th rowSpan={2}>
                        <div className="border-box"/>
                        申請状況
                    </th>
                    <th rowSpan={2}>
                        <div className="border-box"/>
                        承認状況
                    </th>
                  {decisionUsers.map((decisionUser: any) => {
                    return (
                      <th rowSpan={2} key={`th_decision_user_${decisionUser.id}`}>
                        <div className="border-box"/>
                        {decisionUser.position_name}
                      </th>
                    );
                  })}
                </>
            }
            {props.selectedProjectCategory?.category_code !== CATEGORY_CODE_FAQ &&
                <>
                    <th rowSpan={2} style={{minWidth: '74px'}}>
                        <div className="border-box"/>
                      {props.areaName}</th>
                  {!isBasicMap &&
                      <th rowSpan={2} style={{minWidth: '74px'}}>
                          <div className="border-box"/>
                        {props.blockName}</th>
                  }
                </>
            }
            {listAttrs.map((attr: any) => {
              const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
              const width = listWidth ? `${listWidth.value}px` : '200px';
              return (<th rowSpan={2} style={{minWidth: width, maxWidth: width}}>
                <div className="border-box"/>
                {attr.name}</th>)
            })}
            {isSalesApply && (
              <th rowSpan={2}>
                <div className="border-box"/>
                申込エリア
              </th>
            )}
            {/* 基盤情報ステータス */}
            {isBasicMap && (
              <>
                <th rowSpan={2} style={{minWidth: 100, maxWidth: 100}}>
                  <div className="border-box"/>
                  作成会社<br/>ステータス
                </th>
                <th rowSpan={2} style={{minWidth: 100, maxWidth: 100}}>
                  <div className="border-box"/>
                  検定機関<br/>ステータス
                </th>
              </>
            )}
            {!isBasicMap && <>
              {props.searchResults.tabs.map((tab: any) => {
                return (
                  <th className="uk-padding-remove-horizontal">
                    <div className="border-box"/>
                    <div className="uk-text-center">{tab.name}</div>
                  </th>
                )
              })}
            </>
            }
            {/*  公開タブステータスのテーブルヘッダー　*/}
            {isFaq &&
              (
                <th className="uk-padding-remove-horizontal">
                  <div className="border-box"/>
                  <div className="uk-text-center">公開設定</div>
                </th>
              )
            }
          </tr>
          <tr className="header">
            {/* 共通化タブステータス */}
            {!isBasicMap &&
                <>
                  {props.searchResults.tabs.map((tab: any) => {
                    return (
                      <th className="uk-padding-remove-horizontal">
                        <div className="border-box"/>
                        <div className="status-box">
                          {tab.tab_statuses.map((tabStatus: any) => {
                            return (
                              <div className="">{
                                props.selectedTabStatusesId !== tabStatus.id ?
                                  <a
                                    onClick={() => props.onSelectedTabStatusesId(tabStatus.id)}>{tabStatus.status}：{getTabStatusCount(tabStatus.id)}</a>
                                  :
                                  <span>{tabStatus.status}：{getTabStatusCount(tabStatus.id)}</span>
                              }</div>)
                          })}
                          <div className="">{
                            props.selectedTabId !== tab.id ?
                              <a onClick={() => props.onSelectedTabId(tab.id)}>全件：{allTabCounts[tab.id]}</a>
                              :
                              <span>全件：{allTabCounts[tab.id]}</span>
                          }</div>
                        </div>
                      </th>
                    )
                  })}
                </>
            }
            {/*  公開タブステータスのテーブルヘッダー　*/}
            {isFaq &&
                <th className="uk-padding-remove-horizontal">
                    <div className="border-box"/>
                    <div className="status-box">
                      {props.searchResults.data_faq_status_counts?.map((faqStatus: any) => {
                        return (
                          <div className="">
                            <a
                              onClick={() => props.onSelectedFaqStatus(faqStatus.id)}>{faqStatus.name}：{faqStatus.faq_statuses_count}</a>
                          </div>
                        )
                      })}
                        <div className="">
                            <a onClick={() => props.onSelectedFaq()}>全件：{allFaqCounts}</a>
                        </div>
                    </div>
                </th>
            }
          </tr>
          </thead>
          <tbody>
          {props.searchResults.datas.map(((data: any) => {
            const checked = props.checkIds.has(data.id);
            const linkAttrValue = data.data_attribute_values.find((attrValue: any) => linkAttr.id === attrValue.attribute_id);
            const link = getLink(linkAttrValue?.project_category_id, linkAttrValue?.data_id);
            const isLocked = lockedList.find(locked => locked.id === data.id) !== undefined;
            const applyAreas = data.data_applies.map((apply: any) => apply.data_apply_products.map((applyProduct: any) => applyProduct.sales_product.name)).flat(1);
            const requestStatus = data.data_approval_requests[0]?.approval_request_status_id === 2 ? '申請済み' : '未申請'
            const decisionStatus = Common.decisionStatus(data);
            const basicMapStatus = getSearchResultBasicMapStatus(props.searchResults.basic_map_statuses, data.id);

            return (
              <tr className={checked ? "selected" : ""}>
                <td className={checked ? "selected" : ""}>
                  <div className="border-box"/>
                  <div className="uk-flex uk-flex-center"><input type="checkbox" onChange={() => {
                    props.onClickCheck(data.id)
                  }} checked={checked}/></div>
                </td>
                <td
                  className={"uk-text-left" + (checked ? " selected" : "")}
                  style={{minWidth: linkWidth, maxWidth: linkWidth}}
                  data-tooltip-id="progress-tooltip"
                  data-tooltip-html={linkAttrValue?.attribute_value}
                >
                  <div className="border-box"/>
                  {link ? <Link to={link}>{linkAttrValue?.attribute_value}</Link> :
                    <span>{linkAttrValue?.attribute_value}</span>}
                  {isLocked && <div><span
                      className="lock-label">{`${data?.modified_user.last_name}${data?.modified_user.first_name}さんが編集中です`}</span>
                  </div>}
                </td>
                {isSalesApply &&
                    <>
                        <td style={{minWidth: 60}} className={checked ? "selected" : ""}
                            data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
                            <div className="border-box"/>
                          {requestStatus}
                        </td>
                        <td style={{minWidth: 60}} className={checked ? "selected" : ""}
                            data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
                            <div className="border-box"/>
                          {decisionStatus}
                        </td>
                      {decisionUsers.map((decisionUser) => {
                        const style = {
                          minWidth: 80,
                          maxWidth: 80,
                        }
                        const key = `td_decision_user_${data.id}_${decisionUser.id}`;
                        const approvalRequests = data.data_approval_requests;
                        let content = '-';
                        if (approvalRequests.length > 0) {
                          const me = approvalRequests[0].data_approval_decision_users.find((u: any) =>
                            u.is_decision_user === 1 && u.decision_users_id == decisionUser.id);
                          if (me) {
                            if (me.approval_decision_status.code === Config.APPROVAL_DECISION_STATUS_APPROVED_CODE) {
                              content = `${moment(me.modified).format('YYYY/MM/DD')}\n${me.comments ?? ''}`;
                            } else {
                              content = me.comments ?? '';
                            }
                          }
                        }
                        return (
                          <td
                            style={style}
                            key={key}
                            data-tooltip-id="progress-tooltip" data-tooltip-html={content.replaceAll('\n', '<br/>')}>
                            <div className="border-box"/>
                            {content}
                          </td>
                        )
                      })}
                    </>
                }
                {!isFaq &&
                    <>
                        <td className="uk-text-left" data-tooltip-id="progress-tooltip"
                            data-tooltip-html={data.area.name}>
                            <div className="border-box"/>
                          {data.area.name}</td>
                      {!isBasicMap &&
                          <td className="uk-text-left" data-tooltip-id="progress-tooltip"
                              data-tooltip-html={data.block.name}>
                              <div className="border-box"/>
                            {data.block.name}</td>
                      }
                    </>
                }
                {listAttrs.map((attr: any) => {
                  const attrValues = data.data_attribute_values.filter((attrValue: any) => attr.id === attrValue.attribute_id);
                  const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
                  const width = listWidth ? `${listWidth.value}px` : '200px';
                  const content = attrValues.map((attrValue: any) => {
                    if (Config.ATTRIBUTE_CODES.includes(attrValue.attribute_kind_code)) {
                      return `${attrValue.attribute_code}: ${attrValue.attribute_value}`
                    } else {
                      return `${attrValue.attribute_value}`
                    }
                  });
                  return (
                    <td
                      className="uk-text-left"
                      style={{minWidth: width, maxWidth: width}}
                      data-tooltip-id="progress-tooltip" data-tooltip-html={content.join('<br />')}
                    >
                      <div className="border-box"/>
                      {content.join('\n')}
                    </td>)
                })}
                {isSalesApply &&
                    <td
                        style={{minWidth: 100, maxWidth: 100}}
                        data-tooltip-id="progress-tooltip" data-tooltip-html={applyAreas.join('<br />')}
                        key={`td_apply_area_${data.id}`}
                    >
                        <div className="border-box"/>
                      {applyAreas.join(', ')}
                    </td>
                }
                {/* 共通化タブステータス */}
                {!isBasicMap &&
                    <>
                      {props.searchResults.tabs.map((tab: any) => {
                        const tabStatus = tab.tab_statuses.find((tabStatus: any) => {
                          return data.data_tab_statuses.find((dataTabStatus: any) => {
                            return dataTabStatus.tab_statuses_id === tabStatus.id
                          })
                        })?.status ?? '';
                        return (
                          <td data-tooltip-id="progress-tooltip" data-tooltip-html={tabStatus}>
                            <div className="border-box"/>
                            {tabStatus}
                          </td>
                        )
                      })}
                    </>}
                {/* 基盤地図ステータス */}
                {isBasicMap && <>
                    <td data-tooltip-id="progress-tooltip" data-tooltip-html={basicMapStatus.submission}>
                        <div className="border-box"/>
                      {basicMapStatus.submission}
                    </td>
                    <td data-tooltip-id="progress-tooltip" data-tooltip-html={basicMapStatus.certification}>
                        <div className="border-box"/>
                      {basicMapStatus.certification}
                    </td>
                </>}
                {/* 公開タブのステータスリスト */}
                {isFaq &&
                    <td data-tooltip-id="progress-tooltip">
                        <div className="border-box"/>
                      {currentFaqStatuses(data.id)?.statuses?.map((item: any) => {
                        return (
                          <div>{item.status}</div>
                        )
                      })}
                    </td>
                }
              </tr>
            )
          }))}
          </tbody>
        </table>
      </div>
      <div
        className="uk-text-center mt-5">全{props.searchResults.paging.count}件（{props.searchResults.paging.page}/{props.searchResults.paging.pageCount}）
      </div>
      <div className="pagination-container">
        <a onClick={() => {
          props.onPageChange(1)
        }}>FIRST</a>
        <ReactPaginate
          forcePage={props.searchResults.paging.page - 1} // ReactPaginate→0始まり CakePHP→1始まり
          onPageChange={(selectedItem: { selected: number }) => props.onPageChange(selectedItem.selected + 1)}
          pageRangeDisplayed={5}
          pageCount={props.searchResults.paging.pageCount}
          renderOnZeroPageCount={null}
          containerClassName='pagination'
          previousLabel='<'
          nextLabel='>'
          breakLabel='...'
        />
        <a onClick={() => {
          props.onPageChange(props.searchResults.paging.pageCount)
        }}>LAST</a>
      </div>
    </>
  )
}