import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import SubHeader from "@/components/SubHeader";
import {useCustomerStore} from "@/stores/customer";
import {ButtonSection} from "@/components/Customer/ButtonSection";
import {CurrentTab} from "@/containers/customer/drmPf/useDrmPf";
import {useBlockerStore} from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";
import ConfirmModal from "@/components/ConfirmModal";
import {DrmPfPage} from "@/config";

export default function DrmPf() {
  const {customerId} = useParams();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const breadCrumbs = useMemo(() => {
    {
      switch (CurrentTab(pathname)) {
        case DrmPfPage.Detail:
          return [{title: '顧客一覧', path: '/customer'}, {title: 'DRM-PF基本情報'}]
        case DrmPfPage.Edit:
          return [{title: '顧客一覧', path: '/customer'}, {
            title: 'DRM-PF基本情報',
            path: `/customer/drmpf/detail/${customerId}`
          }, {title: '編集'}
          ]
        default:
          return [{title: '顧客一覧', path: '/customer'}, {title: 'API利用履歴'}]
      }
    }
  }, [pathname, customerId])

  const [isShowBackModal, setIsShowBackModal] = useState(false);

  const [drmPfCommonState, childSubmit] = useCustomerStore(state => [state.drmPfCommonState, state.childSubmit]);
  const setBlocker = useBlockerStore((state) => state.setBlocker);

  const isEdit = CurrentTab(pathname) === DrmPfPage.Edit;
  const isApi = CurrentTab(pathname) === DrmPfPage.Api;

  const handleTabChange = (tab: string) => {
    navigate(`/customer/drmpf/${tab}/${customerId}`)
  }

  const onClickCancel = () => {
    setBlocker(false);
    setIsShowBackModal(true);
  };
  const onClickSave = () => {
    setBlocker(false);
    childSubmit();
  };

  useEffect(() => {
    setBlocker(isEdit);
  }, [pathname]);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={drmPfCommonState?.customerNumber}/>
        <ul className="uk-tab uk-margin-small-top">
          <li><Link to={`/customer/basicinfo/detail/${customerId}`}>基本情報</Link></li>
          <li className="uk-active"><Link to={`/customer/drmpf/detail/${customerId}`}>DRM-PF</Link></li>
          <div className="right">最終更新：{drmPfCommonState?.modified_by}&emsp;日時：{drmPfCommonState?.modified}</div>
        </ul>
      </div>
      <div className="container">
        <ul className="uk-tab customer-attribute-tab">
          <li className={!isApi ? 'uk-active' : ''}
              onClick={() => handleTabChange(DrmPfPage.Detail)}><a className="leading-1">DRM-PF<br/>基本情報</a>
          </li>
          <li className={isApi ? 'uk-active' : ''}
              onClick={() => handleTabChange(DrmPfPage.Api)}><a className="h-75">API利用履歴</a>
          </li>
          <ButtonSection handleTabChange={handleTabChange} onClickCancel={onClickCancel} onClickSave={onClickSave}/>
        </ul>
        <Outlet/>
      </div>
      <BlockModal/>
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="前の画面へ"
        isShow={isShowBackModal}
        onConfirm={() => {
          setIsShowBackModal(false);
          navigate(-1);
        }}
        onCancel={() => {
          setIsShowBackModal(false);
        }}
      />
    </div>
  );
}
export const drm_pf_info_items = [
  {label: '承認', content: "未承認"},
]
