import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import ConfirmModal from '@/components/ConfirmModal';
import { deleteGroup, getGroups } from "@/api/groups";
import { useLoadingStore } from "@/stores/loading";
import { MESSAGE_NO_E37, MESSAGE_NO_E39, PERMISSION_ROLL_SYSADMIN } from "@/config";
import { useUserStore } from "@/stores/user";

interface Group {
  id: number,
  name: string,
}

interface LastUpdated {
  last_updated_by?: string,
  last_updated_at?: string,
}

export default function GroupList() {
  const navigate = useNavigate();
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);

  const [isShowModal, setIsShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [groups, setGroups] = useState<Group[]>([]);
  const [lastUpdated, setLastUpdated] = useState<LastUpdated>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const breadCrumbs = [
    { title: '管理画面', path: '/admin' },
    { title: 'グループ一覧' }
  ];

  useEffect(() => {
    if (user?.roll !== PERMISSION_ROLL_SYSADMIN) {
      navigate('/admin');
    }
    setLoading(true);
    const response = getGroups();
    response.then(result => {
      setGroups(result.data.groups);
      setLastUpdated(result.data.last_updated);
      console.log(result.data)
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  const onClickNew = () => {
    navigate("/admin/groups/new");
  }

  const openDeleteGroupModal = ( id: number ) => {
    setIsShowModal(true);
    setDeleteId(id);
  }

  const submitDeleteGroup = () => {
    setLoading(true);
    const response = deleteGroup(deleteId);
    response.then(result => {
      setGroups(result.data.groups);
      setLastUpdated(result.data.last_updated);
      setIsShowModal(false);
      console.log(deleteId + "を削除しました")
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E37);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage])

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="グループ管理"/>
        <div className="uk-text-right uk-margin-small-bottom">
          最終更新ユーザ：{lastUpdated?.last_updated_by} 最終更新:{lastUpdated?.last_updated_at}
        </div>
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div></div>
          <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成
          </button>
        </div>
      </div>
      <div className="container">
        <div className="group-tbl-wrap">
          <table className="group-tbl">
            <thead>
            <tr>
              <th>
                <div className="border-box"/>
                No.
              </th>
              <th className="uk-text-left">
                <div className="border-box"/>
                グループ名
              </th>
              <th>
                <div className="border-box"/>
                削除
              </th>
            </tr>
            </thead>
            <tbody>
            {groups.map(( item, index ) => (
              <tr key={index}>
                <td className="uk-text-center">
                  <div className="border-box"/>
                  {index + 1}</td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  <Link to={`/admin/groups/${item.id}/edit`}>{item.id}：{item.name}</Link></td>
                <td className="uk-text-center">
                  <div className="border-box"/>
                  <a onClick={() => {
                    openDeleteGroupModal(item.id)
                  }}>削除</a>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmModal
        text="削除してもよろしいですか？"
        confirmButtonText="削除"
        isShow={isShowModal}
        onConfirm={() => {
          submitDeleteGroup();
        }}
        onCancel={() => {
          setIsShowModal(false);
        }}
      />
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
    </div>
  );
}