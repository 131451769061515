import { axios } from '@/lib/axios';

interface getDataApprovalRequestParams {
  data_id: number;
  project_category_id: number;
}

export const getDataApprovalRequest = ( params: getDataApprovalRequestParams ) => {
  return axios.get(`/api/v1/dataApprovalRequests/get`, { params });
};

export const postDataApprovalRequest = ( params: any ) => {
  return axios.post(`/api/v1/dataApprovalRequests/post`, params);
};

export const postDataApprovalDecisionUser = ( params: any ) => {
  return axios.post(`/api/v1/dataApprovalRequests/postDataApprovalDecisionUser`, params);
};
