import create from 'zustand';

export type AreaList = {
    area_list: any[];
    tax_rate: number;
}

type AreaListStore = {
    areaList: AreaList | undefined;
    setAreaList: (areaList: AreaList | undefined) => void;
};

export const useAreaListStore = create<AreaListStore>((set) => ({
    areaList: undefined,
    setAreaList: (areaList) =>
        set((state) => ({
            areaList: areaList,
        })),
}));
