import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {DataProps} from '@/containers/data/Data'
import * as Config from '@/config'
import FileNew from '@/containers/admin/Documents/FileNew'
import FileEdit from '@/containers/admin/Documents/FileEdit'
import FileList from '@/containers/admin/Documents/FileList'
import {pdfjs} from 'react-pdf'
import "pdfjs-dist/build/pdf.worker.entry";
import * as Common from '@/utils/common'
import {useProjectStore} from '@/stores/project'
import {useUserStore} from '@/stores/user'
import SubHeader from '@/components/SubHeader'

export interface DocumentManagementProps {
  isType: Config.DocumentManagement
}
export default function DocumentManagement(props: DocumentManagementProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserStore(state => state.user);
  const canDocumentManagement = Common.canDocumentManagement(user);

  useEffect(() => {
    if (canDocumentManagement < Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_OK.value) {
      navigate('/admin');
    }
  }, [location.pathname]);

  const breadCrumbs: { title: string, path?: string }[] = [
    { title: '管理画面', path: '/admin' },
    { title: '資料管理' }
  ];

  const renderPage = () => {
    switch (props.isType) {
      case Config.DocumentManagement.FileNew:
        return <FileNew />
      case Config.DocumentManagement.FileEdit:
        return <FileEdit />
      case Config.DocumentManagement.FileList:
        return <FileList />
      default:
        return null;
    }
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="資料管理" />
      </div>
      {renderPage()}
    </div>
  )
}