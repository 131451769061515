import React, {useEffect} from "react";
import {basicMapStore} from "@/stores/basicMap";
import {useParams} from "react-router-dom";
import {useBasicMap} from "@/utils/useBasicMap";

export const DetailCertificationContainer = () => {
  const params = useParams();
  const hooks = useBasicMap();
  const {
    fetchSubmissionStatus,
    submissionStatus,
    fetchCertificationStatus,
    certificationStatus,
    fetchCertification,
    certification,
    reset
  } = basicMapStore();

  useEffect(() => {
    fetchSubmissionStatus();
    fetchCertificationStatus();
    fetchCertification(Number(params.dataId));
    return () => {
      reset();
    }
  }, [])

  return (
    <div className="certification-tbl">
      <table className="uk-width-1-1">
        <thead>
        <tr>
          <th className="hidden"></th>
          <th className="hidden"></th>
          <th className="width-15p">日付</th>
          <th className="width-15p">担当者</th>
          <th className="width-15p">ステータス</th>
          <th>備考</th>
        </tr>
        </thead>
        <tbody>
        <tr className="">
          <th>作成会社</th>
          <th>検定提出日</th>
          <td>{certification?.submission_date}</td>
          <td>{certification?.submission_charge}</td>
          <td>{hooks.findCertificationOption(certification?.submission_status, submissionStatus)}</td>
          <td className="line-break">{certification?.submission_remarks}</td>
        </tr>
        <tr className="">
          <th rowSpan={2}>検定機関</th>
          <th>受領日</th>
          <td>{certification?.receipt_date}</td>
          <td>{certification?.receipt_charge}</td>
          <td>{hooks.findCertificationOption(certification?.certification_status, certificationStatus)}</td>
          <td className="line-break">{certification?.receipt_remarks}</td>
        </tr>
        <tr className="">
          <th>検定日</th>
          <td>{certification?.certification_date}</td>
          <td>{certification?.certification_charge}</td>
          <td>{hooks.findCertificationOption(certification?.certification_status, certificationStatus)}</td>
          <td className="line-break">{certification?.certification_remarks}</td>
        </tr>
        <tr className="hidden-record">
          <td colSpan={6}></td>
        </tr>
        <tr className="">
          <th colSpan={2}>検定指摘への回答</th>
          <td className="line-break" colSpan={4}>{certification?.answer}</td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}