import { useLoadingStore } from "@/stores/loading";
import { useUserStore } from "@/stores/user";
import usePostPf from "@/utils/usePostPf";
import { DRM_PF_FREE_MAP_URL, DRM_PF_MAP_URL } from "@/config";
import React from "react";

interface OpenMapButtonProps {
  attribute: any;
  attrValue: any;
}

export const OpenMapButton = ( props: OpenMapButtonProps ) => {
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);

  const { formDataForMap, postExternalSite, openFreeViewer } = usePostPf();
  const hasCredentials = user?.has_drm_pf_credentials ?? false;

  const onClickMap = async ( attr: any ) => {
    setLoading(true)
    const formData = await formDataForMap(attr, props.attrValue).finally(() => {
      setLoading(false)
    })
    hasCredentials ? postExternalSite(DRM_PF_MAP_URL, formData) : openFreeViewer();
  }

  return (
    <button
      type="button"
      className="uk-button--m uk-button-refer uk-position-absolute uk-position-center-right uk-margin-small-right"
      onClick={() => onClickMap(props.attribute)}
    >P-ID表示</button>
  )
}