import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import SubHeader from "@/components/SubHeader";
import {useCustomerStore} from "@/stores/customer";
import {useBlockerStore} from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";
import ConfirmModal from "@/components/ConfirmModal";

import {axios} from "@/lib/axios";
import * as Config from "@/config";
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";

import {getUsageHistory} from "@/api/customer";
import {
  IS_BILLING_FLG,
  MESSAGE_NO_E39,
} from "@/config";
import {DrmPfApiUsagesOptions, useDrmPfApiUsages} from "@/containers/customer/usageHistory/useDrmPfApiUsages";
import {AllBills, Fees, AllLogs, Months} from "@/containers/customer/drmPf/types";
import qs from 'qs';
import {useForm} from "react-hook-form";

interface CustomerTypes {
  value: string,
  label: string,
}
interface CustomerGroupTypes {
  value: string,
  label: string,
}
interface CustomerPaymentTypes {
  value: string,
  label: string,
}

export default function UsageHistory() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const breadCrumbs = [
    {title: '顧客一覧', path: '/customer'},
    {title: 'API利用履歴'}
  ];

  const [setIsShowBackModal] = useState(false);

  const setBlocker = useBlockerStore((state) => state.setBlocker);

  const UsagesOptions = DrmPfApiUsagesOptions();
  const {CurrentDate} = useDrmPfApiUsages();
  const setLoading = useLoadingStore(state => state.setLoading);
  const [setDrmPfCommonState, drmPfCommonState] = useCustomerStore(state => [state.setDrmPfCommonState, state.drmPfCommonState]);

  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState<AllLogs[]>([]);
  const [fees, setFees] = useState<Fees[]>([]);
  const [bills, setBills] = useState<AllBills[]>([]);
  const [month, setMonth] = useState<string | undefined>(CurrentDate);
  const [billingFlg, setBillingFlg] = useState<string | null>(null);
  const [selectMonth, setSelectMonths] = useState<Months[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  const onClickToggle = () => setOpen((prev) => !prev);
  const handleChange = (e: any) => {
    setMonth(e.target.value);
    updateDrmApiData(e.target.value);
  }
  const [customerQueryParams, setCustomerQueryParams] = useCustomerStore(state => [
    state.queryParams,
    state.setQueryParams,
  ]);

  // 検索項目
  const [customerNumber, setCustomerNumber] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [customerTypeId, setCustomerTypeId] = useState<any[]>([]);
  const [customerGroupTypeId, setCustomerGroupTypeId] = useState<string>("");
  const [customerPaymentTypeId, setCustomerPaymentTypeId] = useState<any[]>([]);
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [resetForm, setResetForm] = useState<boolean>(false);
  // 検索結果
  const [searchQueryParam, setSearchQueryParam] = useState<any>(undefined);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  // 検索結果の入力項目
  const [checkIds, setSelectedCustomerIds] = useState<any[]>([]);
  const [paging, setPaging] = useState<any>(undefined);
  
  const [selectedPageSize, setSelectedPageSize] = useState<string | ''>('100')

  const [customerTypes, setCustomerTypes] = useState<CustomerTypes[]>([]);
  const [customerGroupTypes, setCustomerGroupTypes] = useState<CustomerGroupTypes[]>([]);
  const [customerPaymentTypes, setCustomerPaymentTypes] = useState<CustomerPaymentTypes[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const setMessage = useMessageModalStore(state => state.setMessage);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<any>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
    }
  );

  useEffect(() => {
    // URLパラメータから検索条件を取得し、状態に反映する
    const params = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });
console.log("----params----");
console.log(params);
    if (typeof params.customer_number === 'string') {
      setCustomerNumber(params.customer_number);
    }
    if (typeof params.group_name === 'string') {
      setGroupName(params.group_name);
    }
    if (typeof params.last_name === 'string') {
      setLastName(params.last_name);
    }
    if (typeof params.first_name === 'string') {
      setFirstName(params.first_name);
    }
    // 団体区分
    if (typeof params.customer_group_type_id === 'string') {
      setCustomerGroupTypeId(params.customer_group_type_id);
    }
    // 問合せ者区分
    if (Array.isArray(params.customer_type_id)) {
      const numbers = params.customer_type_id.map(
        value => typeof value === "string" ? parseInt(value) : null
      ).filter(value => value != null);
      setCustomerTypeId(numbers);
    } else if (typeof params.customer_type_id === 'string') {
      setCustomerTypeId([parseInt(params.customer_type_id)]);
    }
    // 課金区分
    if (Array.isArray(params.customer_payment_type_id)) {
      const numbers = params.customer_payment_type_id.map(
        value => typeof value === "string" ? parseInt(value) : null
      ).filter(value => value != null);
      setCustomerPaymentTypeId(numbers);
    } else if (typeof params.customer_payment_type_id === 'string') {
      setCustomerPaymentTypeId([parseInt(params.customer_payment_type_id)]);
    }
    if (typeof params.from_date === 'string') {
      setFromDate(params.from_date);
    }
    if (typeof params.to_date === 'string') {
      setToDate(params.to_date);
    }
    if (typeof params.page_size === 'string') {
      setSelectedPageSize(params.page_size);
    }
  }, [searchParams]);

  useEffect(() => {
    axios.get('/api/v1/customers/getCustomerTypes')
    .then(result => {
      setCustomerTypes(result.data.customerTypes);
      setCustomerGroupTypes(result.data.customerGroupTypes);
      setCustomerPaymentTypes(result.data.customerPaymentTypes);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  // 検索処理
  function doSearch(params: any, page=1) {
    //console.log("------ doSearch -----");
    console.log("----- params ------");
    console.log(params);
    setLoading(true);
    axios.get('/api/v1/customers/getCustomers', {
      //params
      params: params
    }).then(result => {
      console.log(result.data)
      console.log(result.data.pgiongoarams)
      params = result.data.params;
      //setCustomers(result.data.customers);
      setSearchQueryParam(result.data.params);
      setPaging(result.data.paging);
      setSearchParams(qs.stringify(params));
      setCustomerQueryParams(params);
      //updateDrmApiData(month);
/*      
      setLogs(result.data.displayLogData);
      setBills(result.data.billData);
      setFees(result.data.apiGroup);
      setSelectMonths(result.data.selectMonths);
      console.log(result.data);
      if (result.data.logData[0]['is_billing'] != null) {
        const billig_flg = IS_BILLING_FLG.find((output, index) => {
          return output.value == result.data.logData[0]['is_billing'];
        });
        setBillingFlg(billig_flg?.label || '');
      }
*/        
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  const onSearch = () => {
    const currentSelectedPageSize = selectedPageSize; // 最新のselectedPageSizeの値を保持する
    const params = {
      customer_number: customerNumber,
      group_name: groupName,
      last_name: lastName,
      first_name: firstName,
      customer_group_type_id: customerGroupTypeId,
      customer_type_id: customerTypeId,
      customer_payment_type_id: customerPaymentTypeId,
      from_date: fromDate,
      to_date: toDate,
      page_size: currentSelectedPageSize
    }
    //console.log("-----検索ボタン押下-----");
    //console.log(params);
    doSearch(params, 1);
  }

  /* */
  function updateDrmApiData(month: string | undefined) {
    setLoading(true)
    const response = getUsageHistory(month);
    response.then(result => {
      setLogs(result.data.displayLogData);
      setBills(result.data.billData);
      setFees(result.data.apiGroup);
      setSelectMonths(result.data.selectMonths);
      console.log(result.data);
      if (result.data.logData[0]['is_billing'] != null) {
        const billig_flg = IS_BILLING_FLG.find((output, index) => {
          return output.value == result.data.logData[0]['is_billing'];
        });
        setBillingFlg(billig_flg?.label || '');
      }
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }
/* */
  useEffect(() => {
    updateDrmApiData(month);
  }, []);

  useEffect(() => {
    if (customerQueryParams) {
      setSearchQueryParam(customerQueryParams);
    }
    doSearch(customerQueryParams, 1);
  }, [selectedPageSize]);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={drmPfCommonState?.customerNumber}/>
        <ul className="uk-tab uk-margin-small-top">
          <li><Link to={`/customer/apiUsage`}>API利用履歴</Link></li>
        </ul>
      </div>
      <div className="container">
        <div className="info-tbl_wrap uk-margin-small-top">
          <form>
            <table className="condition-tbl">
              <tbody>
              <tr className="header">
                <th>顧客番号</th>
                <th>法人・団体名</th>
                <th>姓</th>
                <th>名</th>
                <th>問合せ者区分</th>
                <th>団体区分</th>
                <th>課金区分</th>
                <th>照会年月</th>
              </tr>
              <tr>
                <td>
                  <input
                    className="w-100"
                    type="text"
                    value={customerNumber}
                    onChange={(e) => setCustomerNumber(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="w-100"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="w-100"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </td>
                <td>
                  <div className="uk-flex uk-flex-wrap">
                    {customerTypes?.map(customerType => (
                      <label key={customerType.value} className="uk-margin-small-right">
                        <input
                          type="checkbox"
                          name="customer_type_id[]"
                          value={customerType.value}
                          onChange={(e) => {
                            const selectedValue = parseInt(e.target.value, 10);
                            const updatedIds = [...customerTypeId];
                            if (e.target.checked) {
                              updatedIds.push(selectedValue);
                            } else {
                              const index = updatedIds.indexOf(selectedValue);
                              if (index !== -1) {
                                updatedIds.splice(index, 1);
                              }
                            }
                            setCustomerTypeId(updatedIds);
                          }}
                          checked={customerTypeId.includes(customerType.value)}
                        />
                        {customerType.label}
                      </label>
                    ))}
                  </div>
                </td>
                <td>
                  <div>
                    <select 
                      className={`${customerGroupTypeId ? '' : 'placeholder'} ${errors.area_id ? "error-form" : ""}`}
                      defaultValue={""}
                      value={customerGroupTypeId}
                      name="customer_group_type_id"
                      onChange={(e) => {
                        setCustomerGroupTypeId(e.target.value);
                      }}
                    >
                      <option value="">団体区分選択</option>
                      {customerGroupTypes?.map(( item, index ) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td>
                  <div className="uk-flex uk-flex-wrap">
                    {customerPaymentTypes?.map(customerPaymentType => (
                      <label key={customerPaymentType.value} className="uk-margin-small-right">
                        <input
                          type="checkbox"
                          name="customer_payment_type_id[]"
                          value={customerPaymentType.value}
                          onChange={(e) => {
                            const selectedValue = parseInt(e.target.value, 10);
                            let updatedIds = [...customerPaymentTypeId, selectedValue];
                            if (customerPaymentTypeId.includes(selectedValue)) {
                              updatedIds = updatedIds.filter(day => day != selectedValue);
                            }
                            setCustomerPaymentTypeId(updatedIds);
                          }}
                          checked={customerPaymentTypeId.includes(customerPaymentType.value)}
                        />
                        {customerPaymentType.value}:{customerPaymentType.label}
                      </label>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="uk-flex uk-flex-wrap">
                    <div className="uk-inline">
                      <input
                        name="to_date"
                        type="date"
                        className="uk-width-1-1"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </div>
                    <div className="uk-inline">
                      ～
                    </div>
                    <div className="uk-inline">
                      <input
                        name="from_date"
                        type="date"
                        className="uk-width-1-1"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                    <span className="uk-margin-small-left">※暫定</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={8}>
                  <div className="uk-flex uk-flex-center">
                    <button type="button" className="uk-button--m uk-button-refer" onClick={() => onSearch()}>検索</button>
                    <button className="uk-button--m uk-button-cancel uk-margin-left">クリア</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </form>

          <div className="uk-width-1-1 uk-overflow-auto">
            <table className="API-table uk-margin-top">
              <thead>
              <tr>
                {UsagesOptions.drm_pf_api_summary_th.map((item, index) => {
                  const replaced_label = item.label.split(/(\n)/).map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.match(/\n/) ? <br/> : item}
                      </React.Fragment>
                    );
                  });
                  const style: React.CSSProperties = {
                    width: item.width
                  };
                  return (
                    <th className={`label uk-text-center ${item.bold && "uk-text-bold"}`}
                        style={style}
                        rowSpan={item.rowspan}
                        colSpan={item.colspan}
                        key={index}
                    >
                      {replaced_label}
                    </th>
                  );
                })}
              </tr>
              <tr>
                {UsagesOptions.drm_pf_api_summary_th_2nd.map((item, index) => {
                  const style: React.CSSProperties = {
                    width: item.width
                  };
                  return (
                    <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                  );
                })}
              </tr>
              </thead>
              <tbody>
              {Object.values(bills).length !== 0 && Object.values(bills).map((item, index) => (
                <tr key={index}>
                  <td className="uk-text-left">{item.customer_number}</td>
                  <td className="uk-text-left">{item.customer_type}</td>
                  <td className="uk-text-left">{item.customer_group_type}</td>
                  <td className="uk-text-left">{item.group_name}</td>
                  <td className="uk-text-left">{item.department_name}</td>
                  <td className="uk-text-center">{item.paymentType}</td>
                  <td className="uk-text-right">¥ {item.basicFee}</td>
                  <td className="uk-text-right">{item.validTotalAll} 件</td>
                  <td className="uk-text-right">¥ {item.undiscountPriceTotal}</td>
                  <td className="uk-text-right">¥ {item.discountAmount}</td>
                  <td className="uk-text-right">{item.realDiscountRate} %</td>
                  <td className="uk-text-right">¥ {item.discountPriceTotal}</td>
                  <td className="uk-text-right">{item.rate} %</td>
                  <td className="uk-text-right">{item.termDiscounts} %</td>
                  <td className="uk-text-right">¥ {item.allDiscountPriceTotal}</td>
                  <td className="uk-text-right uk-text-bold">¥ {item.billingAmount}</td>
                  <td className="uk-text-right uk-text-bold">{item.tax} %</td>
                  <td className="uk-text-right uk-text-bold">¥ {item.includeTax}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          <div className="uk-margin-small-top toggle-wrapper">
            <button type="button"
                    className="uk-button uk-button-text uk-text-primary"
                    onClick={onClickToggle}
            >
              {open ? "▲" : "▼"} API利用料 内訳
            </button>
            <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${open ? "visible" : "hidden"}`}>
              <table className="API-table uk-width-auto">
                <thead>
                <tr>
                  {UsagesOptions.drm_pf_api_details_th.map((item, index) => {
                    const style: React.CSSProperties = {
                      width: item.width
                    };
                    return (
                      <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                    );
                  })}
                </tr>
                </thead>
                <tbody>
                {Object.values(fees).length !== 0 && Object.values(fees).map((item, index) => (

                  <tr key={item.api_id}>
                    <td>{item.api_id}</td>
                    <td className="uk-text-right">{item.total} 件</td>
                    <td className="uk-text-right">{item.temporary_free_total} 件</td>
                    <td className="uk-text-right">{item.valid_total} 件</td>
                    <td className="uk-text-right">¥ {item.unit_price}</td>
                    <td className="uk-text-right">¥ {item.subtotal}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <p className="uk-text-right uk-margin-remove-bottom">
            ※こちらの小計はボリュームディスカウント前の金額となります。
          </p>
          <div className="uk-width-1-1 uk-overflow-auto">
            <table className="API-table">
              <thead>
              <tr>
                {UsagesOptions.drm_pf_api_usages_th.map((item, index) => {
                  const style: React.CSSProperties = {
                    width: item.width
                  };
                  return (
                    <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                  );
                })}
              </tr>
              </thead>
              <tbody>
              {logs.length !== 0 && logs.map((item, index) => (
                <tr key={index}>
                  <td>{item.customer_number}</td>
                  <td>{item.customer_type}</td>
                  <td>{item.customer_group_type}</td>
                  <td>{item.group_name}</td>
                  <td>{item.department_name}</td>
                  <td>{item.start_date}</td>
                  <td>{item.end_date}</td>
                  <td>{item.ip_address}</td>
                  <td>{item.api_id}</td>
                  <td className="uk-text-center">{item.processed_result}</td>
                  <td className="uk-text-center">{item.processed_kind}</td>
                  <td className="uk-text-center">{item.edition_information}版</td>
                  <td className="uk-text-right">{item.data_number} 件</td>
                  <td className="uk-text-right">¥ {item.unit_price}</td>
                  <td className="uk-text-center">{item.is_billing_str}</td>
                  {item.is_temporary_free === 1 ? <td className="uk-text-right"> -{item.data_number} 件</td> :
                    <td className="uk-text-right"></td>}
                  <td className="uk-text-right">¥ {item.subtotal}</td>
                </tr>
              ))}

              </tbody>
            </table>
          </div>
          {errorMessage &&
              <ConfirmModal
                  text={errorMessage}
                  confirmButtonText="OK"
                  isShow={isShowErrorModal}
                  onConfirm={() => {
                    setIsShowErrorModal(false);
                    setErrorMessage(null);
                    navigate("/drm_pf/info");
                  }}
              />
          }
        </div>

      </div>
      <BlockModal/>
    </div>
  );
}
export const drm_pf_info_items = [
  {label: '承認', content: "未承認"},
]
