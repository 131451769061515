import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import ConfirmModal from '@/components/ConfirmModal';
import {axios} from '@/lib/axios'
import * as Config from '@/config'
import * as Common from '@/utils/common'
import {useLoadingStore} from '@/stores/loading'
import {useProjectStore} from '@/stores/project'
import moment from 'moment'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent, MouseSensor
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useUserStore} from '@/stores/user'
import {useMessageModalStore} from '@/stores/message'

export default function FileList() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const sensors = useSensors(
    // useSensor(PointerSensor),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // }),
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
  );
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const user = useUserStore(state => state.user);
  const canFile = Common.canFile(selectedProjectCategory?.id, user);

  const breadCrumbs = [
    { title: '進捗一覧', path: '/progress' },
    { title: 'ファイル' }
  ];

  const headerTop = (document.querySelector("header")?.offsetHeight ?? 0)
    + (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);

  const [selectedFileAttribute, setSelectedFileAttribute] = useState<string>("");
  const [fileAttributes, setFileAttributes] = useState<any[]>([]);
  const [dataFiles, setDataFiles] = useState<any[]>([]);
  const [paging, setPaging] = useState<any>(undefined);

  function getInputs() {
    setLoading(true);
    axios.get('/api/v1/fileAttributes/inputs', {
      params: {
        project_category_id: selectedProjectCategory?.id,
      }
    }).then(result => {
      setFileAttributes(result.data.fileAttributes);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function getDataFiles(page=1) {
    setLoading(true);
    axios.get('/api/v1/dataFiles/list', {
      params: {
        data_id: params.dataId,
        file_attribute_code: selectedFileAttribute,
        page: page,
      }
    }).then(result => {
      const dataFiles = result.data.dataFiles;
      const s3Urls = result.data.s3Urls;
      for (let i = 0; i < dataFiles.length; i++) {
        dataFiles[i]["s3FilePath"] = s3Urls[dataFiles[i]["id"]]["filepath"];
        dataFiles[i]["s3Thumbnail"] = s3Urls[dataFiles[i]["id"]]["thumbnail"];
      }
      setDataFiles(dataFiles);
      setPaging(result.data.paging);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function deleteById(id: number) {
    setLoading(true);
    axios.post('/api/v1/dataFiles/delete', {
      id: id,
    }).then(result => {
      getDataFiles();
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function moveDisplayNo(id: any, target_display_no: any, update: boolean = false) {
    // バックエンドでも
    setLoading(true);
    axios.post('/api/v1/dataFiles/moveDisplayNo', {
      id: id,
      target_display_no: target_display_no,
    }).then(result => {
      if (update) {
        getDataFiles();
      }
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getInputs();
  }, [location.pathname]);

  // 初回取得とユーザー操作起因がよい？
  useEffect(() => {
    getDataFiles();
  }, [selectedFileAttribute]);

  const onClickNew = () => {
    navigate(Common.filesNewUrl(params.projectCategoryId, params.dataId));
  }

  const onEdit = (id: number) => {
    navigate(Common.filesEditUrl(params.projectCategoryId, params.dataId, id));
  }

  const onDelete = (id: number) => {
    setModalConfirm({callback: () => deleteById(id)});
  }

  const onPageChange = (page: number) => {
    getDataFiles(page);
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      setDataFiles((dataFiles) => {
        const oldIndex = dataFiles.findIndex(dataFile => dataFile.id === active.id);
        const newIndex = dataFiles.findIndex(dataFile => dataFile.id === over.id);
        const newDataFiles = arrayMove(dataFiles, oldIndex, newIndex);
        const [start, end] = oldIndex < newIndex ? [oldIndex, newIndex] : [newIndex, oldIndex];

        // arrayMoveはオブジェクトの更新ではないので上書きになってしまう
        // for(let i = start; i <= end; i++) {
        //   newDataFiles[i].display_no = dataFiles[i].display_no;
        // }
        const displayNo = dataFiles.slice(start, end + 1).map(dataFile => dataFile.display_no);
        newDataFiles.slice(start, end + 1).forEach((dataFile, index) => {
          dataFile.display_no = displayNo[index];
        });
        moveDisplayNo(newDataFiles[newIndex].id, newDataFiles[newIndex].display_no);
        return newDataFiles;
      });
    }
  }

  return (
    <>
      <div className="sub-header" style={{top: headerTop}}>
        <div className="uk-flex-between uk-flex uk-flex-middle uk-margin-small-top">
          <div className="uk-inline">
            <span className="uk-form-icon uk-form-icon-flip" uk-icon="chevron-down"></span>
            <select className={`${selectedFileAttribute ? '' : 'placeholder'}`}
                    onChange={(e) => setSelectedFileAttribute(e.target.value)}>
              <option value="">属性で絞り込みできます</option>
              {fileAttributes.map((o: any) => {
                return <option value={o.code}>{o.code + ': ' + o.name}</option>
              })}
            </select>
          </div>
          <div>
            {canFile > Config.RESTRICTION_HISTORY_FILE_VIEW.value &&
                <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成</button>
            }
          </div>
        </div>
      </div>
      <div className="container">
        <div className="file-tbl-wrap file-tbl-separate uk-margin-small-top">
          <div className="file-tbl">

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={dataFiles}
                strategy={verticalListSortingStrategy}
              >
                <table>
                  {dataFiles.map((dataFile, index) => {
                    return (<FileRow index={index} dataFile={dataFile} onEdit={onEdit} onDelete={onDelete} moveDisplayNo={moveDisplayNo} />);
                  })}
                </table>
              </SortableContext>
            </DndContext>
          </div>
        </div>
        {paging &&
          <>
              <div className="uk-text-center mt-5">全{paging.count}件（{paging.page}/{paging.pageCount}）</div>

              <div className="pagination-container">
                  <a onClick={() => {onPageChange(1)}}>FIRST</a>
                  <ReactPaginate
                      forcePage={paging.page - 1}
                      onPageChange={(selectedItem: {selected: number}) => onPageChange(selectedItem.selected + 1)}
                      pageRangeDisplayed={5}
                      pageCount={paging.pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      previousLabel='<'
                      nextLabel='>'
                      breakLabel='...'
                  />
                  <a onClick={() => {onPageChange(paging.pageCount)}}>LAST</a>
              </div>
          </>
        }
      </div>
      <ConfirmModal
        text={Config.MESSAGE_NO_E11}
        confirmButtonText="削除"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if(modalConfirm)modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </>
  );
}

interface FileRowProps {
  index: number
  dataFile: any,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  moveDisplayNo: (id: any, target_display_no: any, update: boolean) => void,
}
const FileRow = ({index, dataFile, onEdit, onDelete, moveDisplayNo}: FileRowProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({ id: dataFile.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [targetDisplayNo, setTargetDisplayNo] = useState('');

  return (
    <>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th rowSpan={4} className={`border-left ${index === 0 ? "border-top" : ""}`}>
          {dataFile.display_no}
        </th>
        <th rowSpan={4} className={`media ${index === 0 ? "border-top" : ""}`}>
          <div className="media_flex">
            <div className="media_img">
              {dataFile.s3Thumbnail !== "" ?
                <img src={dataFile.s3Thumbnail} />: <span>No Image</span>
              }
            </div>
          </div>
        </th>
        <th className={`label ${index === 0 ? "border-top" : ""}`}>ファイル名</th>
        <td className={`content ${index === 0 ? "border-top" : ""}`}><a href={dataFile.s3FilePath} target="_blank">{dataFile.filename}</a></td>
        <th className={`label ${index === 0 ? "border-top" : ""}`}>元ファイル名</th>
        <td className={`content ${index === 0 ? "border-top" : ""}`}>{dataFile.original_filename}</td>
        <td className={`action ${index === 0 ? "border-top" : ""}`} rowSpan={4}>
          <div>
            <button className="uk-button--m uk-button-refer" onClick={() => onEdit(dataFile.id)}>編集</button>
            <button className="uk-button--m uk-button-cancel uk-margin-top" onClick={() => onDelete(dataFile.id)}>削除</button>
            <div className="uk-button--m uk-margin-top">
              <input
                id={`targetDisplayNo.${dataFile.id}`}
                className="uk-input"
                value={targetDisplayNo}
                onChange={e => setTargetDisplayNo(e.target.value)}
              />
            </div>
            <button className="uk-button--m uk-button-cancel uk-margin-top" disabled={targetDisplayNo === ""} onClick={() => {
              moveDisplayNo(dataFile.id, targetDisplayNo, true);
              setTargetDisplayNo('');
            }}>移動</button>
          </div>
        </td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">属性</th>
        <td colSpan={3} className="content">{`${dataFile.file_attribute_code}: ${dataFile.file_attribute.name}`}</td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">備考</th>
        <td colSpan={3} className="content">{dataFile.remarks}</td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">更新日時</th>
        <td className="content">{moment(dataFile.modified).format(Config.DATE_FORMAT)}</td>
        <th className="label">更新者</th>
        <td className="content">{dataFile.modified_user && `${dataFile.modified_user.last_name}${dataFile.modified_user.first_name}`}</td>
      </tr>
    </>
  );
}