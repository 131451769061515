import create from 'zustand';
import {
  getBasicMapCertificationStatuses,
  getBasicMapConfirmationStatuses,
  getBasicMapEditions, getDataBasicMapCertification, getDataBasicMapCities,
  getDicCitiesFromArea
} from "@/api/basicMap";
import {BasicMapCity, Certification, GetDataBasicMapCitiesParams} from "@/components/Attributes/BasicMap/types";
import * as Common from '@/utils/common'

type Option = {
  id?: number | string;
  code?: number;
  name: string;
}

type BasicMapStore = {
  updatedCityStatus: Option[];
  fetchUpdatedCityStatus: () => void;
  submissionStatus: Option[];
  fetchSubmissionStatus: () => void;
  certificationStatus: Option[];
  fetchCertificationStatus: () => void;
  dicCities: Option[];
  fetchDicCities: (areaId: number) => void;
  certification: Certification;
  fetchCertification: (data_id: number | undefined) => void;
  basicMapCities: BasicMapCity[];
  fetchBasicMapCities: (params: GetDataBasicMapCitiesParams) => void;
  reset: () => void;
}

const initialState = {
  updatedCityStatus: [],
  submissionStatus: [],
  certificationStatus: [],
  dicCities: [],
  certification: {} as Certification,
  basicMapCities: [],
}

const store = create<BasicMapStore>((set) => ({
  ...initialState,
  fetchUpdatedCityStatus: () => {
    getBasicMapConfirmationStatuses().then((result) => {
      set({updatedCityStatus: result.data.confirmation_statuses})
    });
  },
  fetchSubmissionStatus: () => {
    const kind = 1;
    getBasicMapCertificationStatuses(kind).then((result) => {
      set({submissionStatus: result.data.certification_statuses})
    });
  },
  fetchCertificationStatus: () => {
    const kind = 2;
    getBasicMapCertificationStatuses(kind).then((result) => {
      set({certificationStatus: result.data.certification_statuses})
    });
  },
  fetchDicCities: (areaId) => {
    getDicCitiesFromArea(areaId).then(result => {
      set({dicCities: result.data.cities})
    })
  },
  fetchCertification: (data_id) => {
    getDataBasicMapCertification(data_id).then(result => {
      set({certification: result.data.certifications[0]})
    }).catch(error => {
    })
  },
  fetchBasicMapCities: async (params) => {
    await getDataBasicMapCities(params).then(result => {
      set({basicMapCities: result.data.basic_map_cities})
    })
  },
  reset: () => set(initialState)

}));

export function basicMapStore() {
  return store()
}