import React, {useEffect, useState} from 'react';
import {useBlocker} from 'react-router-dom'
import ConfirmModal from '@/components/ConfirmModal'
import {useUserStore} from '@/stores/user'
import {useBlockerStore} from '@/stores/blocker'

type LeaveModalProps = {
  text?: string,
  onCancel?: () => void;
  onConfirm?: () => void;
};

// 引用: https://stackblitz.com/github/remix-run/react-router/tree/main/examples/navigation-blocking?file=package.json,src%2Fapp.tsx
function BlockModal(props: LeaveModalProps) {
  const [isBlocker, setBlocker] = useBlockerStore(state => [state.isBlocker, state.setBlocker]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocker &&
      currentLocation.pathname !== nextLocation.pathname &&
      nextLocation.pathname !== '/login'
  );
  useEffect(() => {
    if (blocker.state === "blocked" && !isBlocker) {
      blocker.reset();
    }
  }, [blocker, isBlocker]);


  const onClickConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
    blocker.proceed?.();
    setBlocker(false);
  };

  const onClickCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    blocker.reset?.();
  };

  return (
    <ConfirmModal
      text={props.text || "画面遷移しますか？入力したデータは登録されません。"}
      confirmButtonText="OK"
      isShow={blocker.state === "blocked"}
      onConfirm={onClickConfirm}
      onCancel={onClickCancel}
    />
  );
}

export default BlockModal;