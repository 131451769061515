import React from 'react';
import ReactDOM from 'react-dom/client';
import 'uikit/dist/css/uikit.min.css';
import './assets/styles/style.scss';
import 'uikit';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // useBlockが動作しない？
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  // https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
