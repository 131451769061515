import React, {useEffect, useState, ChangeEvent} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {SubmitHandler, useForm} from "react-hook-form";
import {useLoadingStore} from "@/stores/loading";
import {axios} from '@/lib/axios';
import {TextBox} from "@/components/Form/TextBox";
import {TextArea} from "@/components/Form/TextArea";
import {RadioButton} from "@/components/Form/RadioButton";
import ConfirmModal from "@/components/ConfirmModal";

import {
  MESSAGE_NO_E08,
  MESSAGE_NO_E21,
  MESSAGE_NO_E35,
  MESSAGE_NO_E37,
  MESSAGE_NO_E39,
  MESSAGE_NO_E68,
  BUSINESS_ENTITY_TYPE,
  BILLING_POST_TYPE,
  AGREE_CHECK,
  PAYMENT_METHOD,
  USE_METHOD,
  USE_PURPOSE,
  USE_PURPOSE2,
  SYSTEM_STRUCTURE,
  PAYMENT_METHOD_CREDIT_CARD,
  CUSTOMER_PAYMENT_TYPE_FREE,
  BUSINESS_ENTITY_TYPE_CORPORATION,
  CREDIT_STATUS,
  PAYMENT_METHOD_REGISTER_STATUS_COMPLETE,
  CUSTOMER_STATUS_UNAPPROVED,
  CUSTOMER_STATUS_APPROVAL,
  BILLING_POST_TYPE_APPLICANT,
  CUSTOMER_TYPE_ROAD_MANAGER,
  CUSTOMER_TYPE_NOT_ROAD_MANAGER,
  USE_PURPOSE_ROAD_MANAGER_SYSTEM,
  USE_PURPOSE_NOT_ROAD_MANAGER_SYSTEM,
  USE_PURPOSE_ROAD_MANAGER_CONFIRM,
  USE_PURPOSE_OTHER,
  IS_SECONDARY_USE,
} from "@/config";
import {CustomerPaymentTypes, DrmPfInfo} from "@/containers/customer/drmPf/types";
import {useCustomerStore} from "@/stores/customer";

export default function DrmPfEdit() {

  // パラメータ取得
  const location = useLocation();
  const segments = location.pathname.split('/');
  let id = segments[segments.length - 1];
  if (id == 'new') {
    id = '';
  }
  //console.log("id:", id);

  const navigate = useNavigate();

  const setLoading = useLoadingStore(state => state.setLoading);
  const [setDrmPfCommonState, setChildSubmit] = useCustomerStore(state => [state.setDrmPfCommonState, state.setChildSubmit]);

  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [creditCardStatus, setCreditCardStatus] = useState<string | null>(null);
  const [creditStatus, setCreditStatus] = useState<string | null>(null);
  const [secondaryUse, setIsSecondaryUse] = useState<string | null>(null);
  const [useMethod, setUseMethod] = useState<string | null>(null);
  const [drmPfInfo, setDrmPfInfo] = useState<DrmPfInfo>();
  const [startUsingMonth, setStartUsingMonth] = useState<string | null>(null);

  const [customerPaymentTypes, setCustomerPaymentTypes] = useState<CustomerPaymentTypes[]>([]);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
  const [customerNumber, setCustomerNumber] = useState<string | undefined>(undefined);
  const [isBillingVisible, setIsBillingVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState<string | undefined>(undefined);
  const [billingType, setBillingType] = useState<string | null>(null);
  const [businessType, setBusinessType] = useState<string | null>(null);
  const [isCreditCard, setIsCreditCard] = useState(false);
  const [isUsePurposeInHouseDeveloper, setIsUsePurposeInHouseDeveloper] = useState(false);
  const [isSecondaryUseVisble, setIsSecondaryUseVisble] = useState(false);
  const [billingPostTypeVisble, setBillingPostTypeVisble] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {errors}
  } = useForm<DrmPfInfo>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: drmPfInfo,
      values: drmPfInfo,
    }
  );
  const hasErrors = Object.keys(errors).length > 0;

  // customer_payment_type_id フィールドの値を監視
  const customerPaymentTypeId = watch('customer_payment_type_id');
  // business_entity_type フィールドの値を監視
  const businessEntityType = watch('business_entity_type');
  // payment_method フィールドの値を監視
  const paymentMethodNo = watch('payment_method');
  // use_purpose フィールドの値を監視
  const usePurposeNo = watch('use_purpose');
  // is_secondary_use フィールドの値を監視
  const isSecondaryUse = watch('is_secondary_use');
  // billing_post_type フィールドの値を監視
  const billingPostType = watch('billing_post_type');

  // 課金区分
  useEffect(() => {
    setIsBillingVisible(customerPaymentTypeId != null && customerPaymentTypeId != CUSTOMER_PAYMENT_TYPE_FREE); // customer_payment_type_id が 無償 でない場合に表示
  }, [customerPaymentTypeId]);
  // 決済方法
  useEffect(() => {
    setIsCreditCard(paymentMethodNo == PAYMENT_METHOD_CREDIT_CARD); // payment_method が クレジットカード払いの時に表示
  }, [paymentMethodNo]);
  // 事業体区分
  useEffect(() => {
    setIsVisible(businessEntityType == BUSINESS_ENTITY_TYPE_CORPORATION); // business_entity_type が 法人の場合に表示
  }, [businessEntityType]);
  // 利用目的
  useEffect(() => {
    if (usePurposeNo != null) {
      if (usePurposeNo == USE_PURPOSE_ROAD_MANAGER_SYSTEM ||
        usePurposeNo == USE_PURPOSE_NOT_ROAD_MANAGER_SYSTEM ||
        usePurposeNo == USE_PURPOSE_ROAD_MANAGER_CONFIRM ||
        usePurposeNo == USE_PURPOSE_OTHER) {
        setIsUsePurposeInHouseDeveloper(false);
      } else {
        setIsUsePurposeInHouseDeveloper(true);
      }
    } else {
      setIsUsePurposeInHouseDeveloper(false);
    }
  }, [usePurposeNo]);
  // 二次利用
  useEffect(() => {
    setIsSecondaryUseVisble(isSecondaryUse == IS_SECONDARY_USE); // is_secondary_use が 利用する場合に表示
  }, [isSecondaryUse]);
  // 請求先
  useEffect(() => {
    setBillingPostTypeVisble(billingPostType != null && billingPostType != BILLING_POST_TYPE_APPLICANT);
  }, [billingPostType]);


  const onSubmit: SubmitHandler<DrmPfInfo> = (data) => {
    setEmailErrorMessage(null);
    console.log(data);
    setLoading(true)
    axios.post('/api/v1/customers/setDrmPfBaseData', data)
      .then(result => {
        console.log(result);
        console.log("登録しました")
        navigate(-1)
      }).catch(error => {
      console.log("失敗しました");
      if (error.response) {
        if (error.response.data['message'] == MESSAGE_NO_E21) {
          setEmailErrorMessage(MESSAGE_NO_E21);
        } else {
          setErrorMessage(MESSAGE_NO_E37);
        }
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  // 郵便番号⇒住所検索
  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newZipcode = e.target.value;

    // APIリクエストを送信
    axios.get(`/api/v1/proxy/getAddress?zipcode=${newZipcode}`)
      .then(response => {
        const results = response.data.results;
        console.log("----- zipcode search ------");
        console.log(results);
        if (results) {
          setValue('billing_post_pref', results[0].address1);
          setValue('billing_post_city', results[0].address2);
          setValue('billing_post_house_number', results[0].address3);
          setValue('billing_post_building_name', '');
        } else {
          alert('該当する住所が見つかりませんでした');
        }
      })
      .catch(error => {
        console.error('Error fetching address:', error);
      });
  };

  useEffect(() => {
    axios.get('/api/v1/customers/getCustomerTypes')
      .then(result => {
        setCustomerPaymentTypes(result.data.customerPaymentTypes);
      }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    axios.get('/api/v1/customers/getDrmPfBaseData', {
      params: {
        id: id,
      }
    })
      .then(result => {
        //console.log(result.data);
        let drmpf = result.data.drmpf[0];
        if (drmpf.customer_payment_type_id != null) {
          drmpf.customer_payment_type_id = drmpf.customer_payment_type_id.toString();
        }
        if (drmpf.payment_method != null) {
          drmpf.payment_method = drmpf.payment_method.toString();
        }
        if (drmpf.secondary_use_method != null) {
          drmpf.secondary_use_method = drmpf.secondary_use_method.toString();
        }
        if (drmpf.start_using_month != null) {
          drmpf.start_using_month = drmpf.start_using_month.substring(0, 7);
        }
        if (drmpf.business_entity_type != null) {
          drmpf.business_entity_type = drmpf.business_entity_type.toString()
        }
        setDrmPfInfo(drmpf);
        setDrmPfCommonState({
          customerNumber: drmpf.customer_number,
          modified: drmpf.modified,
          modified_by: drmpf.modified_by
        });
        setApprovalStatus(result.data.drmpf[0]['approval_status']);
        if (result.data.drmpf[0]['customer_number'] != null) {
          setCustomerNumber(result.data.drmpf[0]['customer_number']);
        } else {
          setCustomerNumber("");
        }
        // 決済方法
        if (result.data.drmpf[0]['payment_method'] != null) {
          const payment_method = PAYMENT_METHOD.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['payment_method']);
          });
          setPaymentMethod(payment_method[0]['label']);
        } else {
          setPaymentMethod('');
        }
        // クレジットカード登録状況
        if (result.data.drmpf[0]['payment_method'] == PAYMENT_METHOD_CREDIT_CARD) {
          if (result.data.drmpf[0]['payment_method_register_status'] == PAYMENT_METHOD_REGISTER_STATUS_COMPLETE) {
            setCreditCardStatus('登録完了');
          } else {
            setCreditCardStatus('未登録');
          }
        } else {
          setCreditCardStatus('-');
        }
        // 与信審査
        if (result.data.drmpf[0]['company_credit_status'] != null) {
          const credit_status = CREDIT_STATUS.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['company_credit_status']);
          });
          setCreditStatus(credit_status[0]['label']);
        } else {
          setCreditStatus('-');
        }
        // 請求先
        if (result.data.drmpf[0]['billing_post_type'] != null) {
          const billing_type = BILLING_POST_TYPE.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['billing_post_type']);
          })
          setBillingType(billing_type[0]['label']);
        } else {
          setBillingType('');
        }
        // 事業所区分
        if (result.data.drmpf[0]['business_entity_type'] != null) {
          const entry_type = BUSINESS_ENTITY_TYPE.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['business_entity_type']);
          })
          setBusinessType(entry_type[0]['label']);
        } else {
          setBusinessType('');
        }
        // 利用開始月
        if (result.data.drmpf[0]['start_using_month'] != null) {
          const start_using_month = new Date(result.data.drmpf[0]['start_using_month']);
          setStartUsingMonth(`${start_using_month.getFullYear()}年${start_using_month.getMonth() + 1}月`);
        } else {
          setStartUsingMonth('');
        }
        // DRM-PFの二次利用
        if (result.data.drmpf[0]['is_secondary_use'] != null) {
          if (result.data.drmpf[0]['is_secondary_use'] == IS_SECONDARY_USE) {
            setIsSecondaryUse('1.利用する');
          } else {
            setIsSecondaryUse('0.利用しない');
          }
        } else {
          setIsSecondaryUse('0.利用しない');
        }
        // 二次利用方法
        if (result.data.drmpf[0]['secondary_use_method'] != null) {
          const use_method = USE_METHOD.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['secondary_use_method']);
          });
          setUseMethod(use_method[0]['label']);
        } else {
          setUseMethod('');
        }
        // 二次利用
        setIsSecondaryUseVisble(result.data.drmpf[0]['is_secondary_use'] == IS_SECONDARY_USE); // is_secondary_use が 利用する場合に表示

      }).catch(error => {
      alert(error.message);
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    setChildSubmit(() => handleSubmit(onSubmit)())
    return () => setChildSubmit(() => {
    });
  }, [setChildSubmit]);

  return (
    <>
      <div className="info-tbl_wrap uk-margin-small-top">
        <form>
          <div className="precaution">※は必須です</div>

          <table className="customer-info-tbl uk-width-1-1">
            <tbody>
            <tr>
              <th className="label">課金区分<span className="precaution">※</span></th>
              <td className="content">
                <div className="uk-child-width-auto uk-grid">
                  {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                    <>
                      {customerPaymentTypes.map((option, index) => (
                        <label key={index}>
                          <div>
                            <input
                              type="radio"
                              value={option.value}
                              {...register('customer_payment_type_id', {required: MESSAGE_NO_E08})}
                              //onChange={handlePaymentTypeChange} // ラジオボタンの値が変更された時
                            />
                          </div>
                          {option.label}
                        </label>
                      ))}
                    </>
                  )}

                  {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                    <>
                      <div>
                        {drmPfInfo?.customer_payment_type_id}.{drmPfInfo?.customer_payment_type}
                      </div>
                    </>
                  )}
                </div>
                {errors.customer_payment_type_id &&
                    <p className="precaution">{errors.customer_payment_type_id.message}</p>}
              </td>
            </tr>
            <tr>
              <th className="label">承認</th>
              <td className="content">
                <div className="uk-child-width-auto uk-grid">
                  {approvalStatus == CUSTOMER_STATUS_UNAPPROVED ? (
                    <span>1.未承認</span>
                  ) : approvalStatus == CUSTOMER_STATUS_APPROVAL ? (
                    <span>2.承認済</span>
                  ) : null}
                </div>
              </td>
            </tr>
            {isBillingVisible && ( // 無償以外
              <>
                <tr>
                  <th className="label">決済方法<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="uk-child-width-auto uk-grid">
                      {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (  // 承認前
                        <>
                          <RadioButton
                            name="payment_method"
                            options={PAYMENT_METHOD}
                            register={register}
                            rules={{required: MESSAGE_NO_E08}}
                            errors={errors}
                            //onChange={handlePaymentMethodChange}  // ラジオボタンが変更された時
                          />
                        </>
                      )}

                      {approvalStatus == CUSTOMER_STATUS_APPROVAL && (  // 承認後
                        <>
                          <div>
                            {paymentMethod}
                          </div>
                        </>
                      )}
                    </div>
                    {errors.payment_method && <p className="precaution">{errors.payment_method.message}</p>}
                  </td>
                </tr>
                {isCreditCard && (
                  <>
                    <tr>
                      <th className="label">クレジットカード登録状況</th>
                      <td className="content">{creditCardStatus}</td>
                    </tr>
                  </>
                )}
                {!isCreditCard && (
                  <>
                    <tr>
                      <th className="label">与信審査</th>
                      <td className="content">{creditStatus}</td>
                    </tr>
                  </>
                )}
              </>
            )}
            <tr>
              <th className="label">利用目的<span className="precaution">※</span></th>
              <td className="content">
                <div className="uk-child-width-auto uk-grid">
                  {drmPfInfo?.customer_type_id == CUSTOMER_TYPE_ROAD_MANAGER ? (
                    <RadioButton
                      name="use_purpose"
                      options={USE_PURPOSE}
                      register={register}
                      rules={{required: MESSAGE_NO_E08}}
                      errors={errors}
                    />
                  ) : drmPfInfo?.customer_type_id == CUSTOMER_TYPE_NOT_ROAD_MANAGER ? (
                    <RadioButton
                      name="use_purpose"
                      options={USE_PURPOSE2}
                      register={register}
                      rules={{required: MESSAGE_NO_E08}}
                      errors={errors}
                    />
                  ) : null}
                </div>
                {errors.use_purpose && <p className="precaution">{errors.use_purpose.message}</p>}
              </td>
            </tr>
            {drmPfInfo?.customer_type_id == CUSTOMER_TYPE_NOT_ROAD_MANAGER && isUsePurposeInHouseDeveloper && ( // 道路管理者以外＆[5.自社開発のシステム開発の場合]
              <>
                <tr>
                  <th className="label">システム体系<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="uk-child-width-auto uk-grid">
                      <RadioButton
                        name="system_structure"
                        options={SYSTEM_STRUCTURE}
                        register={register}
                        rules={{required: MESSAGE_NO_E08}}
                        errors={errors}
                      />
                    </div>
                    {errors.system_structure && <p className="precaution">{errors.system_structure.message}</p>}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th className="label height-5rem">利用目的詳細<span className="precaution">※</span></th>
              <td className="content">
                <div className="uk-flex">
                  <TextArea
                    name="use_purpose_detail"
                    register={register}
                    rules={{
                      required: MESSAGE_NO_E08,
                      maxLength: {value: 200, message: MESSAGE_NO_E35(200)},
                    }}
                    errors={errors}
                    placeholder={"利用目的詳細を入力してください"}
                  />
                </div>
                {errors.use_purpose_detail && <p className="precaution">{errors.use_purpose_detail.message}</p>}
              </td>
            </tr>
            <tr>
              <th className="label">利用終了日</th>
              <td className="content">
                <div className="width-30p uk-flex">
                  <input
                    {...register('end_using_date')}
                    name="end_using_date"
                    type="date"
                    className="uk-width-1-1"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="label">DRM-PFの二次利用</th>
              <td className="content">
                {approvalStatus == CUSTOMER_STATUS_UNAPPROVED ? (  // 承認前
                  <label>
                    <input
                      {...register('is_secondary_use')}
                      name="is_secondary_use"
                      type="checkbox"
                      value="1"
                      defaultChecked={drmPfInfo?.is_secondary_use == '1'} // 1の場合にチェックを付ける
                    />
                    1.利用する
                  </label>
                ) : approvalStatus == CUSTOMER_STATUS_APPROVAL ? (
                  <span>{secondaryUse}</span>
                ) : null}
              </td>
            </tr>
            {isSecondaryUseVisble && (
              <>
                <tr>
                  <th className="label">二次利用方法<span className="precaution">※</span></th>
                  <td className="content">
                    {approvalStatus == CUSTOMER_STATUS_UNAPPROVED ? (  // 承認前
                      <div className="uk-child-width-auto uk-grid">
                        <RadioButton
                          name="secondary_use_method"
                          options={USE_METHOD}
                          register={register}
                          rules={{required: MESSAGE_NO_E08}}
                          errors={errors}
                        />
                      </div>
                    ) : approvalStatus == CUSTOMER_STATUS_APPROVAL ? (
                      <span>{useMethod}</span>
                    ) : null}
                    {errors.secondary_use_method && <p className="precaution">{errors.secondary_use_method.message}</p>}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th className="label">利用申請日<span className="precaution">※</span></th>
              <td className="content">
                <div className="width-30p uk-flex">
                  <input
                    {...register('apply_using_date', {required: MESSAGE_NO_E08})}
                    name="apply_using_date"
                    type="date"
                    className="uk-width-1-1"
                  />
                </div>
                {errors.apply_using_date && <p className="precaution">{errors.apply_using_date.message}</p>}
              </td>
            </tr>
            <tr>
              <th className="label">利用開始月<br/>※長期割引の起点月<span className="precaution">※</span></th>
              <td className="content">
                <div className="width-30p uk-flex">
                  {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                    <>
                      <input
                        {...register('start_using_month', {required: MESSAGE_NO_E08})}
                        name="start_using_month"
                        type="month"
                        className="uk-width-1-1"
                      />
                    </>
                  )}
                  {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                    <>
                      {startUsingMonth}
                    </>
                  )}
                </div>
                {errors.start_using_month && <p className="precaution">{errors.start_using_month.message}</p>}
              </td>
            </tr>
            {isBillingVisible && ( // 無償以外
              <>
                <tr>
                  <th className="label">請求先コード</th>
                  <td className="content">{drmPfInfo?.billing_code}</td>
                </tr>
                <tr>
                  <th className="label">請求先<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="uk-child-width-auto uk-grid">
                      {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                        <>
                          <RadioButton
                            name="billing_post_type"
                            options={BILLING_POST_TYPE}
                            register={register}
                            rules={{required: MESSAGE_NO_E08}}
                            errors={errors}
                          />
                        </>
                      )}
                      {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                        <>
                          <div>
                            {billingType}
                          </div>
                        </>
                      )}
                    </div>
                    {errors.billing_post_type && <p className="precaution">{errors.billing_post_type.message}</p>}
                  </td>
                </tr>
                {billingPostTypeVisble && ( // 請求先（申込者以外）
                  <>
                    <tr>
                      <th className="label">請求先 - 法人・団体名<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                            <>
                              <TextBox
                                name="billing_post_group_name"
                                register={register}
                                rules={{
                                  required: MESSAGE_NO_E08,
                                  maxLength: {value: 100, message: MESSAGE_NO_E35(100)},
                                }}
                                errors={errors}
                                placeholder={"請求先 - 法人・団体名を入力してください"}
                              />
                            </>
                          )}
                          {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                            <>
                              {drmPfInfo?.billing_post_group_name}
                            </>
                          )}
                        </div>
                        {errors.billing_post_group_name &&
                            <p className="precaution">{errors.billing_post_group_name.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 部署名・所属<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_department_name"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 100, message: MESSAGE_NO_E35(100)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 部署名・所属を入力してください"}
                          />
                        </div>
                        {errors.billing_post_department_name &&
                            <p className="precaution">{errors.billing_post_department_name.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 姓<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_last_name"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 30, message: MESSAGE_NO_E35(30)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 姓を入力してください"}
                          />
                        </div>
                        {errors.billing_post_last_name &&
                            <p className="precaution">{errors.billing_post_last_name.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 名<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_first_name"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 30, message: MESSAGE_NO_E35(30)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 名を入力してください"}
                          />
                        </div>
                        {errors.billing_post_first_name &&
                            <p className="precaution">{errors.billing_post_first_name.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 電話番号<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_phone_number"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 20, message: MESSAGE_NO_E35(20)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 電話番号を入力してください"}
                          />
                        </div>
                        {errors.billing_post_phone_number &&
                            <p className="precaution">{errors.billing_post_phone_number.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 郵便番号<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <input
                            {...register('billing_post_zipcode', {
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 7, message: MESSAGE_NO_E35(7)},
                            })}
                            type="text"
                            name="billing_post_zipcode"
                            placeholder={"郵便番号を入力してください"}
                            onBlur={handleZipcodeChange}
                          />
                        </div>
                        {errors.billing_post_zipcode &&
                            <p className="precaution">{errors.billing_post_zipcode.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 都道府県<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_pref"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 4, message: MESSAGE_NO_E35(4)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 都道府県を入力してください"}
                          />
                        </div>
                        {errors.billing_post_pref && <p className="precaution">{errors.billing_post_pref.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 市区町村<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_city"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 56, message: MESSAGE_NO_E35(56)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 市区町村を入力してください"}
                          />
                        </div>
                        {errors.billing_post_city && <p className="precaution">{errors.billing_post_city.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 町名番地<span className="precaution">※</span></th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_house_number"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 56, message: MESSAGE_NO_E35(56)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 町名番地を入力してください"}
                          />
                        </div>
                        {errors.billing_post_house_number &&
                            <p className="precaution">{errors.billing_post_house_number.message}</p>}
                      </td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 建物名・部屋番号</th>
                      <td className="content">
                        <div className="width-40p uk-flex">
                          <TextBox
                            name="billing_post_building_name"
                            register={register}
                            rules={{
                              maxLength: {value: 60, message: MESSAGE_NO_E35(60)},
                            }}
                            errors={errors}
                            placeholder={"請求先 - 建物名・部屋番号を入力してください"}
                          />
                        </div>
                        {errors.billing_post_building_name &&
                            <p className="precaution">{errors.billing_post_building_name.message}</p>}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <th className="label">事業体区分<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="uk-child-width-auto uk-grid">
                      {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                        <>
                          {BUSINESS_ENTITY_TYPE.map((option, index) => (
                            <label key={index}>
                              <div>
                                <input
                                  type="radio"
                                  value={option.value}
                                  {...register('business_entity_type', {required: MESSAGE_NO_E08})}
                                  //onChange={handleBusinessEntityTypeChange} // ラジオボタンの値が変更された時
                                />
                              </div>
                              {option.label}
                            </label>
                          ))}
                        </>
                      )}
                      {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                        <>
                          <div>
                            {businessType}
                          </div>
                        </>
                      )}
                    </div>
                    {errors.business_entity_type && <p className="precaution">{errors.business_entity_type.message}</p>}
                  </td>
                </tr>
              </>
            )}
            {isBillingVisible && isVisible && ( // 法人
              <>
                <tr>
                  <th className="label">法人番号<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="width-40p uk-flex">
                      {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                        <>
                          <TextBox
                            name="corporate_number"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              minLength: {value: 13, message: MESSAGE_NO_E68(13)},
                              maxLength: {value: 13, message: MESSAGE_NO_E68(13)},
                            }}
                            errors={errors}
                            placeholder={"法人番号を入力してください"}
                          />
                        </>
                      )}
                      {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                        <>
                          <div>
                            {drmPfInfo?.corporate_number}
                          </div>
                        </>
                      )}
                    </div>
                    {errors.corporate_number && <p className="precaution">{errors.corporate_number.message}</p>}
                  </td>
                </tr>
                <tr>
                  <th className="label">会社代表者名<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="width-40p uk-flex">
                      {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                        <>
                          <TextBox
                            name="company_representative_name"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 40, message: MESSAGE_NO_E35(40)},
                            }}
                            errors={errors}
                            placeholder={"会社代表者名を入力してください"}
                          />
                        </>
                      )}
                      {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                        <>
                          <div>
                            {drmPfInfo?.company_representative_name}
                          </div>
                        </>
                      )}
                    </div>
                    {errors.company_representative_name &&
                        <p className="precaution">{errors.company_representative_name.message}</p>}
                  </td>
                </tr>
                <tr>
                  <th className="label">会社URL<span className="precaution">※</span></th>
                  <td className="content">
                    <div className="width-40p uk-flex">
                      {approvalStatus == CUSTOMER_STATUS_UNAPPROVED && (
                        <>
                          <TextBox
                            name="company_url"
                            register={register}
                            rules={{
                              required: MESSAGE_NO_E08,
                              maxLength: {value: 200, message: MESSAGE_NO_E35(200)},
                            }}
                            errors={errors}
                            placeholder={"会社URLを入力してください"}
                          />
                        </>
                      )}
                      {approvalStatus == CUSTOMER_STATUS_APPROVAL && (
                        <>
                          <div>
                            {drmPfInfo?.company_url}
                          </div>
                        </>
                      )}
                    </div>
                    {errors.company_url && <p className="precaution">{errors.company_url.message}</p>}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th className="label">利用規約<span className="precaution">※</span></th>
              <td className="content">
                <label>
                  <input
                    {...register('is_terms_use_agree', {required: MESSAGE_NO_E08})}
                    className="uk-margin-small-right"
                    type="checkbox"
                    name="is_terms_use_agree"
                    value={AGREE_CHECK}
                  />1.同意する
                </label>
                {errors.is_terms_use_agree && <p className="precaution">{errors.is_terms_use_agree.message}</p>}
              </td>
            </tr>
            {isBillingVisible && ( // 無償以外
              <>
                <tr>
                  <th className="label">請求業務の委託</th>
                  <td className="content">
                    <label>
                      <input
                        {...register('is_billing_outsourcing', {required: MESSAGE_NO_E08})}
                        className="uk-margin-small-right"
                        type="checkbox"
                        name="is_billing_outsourcing"
                        value={AGREE_CHECK}
                      />1.同意する
                    </label>
                    {errors.is_billing_outsourcing &&
                        <p className="precaution">{errors.is_billing_outsourcing.message}</p>}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th className="label height-5rem">特記事項</th>
              <td className="content">
                <div className="uk-flex">
                  <TextArea
                    name="special_notes"
                    register={register}
                    rules={{
                      maxLength: {value: 500, message: MESSAGE_NO_E35(500)},
                    }}
                    errors={errors}
                    placeholder={"備考（内部用）を入力してください"}
                  />
                </div>
                {errors.special_notes && <p className="precaution">{errors.special_notes.message}</p>}
              </td>
            </tr>
            </tbody>
          </table>
          <table className="customer-info-tbl uk-width-1-1">
            <tbody>
            <tr>
              <th className="label height-5rem">備考（内部用）</th>
              <td className="content">
                <div className="uk-flex">
                  <TextArea
                    name="remarks"
                    register={register}
                    rules={{
                      maxLength: {value: 500, message: MESSAGE_NO_E35(500)},
                    }}
                    errors={errors}
                    placeholder={"備考（内部用）を入力してください"}
                  />
                </div>
                {errors.remarks && <p className="precaution">{errors.remarks.message}</p>}
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
    </>
  );
}
