import React, {useEffect, useState} from "react";
import {basicMapStore} from "@/stores/basicMap";
import {GetDataBasicMapCitiesParams} from "@/components/Attributes/BasicMap/types";
import {useBasicMap} from "@/utils/useBasicMap";
import * as Common from "@/utils/common";

interface Props {
  GetDataBasicMapCitiesParams: GetDataBasicMapCitiesParams;
  attribute: any;
  areaId?: number;
}

export const DetailUpdatedMunicipalitiesContainer = (props: Props) => {
  const {
    fetchBasicMapCities,
    basicMapCities,
    fetchDicCities,
    dicCities,
    fetchUpdatedCityStatus,
    updatedCityStatus,
    reset
  } = basicMapStore();
  const {findBasicMapOption} = useBasicMap();

  const [open, setOpen] = useState(false);
  const dataAttributeValueId = props.GetDataBasicMapCitiesParams.data_attribute_value_id;

  //更新市町村マスタデータ取得
  useEffect(() => {
    fetchUpdatedCityStatus();
    return () => {
      reset();
    }
  }, [])

  //data_attribute_value_id取得後市町村取得
  useEffect(() => {
    if (dataAttributeValueId) {
      fetchBasicMapCities(props.GetDataBasicMapCitiesParams);
    }
  }, [dataAttributeValueId]);

  //エリアid変更で市町村マスタ取得
  useEffect(() => {
    if (props.areaId) {
      fetchDicCities(Number(props.areaId));
    }
  }, [props.areaId]);

  if (!dicCities) return null;

  return (
    <tr className="updated-municipalities-container uk-margin-small-top toggle-wrapper">
      <td colSpan={2} className="toggle-area">
        <div className="uk-text-right">
          <button type="button"
                  className="uk-button uk-button-text uk-text-primary"
                  onClick={() => setOpen((prev) => !prev)}
          >
            {open ? "▲" : "▼"} 市区町村内訳
          </button>
        </div>
        <div className={`toggle-contents uk-overflow-auto ${open ? "visible" : "hidden"}`}>
          <table className="uk-table-small mt-5 mb-5 uk-table-middle">
            <thead>
            <tr>
              <th className="uk-text-center">
                <div className="border-box"/>
                更新対象市区町村名
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                作業担当
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                作成日時
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                都市部（㎢）
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                平野部（㎢）
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                その他（㎢）
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                ステータス
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                最終更新担当
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                最終更新日時
              </th>
              <th className="uk-text-center border-right-2">
                <div className="border-box"/>
                備考
              </th>
            </tr>
            </thead>
            <tbody>
            {basicMapCities?.map((city, index) => {
              return (
                <tr key={index}>
                  <td className="uk-text-center">{city.update_target_city_name}</td>
                  <td className="uk-text-center">{city.created_name}</td>
                  <td className="uk-text-center">{city?.created ? Common.formatDate(String(city?.created)) : ""}</td>
                  <td className="uk-text-center">{city.urban_area}</td>
                  <td className="uk-text-center">{city.plain_area}</td>
                  <td className="uk-text-center">{city.other_area}</td>
                  <td className="uk-text-center">{findBasicMapOption(Number(city?.status), updatedCityStatus)}</td>
                  <td className="uk-text-center">{city.created_name}</td>
                  <td className="uk-text-center">{city?.created ? Common.formatDate(String(city?.modified)) : ""}</td>
                  <td className="uk-text-center">{city.remarks}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  )
}