import React, { useCallback, useEffect, useState } from 'react';
import CustomSelect from '@/components/CustomSelect';
import { useLoadingStore } from "@/stores/loading";
import { importCsv } from "@/api/data";
import { ERROR_MESSAGE_E23, MESSAGE_NO_E39, SUCCESS_MESSAGE_E24 } from "@/config";
import ConfirmModal from "@/components/ConfirmModal";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {axios} from '@/lib/axios'
import {Tooltip} from 'react-tooltip'
import moment from 'moment'
import * as Config from '@/config'

interface error {
  file?: string,
}

export default function DicDataImport() {
  const setLoading = useLoadingStore(state => state.setLoading);

  const [isShowModal, setIsShowModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<error | null>(null);
  const [checkMessage, setCheckMessage] = useState<string | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [tmpPath, setTmpPath] = useState<string | null>(null);
  const [outPutList, setOutPutList] = useState<Array<any>>([]);

  useEffect(() => {
    getList()
    return () => {
    };
  }, []);

  function getList() {
    axios.get('/api/v1/dicImport/list', {}).then(result => {
      const outputs = result.data.dataDicImports;
      setOutPutList(outputs);
    }).catch(error => {
      setResultMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
    });
  }


  const onDrop = useCallback(( files: File[] ) => {
    if (files.length > 0) {
      setFile(files[0]);
      setCheckMessage(null);
      setTmpPath(null);
    }
  }, []);
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    // accept: { 'text/csv': ['.csv'] },
    multiple: false,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const handleFileImportChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if (e.target.files) {
      setFile(e.target.files[0])
      setCheckMessage(null);
      setTmpPath(null);
    }
  }

  const onClickClear = () => {
    setFile(null);
    setResultMessage(null);
    setCheckMessage(null);
    setTmpPath(null);
  }

  const onClickImportCheck = (e: any) => {
    setResultMessage(null);
    setCheckMessage(null);
    let messages: any = {}
    if (!file) {
      messages["file"] = "必須項目です。";
    }
    if (!file) {
      e.preventDefault();
      setError(messages);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    axios.post('/api/v1/dicImport/importCheck', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then(( result ) => {
      setTmpPath(result.data.tmpPath);
      // console.log(result);
    }).catch(( error ) => {
      setTmpPath(null);
      // console.log(error);
      if (!error.response) {
        setResultMessage(MESSAGE_NO_E39)
        return;
      }
      if (error.response.data["message"]) {
        setCheckMessage(error.response.data.message);
      } else {
        setResultMessage(ERROR_MESSAGE_E23);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const onClickImport = async ( e: any ) => {
    setLoading(true);
    axios.post('/api/v1/dicImport/import', {
      tmpPath: tmpPath,
      name: file?.name
    }).then(( result ) => {
      // setTmpPath(null);
      setResultMessage(SUCCESS_MESSAGE_E24);
    }).catch(( error ) => {
      console.log(error);
      if (!error.response) {
        setResultMessage(MESSAGE_NO_E39)
        return;
      }
      if (error.response.data["message"]) {
        setResultMessage(error.response.data.message);
      } else {
        setResultMessage(ERROR_MESSAGE_E23);
      }
    }).finally(() => {
      getList()
      setTmpPath(null);
      setLoading(false);
    });
  }

  useEffect(() => {
    resultMessage && setIsShowModal(true);
  }, [resultMessage])

  return (
    <div>
      <div className="precaution">※は必須です</div>
      <form>
        <div className="admin-tbl uk-margin-small-top">
          <table className="uk-width-1-1">
            <tr>
              <td>
                ファイル選択<span className="error">※</span>
                {error?.file && <span className="precaution">{error.file}</span>}
              </td>
              <td>
                <div
                  {...getRootProps()}
                  className="file-select uk-form-custom"
                >
                  <input
                    type="file"
                    {...getInputProps()}
                    onChange={handleFileImportChange}
                  />
                  <button
                    className="uk-button--m uk-button-cancel"
                    type="button"
                  >
                    ファイル選択
                  </button>
                  <div>{file?.name}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        {checkMessage &&
            <div className="uk-flex uk-flex-center">
                <span className="precaution">{checkMessage}</span>
            </div>
        }
      </form>
      <div className="uk-flex uk-flex-center uk-margin-top">
        <button className="uk-button--m uk-button-cancel" onClick={onClickClear}>クリア</button>
        <button className="uk-button--w uk-button-white uk-margin-left" onClick={onClickImportCheck}>インポートチェック</button>
        <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickImport} disabled={!tmpPath}>インポート</button>
      </div>
      <OutputListContainer outPutList={outPutList} />
      {resultMessage &&
          <ConfirmModal
              text={resultMessage}
              confirmButtonText="OK"
              isShow={isShowModal}
              onConfirm={() => {
                setIsShowModal(false);
                setResultMessage(null);
              }}
          />
      }
    </div>
  );
}

interface OutputListContainerProps {
  outPutList: Array<any>,
}

const OutputListContainer = ( props: OutputListContainerProps ) => {

  return (
    <div className="uk-margin-top">
      <Tooltip id="report-tooltip" style={{ zIndex: 3 }}/>
      <table className="report-tbl">
        <thead>
        <tr className="header">
          <th>登録日時</th>
          <th>グループ名</th>
          <th>登録者</th>
          <th>ファイル名</th>
          <th>ステータス</th>
        </tr>
        </thead>
        <tbody>
        {props.outPutList.map((( data: any ) => {
          return (
            <tr>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={moment(data.created).format(Config.DATE_FORMAT)}>
                {moment(data.created).format(Config.DATE_FORMAT)}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.created_user.group.name}>
                {data.created_user.group.name}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={`${data.created_user.last_name}${data.created_user.first_name}`}>
                {`${data.created_user.last_name}${data.created_user.first_name}`}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.name}>
                {data.name}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.status}>
                {Config.REPORT_OUTPUTS_STATUSES[data.status]}
              </td>
            </tr>
          )
        }))}
        </tbody>
      </table>
    </div>
  )
}