import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataProps} from '@/containers/data/Data'
import * as Config from '@/config'
import ApproveDetail from './ApproveDetail';
import ApproveEdit from './ApproveEdit';

export default function Approve(props: DataProps) {
  const navigate = useNavigate();

  switch (props.isType) {
    case Config.Data.ApproveDetail:
      return <ApproveDetail />
    case Config.Data.ApproveEdit:
      return <ApproveEdit />
    default:
      return null;
  }
}