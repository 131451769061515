import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import ConfirmModal from '@/components/ConfirmModal';
import {axios} from '@/lib/axios'
import * as Config from '@/config'
import * as Common from '@/utils/common'
import {useLoadingStore} from '@/stores/loading'
import {useProjectStore} from '@/stores/project'
import moment from 'moment'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent, MouseSensor
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useUserStore} from '@/stores/user'
import {useMessageModalStore} from '@/stores/message'

export default function FileList() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const sensors = useSensors(
    // useSensor(PointerSensor),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // }),
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
  );
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const user = useUserStore(state => state.user);

  const headerTop = (document.querySelector("header")?.offsetHeight ?? 0)
    + (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);

  const [dataDocuments, setDataDocuments] = useState<any[]>([]);
  const [paging, setPaging] = useState<any>(undefined);

  function getDataDocuments(page=1) {
    setLoading(true);
    axios.get('/api/v1/dataDocuments/list', {
      params: {
        page: page,
      }
    }).then(result => {
      const dataDocuments = result.data.dataDocuments;
      const s3Urls = result.data.s3Urls;
      for (let i = 0; i < dataDocuments.length; i++) {
        dataDocuments[i]["s3FilePath"] = s3Urls[dataDocuments[i]["id"]]["filepath"];
      }
      setDataDocuments(dataDocuments);
      setPaging(result.data.paging);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function deleteById(id: number) {
    setLoading(true);
    axios.post('/api/v1/dataDocuments/delete', {
      id: id,
    }).then(result => {
      getDataDocuments();
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function moveDisplayNo(id: any, target_display_no: any, update: boolean = false) {
    setLoading(true);
    axios.post('/api/v1/dataDocuments/moveDisplayNo', {
      id: id,
      target_display_no: target_display_no,
    }).then(result => {
      if (update) {
        getDataDocuments();
      }
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getDataDocuments();
  }, [location.pathname]);
  
  const onClickNew = () => {
    navigate('/admin/document/new');
  }

  const onEdit = (id: number) => {
    navigate(`/admin/document/edit/${id}`);
  }

  const onDelete = (id: number) => {
    setModalConfirm({callback: () => deleteById(id)});
  }

  const onPageChange = (page: number) => {
    getDataDocuments(page);
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      setDataDocuments((dataDocuments) => {
        const oldIndex = dataDocuments.findIndex(dataDocument => dataDocument.id === active.id);
        const newIndex = dataDocuments.findIndex(dataDocument => dataDocument.id === over.id);
        const newDataDocuments = arrayMove(dataDocuments, oldIndex, newIndex);
        const [start, end] = oldIndex < newIndex ? [oldIndex, newIndex] : [newIndex, oldIndex];

        // arrayMoveはオブジェクトの更新ではないので上書きになってしまう
        // for(let i = start; i <= end; i++) {
        //   newDataDocuments[i].display_no = dataDocuments[i].display_no;
        // }
        const displayNo = dataDocuments.slice(start, end + 1).map(dataDocument => dataDocument.display_no);
        newDataDocuments.slice(start, end + 1).forEach((dataDocument, index) => {
          dataDocument.display_no = displayNo[index];
        });
        moveDisplayNo(newDataDocuments[newIndex].id, newDataDocuments[newIndex].display_no);
        return newDataDocuments;
      });
    }
  }

  return (
    <>
      <div className="sub-header" style={{top: headerTop}}>
        <div className="uk-flex-between uk-flex uk-flex-middle uk-margin-small-top">
          <div className="uk-inline">
            <span className="uk-form-icon uk-form-icon-flip" uk-icon="chevron-down"></span>
          </div>
          <div>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成</button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="file-tbl-wrap file-tbl-separate uk-margin-small-top">
          <div className="file-tbl">

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={dataDocuments}
                strategy={verticalListSortingStrategy}
              >
                <table>
                  {dataDocuments.map((dataDocument, index) => {
                    return (<FileRow index={index} dataDocument={dataDocument} onEdit={onEdit} onDelete={onDelete} moveDisplayNo={moveDisplayNo} />);
                  })}
                </table>
              </SortableContext>
            </DndContext>
          </div>
        </div>
        {paging &&
          <>
              <div className="uk-text-center mt-5">全{paging.count}件（{paging.page}/{paging.pageCount}）</div>

              <div className="pagination-container">
                  <a onClick={() => {onPageChange(1)}}>FIRST</a>
                  <ReactPaginate
                      forcePage={paging.page - 1}
                      onPageChange={(selectedItem: {selected: number}) => onPageChange(selectedItem.selected + 1)}
                      pageRangeDisplayed={5}
                      pageCount={paging.pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      previousLabel='<'
                      nextLabel='>'
                      breakLabel='...'
                  />
                  <a onClick={() => {onPageChange(paging.pageCount)}}>LAST</a>
              </div>
          </>
        }
      </div>
      <ConfirmModal
        text={Config.MESSAGE_NO_E11}
        confirmButtonText="削除"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if(modalConfirm)modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </>
  );
}

interface FileRowProps {
  index: number
  dataDocument: any,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  moveDisplayNo: (id: any, target_display_no: any, update: boolean) => void,
}
const FileRow = ({index, dataDocument, onEdit, onDelete, moveDisplayNo}: FileRowProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({ id: dataDocument.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [targetDisplayNo, setTargetDisplayNo] = useState('');

  return (
    <>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th rowSpan={5} className={`border-left ${index === 0 ? "border-top" : ""}`}>
          {dataDocument.display_no}
        </th>
        <th className={`label ${index === 0 ? "border-top" : ""}`}>ファイル名</th>
        <td className={`content ${index === 0 ? "border-top" : ""}`}><a href={dataDocument.s3FilePath} target="_blank">{dataDocument.filename}</a></td>
        <th className={`label ${index === 0 ? "border-top" : ""}`}>元ファイル名</th>
        <td className={`content ${index === 0 ? "border-top" : ""}`}>{dataDocument.original_filename}</td>
        <td className={`action ${index === 0 ? "border-top" : ""}`} rowSpan={5}>
          <div>
            <button className="uk-button--m uk-button-refer" onClick={() => onEdit(dataDocument.id)}>編集</button>
            <button className="uk-button--m uk-button-cancel uk-margin-top" onClick={() => onDelete(dataDocument.id)}>削除</button>
            <div className="uk-button--m uk-margin-top">
              <input
                id={`targetDisplayNo.${dataDocument.id}`}
                className="uk-input"
                value={targetDisplayNo}
                onChange={e => setTargetDisplayNo(e.target.value)}
              />
            </div>
            <button className="uk-button--m uk-button-cancel uk-margin-top" disabled={targetDisplayNo === ""} onClick={() => {
              moveDisplayNo(dataDocument.id, targetDisplayNo, true);
              setTargetDisplayNo('');
            }}>移動</button>
          </div>
        </td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">資料名</th>
        <td colSpan={3} className="content">{dataDocument.document_name}</td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">更新年月日（公開日）</th>
        <td className="content">{moment(dataDocument.release_date).format("YYYY-MM-DD")}</td>
        <th className="label">公開終了日</th>
        <td className="content">{dataDocument.end_date && moment(dataDocument.end_date).format("YYYY-MM-DD")}</td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">更新日時</th>
        <td className="content">{moment(dataDocument.modified).format(Config.DATE_FORMAT)}</td>
        <th className="label">更新者</th>
        <td className="content">{dataDocument.modified_user && `${dataDocument.modified_user.last_name}${dataDocument.modified_user.first_name}`}</td>
      </tr>
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <th className="label">備考</th>
        <td colSpan={3} className="content">{dataDocument.remarks}</td>
      </tr>
    </>
  );
}