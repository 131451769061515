import clipMIcon from "@/assets/images/clip_m.png";
import { Ticket } from "@/containers/data/Ticket/Ticket";
import React from "react";
import { TicketComment } from "@/containers/data/Public/Public";

interface Props {
  ticket?: Ticket
  className?: string
  ticketComments?: TicketComment[]
}

export default function MessageList( { ticket, className, ticketComments }: Props ) {
  const comments = ticket ? ticket.data_ticket_comments : ticketComments ?? []
  return (
    <div className={`messages ${className}`}>
      {comments?.map(( item, index ) => {
        return (<React.Fragment key={index}>
          {item.is_inquiry_user ?
            <div>
              <div className="received-message">
                <div className="received-message-name">{item.created_group_by} {item.created_user_by}</div>
                <div className="received-message-box">
                  <div className="line-break">{item.comment}</div>
                  {item.attached_files.length > 0 &&
                      <>
                          <hr/>
                        {item.attached_files.map(( file, index ) => (
                          <div key={index} className="uk-flex uk-flex-middle">
                            <img
                              src={clipMIcon}
                              width={16}
                              height={16}
                              className="uk-margin-small-right"
                            />
                            <a href={file.filepath} target="_blank">{file.original_filename}</a>
                          </div>
                        ))}
                      </>}
                </div>
                <div className="received-message-time">{item.created}</div>
              </div>
            </div>
            :
            <div>
              <div className="sent-message">
                <div className="sent-message-name">{item.created_group_by} {item.created_user_by}</div>
                <div className="sent-message-box">
                  <div className="line-break">{item.comment}</div>
                  {item.attached_files.length > 0 &&
                      <>
                          <hr/>
                        {item.attached_files.map(( file, index ) => (
                          <div key={index} className="uk-flex uk-flex-middle">
                            <img
                              src={clipMIcon}
                              width={16}
                              height={16}
                              className="uk-margin-small-right"
                            />
                            <a href={file.filepath} target="_blank">{file.original_filename}</a>
                          </div>
                        ))}
                      </>}
                </div>
                <div className="sent-message-time">{item.created}</div>
              </div>
            </div>
          }
        </React.Fragment>)
      })}
    </div>
  );
}
