import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import ConfirmModal from '@/components/ConfirmModal';
import {useLoadingStore} from "@/stores/loading";
import {deleteUser, getUsers, resetPassword} from "@/api/users";
import {MESSAGE_NO_E37, MESSAGE_NO_E39, PERMISSION_ROLL} from "@/config";
import {useProjectStore} from "@/stores/project";
import * as Common from "@/utils/common";
import {useUserStore} from "@/stores/user";
import * as Config from "@/config";
import {User, LastUpdated} from "@/containers/admin/Users/types";


export default function UserList() {
  const navigate = useNavigate();
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const canUser = Common.canUserManagement(user)

  const [isShowPWModal, setIsShowPWModal] = useState(false);
  const [resetPWId, setResetPWId] = useState<number>(0);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [lastUpdated, setLastUpdated] = useState<LastUpdated>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const breadCrumbs = [
    {title: '管理画面', path: '/admin'},
    {title: 'ユーザ一覧'},
  ];

  const onClickNew = () => {
    navigate("/admin/users/new");
  }
  const onClickDuplicate = () => {
    if (selectedUser) {
      navigate(`/admin/users/${selectedUser}/duplicate`);
    }
  }

  const openDeleteUserModal = (id: number) => {
    setIsShowDeleteModal(true);
    setDeleteId(id);
  }

  const submitDeleteUser = () => {
    if (!selectedProject) {
      return
    }
    setLoading(true);
    const response = deleteUser(deleteId, selectedProject.id);
    response.then(result => {
      setUsers(result.data.users);
      setLastUpdated(result.data.last_updated)
      setIsShowDeleteModal(false);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E37);
    }).finally(() => {
      setLoading(false);
    });
  }

  const openResetPWModal = (id: number) => {
    setIsShowPWModal(true);
    setResetPWId(id);
  }

  const submitResetPassword = () => {
    if (!selectedProject) {
      return
    }
    setLoading(true);
    const response = resetPassword(resetPWId, selectedProject.id);
    response.then(result => {
      setUsers(result.data.users);
      setLastUpdated(result.data.last_updated)
      setIsShowPWModal(false);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E37);
    }).finally(() => {
      setLoading(false);
    });
  }

  const findLabelByValue = (value: string) => {
    const roll = PERMISSION_ROLL.find((item) => item.value === value);
    return roll ? roll.label : '未定義';
  };

  useEffect(() => {
    if (!selectedProject) {
      return
    }
    setLoading(true);
    const response = getUsers(selectedProject.id);
    response.then(result => {
      setUsers(result.data.users);
      setLastUpdated(result.data.last_updated)
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, [selectedProject])

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage])

  useEffect(() => {
    if (canUser < Config.RESTRICTION_HISTORY_USER_OK.value) {
      navigate('/admin');
    }
  }, [])

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="ユーザ管理"/>
        <div
          className="uk-text-right uk-margin-small-bottom">最終更新ユーザ：{lastUpdated?.last_updated_by} 最終更新:{lastUpdated?.last_updated_at}</div>
        <div className="uk-flex-right uk-flex uk-flex-middle">
          <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickDuplicate}>複製</button>
          <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成</button>
        </div>
      </div>
      <div className="container">
        <div className="user-tbl-wrap">
          <table className="user-tbl">
            <thead>
            <tr>
              <th>
                <div className="border-box"/>
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                No.
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                ID
              </th>
              <th>
                <div className="border-box"/>
                グループ
              </th>
              <th>
                <div className="border-box"/>
                権限
              </th>
              <th>
                <div className="border-box"/>
                メールアドレス
              </th>
              <th>
                <div className="border-box"/>
                姓
              </th>
              <th>
                <div className="border-box"/>
                名
              </th>
              <th>
                <div className="border-box"/>
                プロジェクト
              </th>
              <th>
                <div className="border-box"/>
                作成日
              </th>
              <th>
                <div className="border-box"/>
                有効期限
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                PW初期化
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                削除
              </th>
            </tr>
            </thead>
            <tbody>
            {users.map((item, index) => (
              <tr
                className={item.expired ? "" : ""}
                style={{backgroundColor: item.expired ? 'darkgray' : ''}}
                key={index}
              >
                <td className="uk-text-center">
                  <div className="border-box"/>
                  <input type="radio"
                         name="selectRow"
                         value={item.id}
                         onChange={(e) => setSelectedUser(Number(e.target.value))}
                  />
                </td>
                <td className="uk-text-center">
                  <div className="border-box"/>
                  {index + 1}</td>
                <td className="uk-text-center">
                  <div className="border-box"/>
                  <Link to={`/admin/users/${item.id}/edit`}>{item.id}</Link>
                </td>
                <td>
                  <div className="border-box"/>
                  {item.group_name}</td>
                <td>
                  <div className="border-box"/>
                  {findLabelByValue(item.roll)}</td>
                <td>
                  <div className="border-box"/>
                  {item.email_address}</td>
                <td>
                  <div className="border-box"/>
                  {item.last_name}</td>
                <td>
                  <div className="border-box"/>
                  {item.first_name}</td>
                <td>
                  <div className="border-box"/>
                  {item.projects.map((project, index) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <br/>}
                      {project.name}
                    </React.Fragment>
                  ))}
                </td>
                <td>
                  <div className="border-box"/>
                  {item.created}</td>
                <td>
                  <div className="border-box"/>
                  {item.expiration_date}</td>
                <td className="uk-text-center">
                  <div className="border-box"/>
                  <a
                    onClick={() => {
                      if (item.is_changed_password !== 0) {
                        openResetPWModal(item.id);
                      }
                    }}
                    style={{
                      color: item.is_changed_password === 0 ? 'gray' : '',
                      cursor: item.is_changed_password === 0 ? 'not-allowed' : '',
                      textDecoration: item.is_changed_password === 0 ? 'none' : ''
                    }}
                  >
                    PW初期化
                  </a>
                </td>
                <td className="uk-text-center">
                  <div className="border-box"/>
                  <a
                    onClick={() => {
                      openDeleteUserModal(item.id)
                    }}>削除
                  </a>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmModal
        text="ユーザのパスワードを初期化しますか？"
        confirmButtonText="初期化"
        isShow={isShowPWModal}
        onConfirm={() => {
          submitResetPassword();
        }}
        onCancel={() => {
          setIsShowPWModal(false);
        }}
      />
      <ConfirmModal
        text="データを削除しますか？"
        confirmButtonText="削除"
        isShow={isShowDeleteModal}
        onConfirm={() => {
          submitDeleteUser();
        }}
        onCancel={() => {
          setIsShowDeleteModal(false);
        }}
      />
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
    </div>
  );
}