import {DataProps} from '@/containers/data/Data'
import * as Config from '@/config'
import ApplyAreaListEdit from './ApplyAreaListEdit';
import ApplyAreaList from './ApplyAreaList';

export default function ApplyAreas(props: DataProps) {

  switch (props.isType) {
    case Config.Data.ApplyAreaList:
      return <ApplyAreaList />
    case Config.Data.ApplyAreaListEdit:
      return <ApplyAreaListEdit />
    default:
      return null;
  }
}