import { Information } from "@/api/information";
import ConfirmModal from "@/components/ConfirmModal";
import { MESSAGE_NO_E39 } from "@/config";
import { useLoadingStore } from "@/stores/loading";
import moment from "moment";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SubHeader from "../../../components/SubHeader";
import { getLastInformation } from "./shared/getLastInformation";
import { useAuthCheck } from "./shared/useAuthCheck";
import { useInformationList } from "./shared/useInformationList";

const getStatusLabel = (startDate: string, endDate: string) => {
  const today = moment();
  if (today.isBefore(startDate, "day")) return "待機中";
  if (today.isAfter(endDate, "day")) return "非公開";
  return "公開中";
};

const isPastDate = (date: string) => {
  return moment().isAfter(date, "day");
};

const sortInformation = (information: Information[]): Information[] => {
  const today = moment();

  const taikiInfos = information.filter((info) =>
    today.isBefore(info.start_date, "day")
  );

  const koukaiInfos = information.filter((info) =>
    today.isBetween(info.start_date, info.end_date, "day", "[]")
  );

  const hikoukaiInfos = information
    .filter((info) => today.isAfter(info.end_date, "day"))
    .toSorted((a, b) => (new Date(a.modified) < new Date(b.modified) ? 1 : -1));

  return [...taikiInfos, ...koukaiInfos, ...hikoukaiInfos];
};

const InformationList: FC = () => {
  const navigate = useNavigate();
  const setLoading = useLoadingStore((state) => state.setLoading);

  useAuthCheck();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const breadCrumbs = [
    { title: "管理画面", path: "/admin" },
    { title: "お知らせ一覧" },
  ];

  const onClickNew = () => {
    navigate("/admin/information/new");
  };

  const information = useInformationList(setLoading, () =>
    setErrorMessage(MESSAGE_NO_E39)
  );
  const lastInformation = getLastInformation(information);

  return (
    <>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="お知らせ管理" />
        <div className="uk-text-right uk-margin-small-bottom">
          最終更新ユーザ: {lastInformation?.modified_by} 最終更新:{" "}
          {moment(lastInformation?.modified).format("YYYY-MM-DD HH:mm")}
        </div>
        <div className="uk-flex-right uk-flex uk-flex-middle">
          <button
            className="uk-button--m uk-button-refer uk-margin-left"
            onClick={onClickNew}
          >
            新規作成
          </button>
        </div>
      </div>
      <div className="container">
        <div className="user-tbl-wrap">
          <table className="user-tbl">
            <thead>
              <tr>
                <th className="uk-text-center">
                  <div className="border-box" />
                  No.
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  ステータス
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  表示場所
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  表示期間
                </th>
                <th>
                  <div className="border-box" />
                  タイトル
                </th>
                <th>
                  <div className="border-box" />
                  お知らせ内容
                </th>
                <th>
                  <div className="border-box" />
                  最終更新者
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  最終更新日時
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  複製
                </th>
              </tr>
            </thead>
            <tbody>
              {information?.information_list &&
                sortInformation(information.information_list).map(
                  (item, index) => (
                    <tr
                      key={item.id}
                      style={{
                        height: "50px",
                        backgroundColor: isPastDate(item.end_date)
                          ? "darkgray"
                          : "",
                      }}
                    >
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {index + 1}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {getStatusLabel(item.start_date, item.end_date)}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {item.display_drmdx && (
                          <p style={{ margin: 0 }}>DRM-DX</p>
                        )}
                        {item.display_mypage && (
                          <p style={{ margin: 0 }}>マイページ</p>
                        )}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {moment(item.start_date).format("YYYY/M/D")}~
                        {moment(item.end_date).format("YYYY/M/D")}
                      </td>
                      <td>
                        <div className="border-box" />
                        <Link to={`/admin/information/${item.id}/edit`}>
                          {item.title}
                        </Link>
                      </td>
                      <td style={{ maxWidth: "500px" }}>
                        <div className="border-box" />
                        <span className="line-clamp-1">{item.details}</span>
                      </td>
                      <td>
                        <div className="border-box" />
                        {item.modified_by}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {moment(item.modified).format("YYYY/MM/DD HH:mm")}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        <Link to={`/admin/information/${item.id}/duplicate`}>
                          複製
                        </Link>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={!!errorMessage}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        />
      )}
    </>
  );
};

export default InformationList;
