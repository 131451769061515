import React from 'react';
import { RegisterOptions, DeepMap, FieldError } from 'react-hook-form';

interface props {
  name: string;
  register: any;
  rules?: RegisterOptions;
  errors: DeepMap<any, FieldError>;
  placeholder?: string;
}

export const TextArea: React.FC<props> = ( { name, register, rules, errors, placeholder } ) => {
  return (
    <>
      <textarea name={name} {...register(name, rules)} placeholder={placeholder} className="uk-width-1-1"/>
      {/*{errors[name] && <p>{errors[name].message}</p>}*/}
    </>
  );
};