import * as Config from "@/config";
import {postDataBasicMapCertification} from "@/api/basicMap";
import {ErrorOption} from "react-hook-form";
import * as Common from "@/utils/common";
import {BasicMapCity, PostDataBasicMapCity} from "@/components/Attributes/BasicMap/types";

export function useBasicMap() {
//検索項目追加
  const insertAfterItem = <T>(
    array: T[],
    predicate: (item: T) => boolean,
    newItem: T
  ): T[] => {
    const index = array.findIndex(predicate);

    if (index === -1) {
      return [...array, newItem];
    }

    return [
      ...array.slice(0, index + 1),
      newItem,
      ...array.slice(index + 1)
    ];
  }
//検索項目追加
  const basicMapSearchItem = (attributes: any[], options: any) => {
    let item;
    item = insertAfterItem(
      attributes,
      attr => attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.updatedMunicipalities,
      {
        id: 997,
        name: "更新対象市区町村ステータス",
        isStatus: "updated_city_status",
        options: options.updatedCityStatus,
      })
    item.push({
      id: 998,
      name: "作成会社ステータス",
      isStatus: "submission_status",
      options: options.submissionStatus
    })
    item.push({
      id: 999,
      name: "検定機関ステータス",
      isStatus: "certification_status",
      options: options.certificationStatus
    })

    return item;
  }

  //更新対象市町村かどうか判定
  const isUpdatedMunicipalities = (attribute: any) => {
    return attribute.column_name === Config.ATTRIBUTE_COLUMN_NAME.updatedMunicipalities
  }

  type Option = {
    id?: number | string
    code?: number
    name: string
  }
  const findCertificationOption = (id: number | null, options: Option[]) => {
    return options.find(option => option.code === id)?.name
  }

  const findBasicMapOption = (id: number, options: Option[]) => {
    return options.find(option => option.id === id)?.name
  }

  const postBasicMapCertification = async (inputData: any, data_id: any, setLoading: (loading: boolean) => void, certification_id: number) => {
    const certification = inputData.basic_map;
    const basicMapPostData = {
      ...certification,
      id: certification_id,
      data_id: data_id
    };

    setLoading(true);
    return await postDataBasicMapCertification(basicMapPostData).then(result => {
      return result.data;
    }).finally(() => {
      setLoading(false);
    });
  }

  interface Blocks {
    area_id: number;
    code: string;
    id: number;
    name: string;
  }

  //基本情報タブ登録処理のリクエスト成形メソッド
  const pushBasicMapPostData = (obj: any, inputData: any, blocks: Blocks[], certification_id: number) => {
    const {data_tab_statuses, block_id, ...obj1} = obj;
    const basicMapBlockId = blocks.find(block => {
      return block.area_id == Number(obj.area_id) && block.code == Config.BASIC_MAP_BLOCK_CODE;
    })?.id;
    const basicMapCities = {...inputData.basic_map_cities};
    const submissionStatus = {id: certification_id, status: inputData.submission_status};
    return {...obj, block_id: basicMapBlockId, basic_map_cities: basicMapCities, submission_status: submissionStatus}
  }

  const isEmptyObject = <T extends object>(obj: T): boolean => {
    return obj && Object.keys(obj).length === 0;
  };

  //FormデータをPostDataの型に変換
  const convertBasicMapCityData = (original: BasicMapCity): PostDataBasicMapCity => {
    return {
      id: Number(original.id) > 0 ? Number(original.id) : undefined,
      initial_registration_flag: Number(original.initial_registration_flag),
      other_area: original.other_area,
      plain_area: original.plain_area,
      urban_area: original.urban_area,
      remarks: original.remarks,
      status: String(original.status),
      update_target_city_code: original.update_target_city_code,
    };
  }

  //Formデータを変換して返す
  const basicMapCitiesPostData = (inputCityData: BasicMapCity[]): PostDataBasicMapCity[] => {
    return inputCityData?.map(city => convertBasicMapCityData(city));
  }

  type SetErrorFn = (name: string, error: ErrorOption, options?: ({ shouldFocus: boolean } | undefined)) => void;
  const setBasicMapCityErrors = (setError: SetErrorFn, errors: any) => {
    for (const key in errors) {
      setError(`basic_map_cities[${key}]`, {
        type: "sever",
        message: Common.getNestedValue(errors[key]),
      });
    }
  }

  // テキストエリアサイズ調整
  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  return {
    basicMapSearchItem,
    isUpdatedMunicipalities,
    findCertificationOption,
    findBasicMapOption,
    postBasicMapCertification,
    isEmptyObject,
    pushBasicMapPostData,
    basicMapCitiesPostData,
    setBasicMapCityErrors,
    adjustTextareaHeight
  }
}