import create from 'zustand';

export type Project = {
  id: number;
  name: string;
  project_categories: ProjectCategory[];
};

export type ProjectCategory = {
  id: number;
  project_id: number;
  category_code: string;
  name: string;
};

type ProjectStore = {
  project: Project | undefined;
  projectCategory: ProjectCategory | undefined;
  setProject: ( project: Project | undefined ) => void;
  setProjectCategory: ( category: ProjectCategory | undefined ) => void;
};

export const useProjectStore = create<ProjectStore>(( set ) => ({
  project: undefined,
  projectCategory: undefined,
  setProject: ( project ) =>
    set(( state ) => ({
      project: project,
    })),
  setProjectCategory: ( projectCategory ) =>
    set(( state ) => ({
      projectCategory: projectCategory,
    })),
}));
