import React from "react";
import {CurrentTab} from "@/containers/customer/drmPf/useDrmPf";
import {useLocation} from "react-router-dom";
import {DrmPfPage} from "@/config";

interface ButtonSectionProps {
  handleTabChange: (type: string) => void;
  onClickCancel: () => void;
  onClickSave: () => void;
}

export const ButtonSection = (props: ButtonSectionProps) => {
  const pathname = useLocation().pathname;
  const isEdit = CurrentTab(pathname) === DrmPfPage.Edit;
  const isDetail = CurrentTab(pathname) === DrmPfPage.Detail;
  return (
    <div className="right">
      {isEdit &&
          <>
              <button className="uk-button--m uk-button-cancel" onClick={() => props.onClickCancel()}>キャンセル</button>
              <button className="uk-button--m uk-button-refer uk-margin-left" onClick={() => props.onClickSave()}>保存
              </button>
          </>
      }
      {isDetail &&
          <button className="uk-button--m uk-button-refer uk-margin-left"
                  onClick={() => props.handleTabChange('edit')}>編集
          </button>
      }
    </div>
  )
}