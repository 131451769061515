import React from 'react';
import { Document, Page } from 'react-pdf';
import {useFormContext, useWatch} from 'react-hook-form'

interface DataDocument {
  index: number,
  inputFile: InputFile,
}
export interface InputFile {
  file: File,
  tempPath: string,
  preview: string,
}
export interface DataDocumentInputs {
  filename: string,
  document_name?: string,
  release_date?: string,
  end_date?: string,
  remarks: string,
}
export const FileInput = ({index, inputFile}: DataDocument) => {
  const { register, control } = useFormContext();
  const { formState: { errors }, } = useFormContext<{dataDocuments: Array<DataDocumentInputs>}>();
  const name = `dataDocuments.${index}`;
  const error = errors.dataDocuments?.[index];

  return (
    <>
      <tr>
        <th rowSpan={5} className="media">
          <div className="uk-flex uk-flex-center">
            <div className="media_img">
            </div>
          </div>
        </th>
        <th className="label">ファイル名</th>
        <td className="content">自動取得</td>
        <th className="label">元ファイル名</th>
        <td className="content">自動取得</td>
      </tr>
      <tr>
        <th className="label">資料名<span className="error">※</span></th>
        <td colSpan={3} className="content">
          <input
            className={`uk-width-1-1 ${error?.document_name ? "error-form" : ""}`}
            {...register(`${name}.document_name`)}
          />
          {error?.document_name && <div className="error">{`${error?.document_name?.message}`}</div>}
        </td>
      </tr>
      <tr>
        <th className="label">更新年月日（公開日）<span className="error">※</span></th>
        <td className="content">
          <input
            type="date"
            className={`uk-width-1-1 ${error?.release_date ? "error-form" : ""}`}
            {...register(`${name}.release_date`)}
          />
          {error?.release_date && <div className="error">{`${error?.release_date?.message}`}</div>}
        </td>
        <th className="label">公開終了日</th>
        <td className="content">
          <input
            type="date"
            className={`uk-width-1-1 ${error?.end_date ? "error-form" : ""}`}
            {...register(`${name}.end_date`)}
          />
          {error?.end_date && <div className="error">{`${error?.end_date?.message}`}</div>}
        </td>
      </tr>
      <tr>
        <th className="label">更新日時</th>
        <td className="content">自動取得</td>
        <th className="label">更新者</th>
        <td className="content">自動取得</td>
      </tr>
      <tr>
        <th className="label">備考</th>
        <td colSpan={3} className="content">
                  <textarea
                    className={`uk-width-1-1 ${error?.remarks ? "error-form" : ""}`}
                    {...register(`${name}.remarks`)}
                  />
          {error?.remarks && <div className="error">{`${error?.remarks?.message}`}</div>}
        </td>
      </tr>
    </>
  );
}

export const FileInputDummy = () => {
  return (
    <div className="file-tbl">
      <table>
        <tr>
          <th rowSpan={5} className="media">
            <div className="uk-flex uk-flex-center">
              <div className="media_img">
              </div>
            </div>
          </th>
          <th className="label">ファイル名</th>
          <td className="content">自動取得</td>
          <th className="label">元ファイル名</th>
          <td className="content">自動取得</td>
        </tr>
        <tr>
          <th className="label">資料名<span className="error">※</span></th>
          <td colSpan={3} className="content">
          </td>
        </tr>
        <tr>
          <th className="label">更新年月日（公開日）<span className="error">※</span></th>
          <td className="content"></td>
          <th className="label">公開終了日</th>
          <td className="content"></td>
        </tr>
        <tr>
          <th className="label">更新日時</th>
          <td className="content">自動取得</td>
          <th className="label">更新者</th>
          <td className="content">自動取得</td>
        </tr>
        <tr>
          <th className="label">備考</th>
          <td colSpan={3} className="content">
          </td>
        </tr>
      </table>
    </div>
  );
}