import create from 'zustand';

type AxiosRouteStore = {
    axiosRoute: string;
    setAxiosRoute: (axiosRoute: string) => void;
};

export const useAxiosRouteStore = create<AxiosRouteStore>((set) => ({
    axiosRoute: '',
    setAxiosRoute: (axiosRoute) =>
        set((state) => ({
            axiosRoute: axiosRoute,
        })),
}));
