import React, {useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";

interface City {
  id?: number | string;
  code?: number;
  name: string;
}

interface UpdatedCityInputProps {
  cities: City[];
  name: string;
  defaultCode: string | undefined;
}

export const UpdatedCityInput: React.FC<UpdatedCityInputProps> = ({cities, name, defaultCode}) => {
  const {control} = useFormContext();
  const defaultValue = cities.find(city => String(city.code) === defaultCode)?.name;
  const [inputValue, setInputValue] = useState<string | undefined>(defaultValue);

  const handleInputChange = (value: string, onChange: (value: string | undefined) => void) => {
    setInputValue(value);
    const selectedCity = cities.find(city => city.name === value);
    if (selectedCity) {
      onChange(selectedCity.code?.toString());
    } else {
      onChange(undefined);
    }
  };

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue])

  if (!cities) return null;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultCode === 'undefined' ? undefined : defaultCode}
      render={({field}) => (
        <div>
          <input
            list="updated-city-list"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value, field.onChange)}
          />
          <input
            {...field}
            type="hidden"
          />
        </div>
      )}
    />
  );
};


interface UpdatedCityListProps {
  cities: City[];
}

export const UpdatedCityList = (props: UpdatedCityListProps) => {
  return (
    <datalist id="updated-city-list">
      {props.cities.map((city) => (
        <option key={city.code} value={city.name}>{city.code}</option>
      ))}
    </datalist>
  )
}
