import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  useFormContext,
  Controller,
  UseFieldArrayAppend,
} from "react-hook-form";
import { SelectBox } from '@/components/Form/SelectBox';
import { MESSAGE_NO_E08, MESSAGE_NO_E35 } from '@/config';
import * as Common from '@/utils/common';

type ApplyArea = {
  id: string;
  checked: boolean;
  category_id: number | null;
  sales_product_id: number | null;
  sales_product_id2: number | null;
  quantity: number;
  unit_price: number;
  remarks: string | null;
}

interface Props {
  applyArea: ApplyArea;
  index: number;
  length: number;
  areas: any[];
}

interface Methods {
}

export const ApplyAreaForm = forwardRef<Methods, Props>((props, ref) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<{ data_apply_products: Array<ApplyArea> }>();

  const { applyArea, index, areas } = props;

  const [isReset, setIsReset] = useState<boolean>(true);

  const watchCategoryId = watch(`data_apply_products.${index}.category_id`);
  const watchSalesProductId = watch(`data_apply_products.${index}.sales_product_id`);
  const watchSalesProductId2 = watch(`data_apply_products.${index}.sales_product_id2`);
  const quantity = watch(`data_apply_products.${index}.quantity`);

  const convertAreasToOptions = (areas: any[]) => {
    return areas.map((area: any) => { return { label: area.name, value: area.id } });
  }

  const salesProductOptions = useMemo(() => {
    if (watchCategoryId && watchSalesProductId2) {
      return convertAreasToOptions(Common.getArea(areas, watchCategoryId).children?.find((area: any) => area.id == watchSalesProductId2)?.children ?? []);
    }
    return [];
  }, [watchSalesProductId2]);

  useEffect(() => {
    if (isReset) {
      setIsReset(false);
      return;
    }
    if (watchCategoryId == 1) {
      setValue(`data_apply_products.${index}.sales_product_id`, watchCategoryId);
    } else {
      setValue(`data_apply_products.${index}.sales_product_id`, null);
      setValue(`data_apply_products.${index}.sales_product_id2`, null);
    }
  }, [watchCategoryId]);

  useEffect(() => {
    if (isReset) {
      setIsReset(false);
      return;
    }
    setValue(`data_apply_products.${index}.sales_product_id`, null);
  }, [watchSalesProductId2]);

  const amount = useMemo(() => {
    let value = 0;
    if (watchSalesProductId) {
      value = Common.getArea(areas, watchSalesProductId).amount;
    }
    setValue(`data_apply_products.${index}.unit_price`, value);
    return value;
  }, [watchCategoryId, watchSalesProductId, watchSalesProductId2]);

  return (
    <tr key={applyArea.id}>
      <td>
        <input type="hidden" name={`data_apply_products.${index}.id`} />
        <label>
          <input
            type="checkbox"
            {...register(`data_apply_products.${index}.checked`)}
          />
        </label>
      </td>
      <td>
        <div className="uk-grid uk-grid-small uk-margin-remove" data-uk-grid>
          <SelectBox
            name={`data_apply_products.${index}.category_id`}
            options={convertAreasToOptions(areas)}
            register={register}
            rules={{ required: MESSAGE_NO_E08 }}
            error={errors.data_apply_products?.[index]?.category_id?.message}
          />
          {(watchCategoryId == 2 || watchCategoryId == 1817) && (
            <SelectBox
              name={`data_apply_products.${index}.sales_product_id`}
              options={convertAreasToOptions(
                Common.getArea(areas, watchCategoryId).children
              )}
              register={register}
              rules={{ required: MESSAGE_NO_E08 }}
              error={
                errors.data_apply_products?.[index]?.sales_product_id?.message
              }
            />
          )}
          {watchCategoryId == 3 && (
            <>
              <SelectBox
                name={`data_apply_products.${index}.sales_product_id2`}
                options={convertAreasToOptions(
                  Common.getArea(areas, watchCategoryId).children
                )}
                register={register}
                rules={{ required: MESSAGE_NO_E08 }}
                error={
                  errors.data_apply_products?.[index]?.sales_product_id2
                    ?.message
                }
              />
              <SelectBox
                name={`data_apply_products.${index}.sales_product_id`}
                options={salesProductOptions}
                register={register}
                rules={{ required: MESSAGE_NO_E08 }}
                error={
                  errors.data_apply_products?.[index]?.sales_product_id?.message
                }
              />
            </>
          )}
        </div>
      </td>
      <td className="uk-text-right">
        {"¥ " + Common.formatPrice(amount)}
        <input
          type="hidden"
          {...register(`data_apply_products.${index}.unit_price`)}
        />
      </td>
      <td>
        <input
          type="number"
          className={`width-60 uk-text-right ${
            errors.data_apply_products?.[index]?.quantity?.message
              ? "error-form"
              : ""
          }`}
          {...register(`data_apply_products.${index}.quantity`, {
            required: MESSAGE_NO_E08,
          })}
        />
        {errors.data_apply_products?.[index]?.quantity?.message && (
          <div className="error">{`${errors.data_apply_products?.[index]?.quantity?.message}`}</div>
        )}
      </td>
      <td className="uk-text-right">
        {amount && quantity ? "¥ " + Common.formatPrice(amount * quantity) : ""}
      </td>
      <td>
        <input
          className={`uk-width-1-1" ${
            errors.data_apply_products?.[index]?.remarks?.message
              ? "error-form"
              : ""
          }`}
          {...register(`data_apply_products.${index}.remarks`, {
            maxLength: {
              value: 200,
              message: MESSAGE_NO_E35(200),
            },
          })}
        />
        {errors.data_apply_products?.[index]?.remarks?.message && (
          <div className="error">{`${errors.data_apply_products?.[index]?.remarks?.message}`}</div>
        )}
      </td>
    </tr>
  );
});