import React, { useEffect, useState } from 'react';

type SelectOption = {
  value: string;
  label: string;
};

type CustomSelectProps = {
  options: SelectOption[];
  placeholder?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  value?: string | ReadonlyArray<string> | number | null | undefined;
  onChange?: ( event: any ) => void;
};

function CustomSelect( props: CustomSelectProps ) {
  const [selectedValue, setSelectedValue] = useState(props.value ?? '');

  const handleSelectChange = ( event: any ) => {
    setSelectedValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  useEffect(() => {
    setSelectedValue(props.value ?? '')
  }, [props.value])

  return (
    <select
      {...props}
      className={`${props.className} ${selectedValue ? '' : 'placeholder'}`}
      value={selectedValue}
      onChange={handleSelectChange}
    >
      {props.placeholder &&
          <option value="">
            {props.placeholder}
          </option>
      }
      {props.options.map(( option ) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default CustomSelect;