import CustomSelect from "@/components/CustomSelect";
import {API_URL, ERROR_MESSAGE_E23, importOptions, MESSAGE_NO_E39, SUCCESS_MESSAGE_E24} from "@/config";
import { Project, ProjectCategory, } from "@/stores/project";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { SearchRows } from "@/containers/progress/ProgressList";
import { useLoadingStore } from "@/stores/loading";
import { getProjects } from "@/api/projects";
import { axios } from "@/lib/axios";
import { exportCsv } from "@/api/data";
import * as Common from "@/utils/common";
import {Tooltip} from 'react-tooltip'
import moment from 'moment'
import * as Config from '@/config'
import {useInterval} from '@/utils/UseInterval'
import ConfirmModal from '@/components/ConfirmModal'

interface ExportProps {
  setModalMessage: ( res: { type: {}, message: string } | null ) => void
}

export const DicDataExport = ( props: ExportProps ) => {
  const methods = useForm();
  const setLoading = useLoadingStore(state => state.setLoading);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  const [error, setError] = useState<any | null>(null);
  const [outPutList, setOutPutList] = useState<Array<any>>([]);
  const targetList = outPutList.filter((o: any) => o.deleted === null).map((o: any) => o.id);
  const [checkIds, setCheckIds] = useState(new Set<number>());

  useInterval(() => {
    getList();
  }, 60 * 1000);

  useEffect(() => {
    getList()
    return () => {
    };
  }, []);

  function getList() {
    axios.get('/api/v1/dicExport/list', {}).then(result => {
      const outputs = result.data.dataDicExports;
      setOutPutList(outputs);
      const resetIds = new Set<number>(outputs.filter((o: any) => checkIds.has(o.id) && !o.deleted).map((o: any) => o.id));
      setCheckIds(resetIds);
    }).catch(error => {
      props.setModalMessage({ type: { server: true }, message: MESSAGE_NO_E39 });
    }).finally(() => {
    });
  }

  function deleteIds() {
    setModalConfirm({
      callback: () => {
        setLoading(true);
        axios.post('/api/v1/dicExport/deleteByIds', {
          ids: Array.from(checkIds),
        }).then(result => {
          getList();
        }).catch(error => {
          props.setModalMessage({ type: { server: true }, message: MESSAGE_NO_E39 });
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  }

  const onClickClear = () => {
  }

  const onClickExport = async ( e: any ) => {
    setLoading(true);
    axios.post('/api/v1/dicExport/export', {
    }).then(( result ) => {
      // if (result.status === 200) {
      //   const contentType = result.headers["content-type"];
      //   const blob = new Blob([result.data], { type: contentType });
      //   const downloadUrl = window.URL.createObjectURL(blob);
      //   const a = document.createElement('a');
      //   a.href = downloadUrl;
      //   a.download = "dic_import_template_v3_1@.xlsm";
      //   document.body.appendChild(a);
      //   a.click();
      //   document.body.removeChild(a);
      //   window.URL.revokeObjectURL(downloadUrl);
      // }
    }).catch(( error ) => {
      if (!error.response) {
        props.setModalMessage({ type: { server: true }, message: MESSAGE_NO_E39 })
      }
    }).finally(() => {
      getList();
      setLoading(false);
    });
  }

  const onClickDownload = () => {
    axios.post('/api/v1/dicExport/downloads', {
      ids: Array.from(checkIds),
    }).then(result => {
      result.data.s3Urls.forEach((s3Url: any, index: number) => {
        axios.get(s3Url.url, {
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = s3Url.filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      });
    }).catch(error => {
      props.setModalMessage({ type: { server: true }, message: MESSAGE_NO_E39 })
    }).finally(() => {
    });
  }

  const onClickCheck = ( id: number ) => {
    if (checkIds.has(id)) {
      checkIds.delete(id);
      setCheckIds(new Set(checkIds));
    } else {
      checkIds.add(id);
      setCheckIds(new Set(checkIds));
    }
  }

  const onClickCheckAll = () => {
    if (checkIds.size === targetList.length) {
      setCheckIds(new Set<number>());
    } else {
      setCheckIds(new Set<number>(targetList));
    }
  }

  const onClickDeleteIds = () => {
    deleteIds();
  }

  return (
    <div>
      <div className="uk-flex uk-flex-center uk-margin-top">
        {/*<button className="uk-button--m uk-button-cancel" onClick={onClickClear}>クリア</button>*/}
        <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickExport}>エクスポート</button>
      </div>

      <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
        <div className="uk-inline">
          <button className="uk-button--m uk-button-refer" onClick={onClickDownload} disabled={checkIds.size === 0}>
            ダウンロード</button>
        </div>
        <div>
          <button className="uk-button--m uk-button-cancel" onClick={onClickDeleteIds}
                  disabled={checkIds.size === 0}>一括削除</button>
        </div>
      </div>
      <OutputListContainer outPutList={outPutList} targetList={targetList} checkIds={checkIds} onClickCheck={onClickCheck} onClickCheckAll={onClickCheckAll} />
      <div className="uk-text-right">1週間で自動削除されます</div>

      <ConfirmModal
        text={Config.MESSAGE_NO_E05}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if (modalConfirm) modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  )
}

interface OutputListContainerProps {
  outPutList: Array<any>,
  targetList: Array<number>,
  checkIds: Set<number>,
  onClickCheck: ( id: number ) => void,
  onClickCheckAll: () => void,
}

const OutputListContainer = ( props: OutputListContainerProps ) => {

  return (
    <div className="mt-5">
      <Tooltip id="report-tooltip" style={{ zIndex: 3 }}/>
      <table className="report-tbl">
        <thead>
        <tr className="header">
          <th style={{width: 30}}>
            <div className="uk-flex uk-flex-center">
              <input
                type="checkbox"
                onChange={props.onClickCheckAll}
                checked={props.targetList.length > 0 && props.checkIds.size === props.targetList.length}/>
            </div>
          </th>
          <th>登録日時</th>
          <th>グループ名</th>
          <th>登録者</th>
          <th>ステータス</th>
          <th>出力完了日時</th>
          <th>最終更新日時</th>
          <th>最終更新者</th>
        </tr>
        </thead>
        <tbody>
        {props.outPutList.map((( data: any ) => {
          const checked = props.checkIds.has(data.id);
          return (
            <tr className={`${checked ? "selected" : ""} ${data.deleted ? "deleted" : ""}`}>
              <td className={`${checked ? "selected" : ""}`}>
                <div className="uk-flex uk-flex-center">
                  <input type="checkbox" onChange={() => {
                    props.onClickCheck(data.id)
                  }} checked={checked} disabled={data.deleted}/>
                </div>
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={moment(data.created).format(Config.DATE_FORMAT)}>
                {moment(data.created).format(Config.DATE_FORMAT)}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.created_user.group.name}>
                {data.created_user.group.name}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={`${data.created_user.last_name}${data.created_user.first_name}`}>
                {`${data.created_user.last_name}${data.created_user.first_name}`}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.status}>
                {Config.REPORT_OUTPUTS_STATUSES[data.status]}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={data.output_time ? moment(data.output_time).format(Config.DATE_FORMAT) : Config.REPORT_OUTPUTS_STATUSES[data.status]}>
                {data.output_time ? moment(data.output_time).format(Config.DATE_FORMAT) : ""}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={moment(data.modified).format(Config.DATE_FORMAT)}>
                {moment(data.modified).format(Config.DATE_FORMAT)}
              </td>
              <td className="uk-text-left" data-tooltip-id="report-tooltip" data-tooltip-html={`${data.modified_user.last_name}${data.modified_user.first_name}`}>
                {`${data.modified_user.last_name}${data.modified_user.first_name}`}
              </td>
            </tr>
          )
        }))}
        </tbody>
      </table>
    </div>
  )
}