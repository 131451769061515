import create from 'zustand';

type ProgressStore = {
    queryParams: any | undefined;
    setQueryParams: (any: any | undefined) => void;
};

// 検索条件の保持に使用
export const useProgressStore = create<ProgressStore>((set) => ({
    queryParams: undefined,
    setQueryParams: (queryParams) =>
        set((state) => ({
            queryParams: queryParams,
        })),
}));
