import create from 'zustand';

type Location = {
    prefecture: string;
    city: string;
    featureKey: string;
}

type MapDrawingStore = {
    p_id: string | null;
    setP_id: (p_id: string | null) => void;
    coordinate: any;
    setCoordinate: (coordinate: any) => void;
    currentLocation: Location;
    setCurrentLocation: (currentLocation: Location) => void;
    reset: () => void;
};

const initialState = {
    p_id: null,
    coordinate: null,
    currentLocation: {prefecture: "", city: "", featureKey: ""},
}

const useMapDrawingStore = create<MapDrawingStore>((set) => ({
    ...initialState,
    setP_id: (p_id) =>
        set((_) => ({
            p_id: p_id,
        })),
    setCoordinate: (coordinate) =>
        set((_) => ({
            coordinate: coordinate,
        })),
    setCurrentLocation: (currentLocation) =>
        set((_) => ({
            currentLocation: currentLocation,
        })),
    reset: () => {
        set(initialState)
    }
}));

export function useMapDrawing() {
    return useMapDrawingStore();
}