import React, { useEffect } from 'react';
import { RegisterOptions, DeepMap, FieldError, useForm } from 'react-hook-form';

interface Option {
  label: string;
  value: string | number;
}

interface props {
  name: string
  options: Option[]
  register: any
  rules?: RegisterOptions
  errors: DeepMap<any, FieldError>
  setValue?: any
  watchedRestrictionProgressNew?: string | false | undefined
  loginRestriction?: string | number
  loginRoll?: string
  roll?: string
}

export const RadioButton: React.FC<props> = ( {
                                                name,
                                                options,
                                                register,
                                                rules,
                                                watchedRestrictionProgressNew,
                                                errors,
                                                setValue,
                                                loginRestriction,
                                                loginRoll,
                                                roll

                                              } ) => {
  const disabled = ( index: number, value: string | number ) => {
    if (roll && loginRoll) {
      return roll < loginRoll
    }
    if (watchedRestrictionProgressNew === "1") {
      return index !== 2;
    }
    if (loginRestriction === 0 && (value === 1 || value === 2)) {
      return true;
    }
    if (loginRestriction) {
      return loginRestriction < value
    }
    if (loginRoll) {
      return loginRoll > value
    }
  }

  const isChecked = ( index: number ) => {
    return watchedRestrictionProgressNew === "1" && index === 2;
  }
  useEffect(() => {
    options.forEach(( option, index ) => {
      if (isChecked(index)) {
        setValue(name, option.value);
      }
    });
  }, [options, watchedRestrictionProgressNew, setValue, name]);

  return (
    <>
      {options.map(( option, index ) => (
        <label key={index}>
          <div>
            {watchedRestrictionProgressNew === "1" ?
              <input
                type="radio"
                value={option.value}
                {...register(name, rules)}
                disabled={disabled(index, option.value)}
                checked={isChecked(index)}
              /> :
              <input
                type="radio"
                value={option.value}
                {...register(name, rules)}
                disabled={disabled(index, option.value)}
              />
            }
          </div>
          {option.label}
        </label>
      ))}
      {/*{errors[name] && <p>{errors[name].message}</p>}*/}
    </>
  );
};