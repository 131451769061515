export function useDrmPfApiUsages() {
  const CurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = ('0' + (today.getMonth() + 1)).slice(-2)
    return year + '-' + month
  }

  return {CurrentDate};
}

export function DrmPfApiUsagesOptions() {
  const drm_pf_api_summary_th = [
    {
      label: '顧客番号',
      width: '4rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '問合せ者区分',
      width: '4rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '団体区分',
      width: '4rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '法人・団体名',
      width: '10rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '部署名・所属名',
      width: '5rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '課金\n区分',
      width: '2rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '基本料金',
      width: '4rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: 'データ取得件数',
      width: '7rem',
      rowspan: 1,
      colspan: 1,
      bold: false
    },
    {
      label: 'データ取得料',
      width: '18rem',
      rowspan: 1,
      colspan: 4,
      bold: false
    },
    {
      label: '割引',
      width: '7rem',
      rowspan: 1,
      colspan: 2,
      bold: false
    },
    {
      label: 'データ取得料\n（割引適用後）',
      width: '6rem',
      rowspan: 2,
      colspan: 1,
      bold: false
    },
    {
      label: '請求額（税抜）',
      width: '8rem',
      rowspan: 2,
      colspan: 1,
      bold: true
    },
    {
      label: '消費税率',
      width: '5rem',
      rowspan: 2,
      colspan: 1,
      bold: true
    },
    {
      label: '請求額（税込）',
      width: '8rem',
      rowspan: 2,
      colspan: 1,
      bold: true
    },
  ]
  const drm_pf_api_summary_th_2nd = [
    {label: '有効取得件数', width: 'auto'},
    {label: '減額前', width: '4rem'},
    {label: '従量割引', width: '4rem'},
    {label: '実質割引率', width: '4rem'},
    {label: '減額後', width: '4rem'},
    {label: '課金区分', width: '4rem'},
    {label: '長期', width: '2rem'},
  ]

  const drm_pf_api_details_th = [
    {label: 'API-ID', width: '5rem'},
    {label: '総取得件数', width: '6rem'},
    {label: '一時無償件数', width: '6rem'},
    {label: '有効取得件数', width: '6rem'},
    {label: '単価', width: '5rem'},
    {label: '小計金額（税抜）', width: '10rem'},
  ]

  const drm_pf_api_usages_th = [
    {label: '顧客番号',  width: '3rem'},
    {label: '問合せ者区分',  width: '4rem'},
    {label: '団体区分', width: '4rem'},
    {label: '法人・団体名', width: '10rem'},
    {label: '部署名・所属名', width: '6rem'},
    {label: '利用開始', width: '8rem'},
    {label: '利用終了', width: '8rem'},
    {label: 'IPアドレス', width: '6rem'},
    {label: 'API-ID', width: '4rem'},
    {label: '処理結果', width: '5rem'},
    {label: '処理区分', width: '4rem'},
    {label: '版情報', width: '3rem'},
    {label: '取得データ件数', width: '5rem'},
    {label: '単価', width: '5rem'},
    {label: '請求対象', width: '4rem'},
    {label: '一時無償', width: '4rem'},
    {label: '小計', width: '7rem'},
  ]

  return {drm_pf_api_summary_th, drm_pf_api_summary_th_2nd, drm_pf_api_details_th, drm_pf_api_usages_th};
}