import { axios } from '@/lib/axios';
import { GroupInfo } from "@/containers/admin/Groups/GroupEdit"

export const getGroups = (): Promise<any> => {
  return axios.get('/api/v1/groups/getGroups')
}

export const getGroupsInfo = ( group_id:string | undefined = undefined ): Promise<any> => {
  const params = group_id !== undefined ? { group_id } : {};
  return axios.get('/api/v1/groups/getGroupsInfo', {
    params
  })
}

export const createGroup = ( group_info: GroupInfo ): Promise<any> => {
  return axios.post('/api/v1/groups/createGroup', group_info)
}

export const updateGroup = ( group_info: GroupInfo ): Promise<any> => {
  return axios.post('/api/v1/groups/updateGroup', group_info)
}

export const deleteGroup = ( group_id: number ): Promise<any> => {
  return axios.delete('/api/v1/groups/deleteGroup', {
    params: { group_id }
  })
}