import { axios } from '@/lib/axios';
import { PostComment } from "@/components/MessageInputBox";

export const getTicket = ( data_id: number ) => {
  return axios.get('/api/v1/dataTickets/getTicket', {
    params: { data_id }
  })
}

export const postComment = ( postData: PostComment ) => {
  return axios.post('/api/v1/dataTickets/postComment', postData)
}

export const searchTicket = ( projectCategoryId: number, keyword: string | null = null, area_id: number | null = null, block_id: number | null = null ) => {
  return axios.get('/api/v1/dataTickets/searchTicket', {
    params: { project_category_id: projectCategoryId, area_id: area_id, block_id: block_id, keyword: keyword }
  })
}