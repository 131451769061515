import { useState, useEffect } from "react";
import { InformationList, getInformationList } from "@/api/information";

export const useInformationList = (
  setLoading: (isLoading: boolean) => void,
  onError: () => void
) => {
  const [information, setInformation] = useState<InformationList>();

  useEffect(() => {
    setLoading(true);
    getInformationList()
      .then((res) => {
        setInformation(res.data);
      })
      .catch(() => {
        onError();
      })
      .finally(() => setLoading(false));
  }, []);

  return information;
};
