import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import MessageList from "@/components/MessageList";
import MessageInputBox from "@/components/MessageInputBox";
import { useCallback, useEffect, useState } from "react";
import { useLoadingStore } from "@/stores/loading";
import { getTicket as getTicketApi } from "@/api/ticket";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import { useProjectStore } from "@/stores/project";
import { useUserStore } from "@/stores/user";

interface AttachedFile {
  original_filename: string
  filepath: string
}

interface TicketComment {
  id: number
  created: string
  created_group_by: string
  created_user_by: string
  is_inquiry_user: boolean
  comment: string
  attached_files: AttachedFile[]
}

export interface Ticket {
  data_id: number
  data_ticket_id: number
  is_close: boolean
  status: string
  data_ticket_comments: TicketComment[]
}

export default function Ticket() {
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const navigate = useNavigate()
  const params = useParams()
  const pathname = useLocation().pathname
  const canTickets = Common.canTicket(selectedProjectCategory?.id, user)

  const [ticket, setTicket] = useState<Ticket>()
  const onClickTicketSearch = () => {
    // window.open(`${BASENAME}/ticket_search`)
    window.open(`/progress/${params.projectCategoryId}/ticket/search`)
  }

  const getTicket = useCallback(() => {
    getTicketApi(Number(params.dataId)).then(result => {
      setTicket(result.data)
    }).catch(error => {
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (canTickets < Config.RESTRICTION_HISTORY_TICKET_VIEW.value) {
      console.log("Ticket")
      navigate('/progress');
    }
  }, [pathname])

  useEffect(() => {
    setLoading(true)
    getTicket()
  }, [])

  return (
    <div>
      <div className="container">
        <div>
          <div className="uk-margin-small-top">
            <table className="uk-width-medium">
              <tbody>
              <tr>
                <td className="uk-width-2-5 background-gray239">ステータス</td>
                <td>{ticket?.status}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <button className="uk-button--m uk-button-refer uk-margin-small-top" onClick={onClickTicketSearch}>チケット検索
          </button>
          <MessageList ticket={ticket} className="uk-margin-top"/>
          <MessageInputBox ticket={ticket} getTicket={getTicket}/>
        </div>
      </div>
    </div>
  );
}