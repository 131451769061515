import React, {useEffect} from "react";
import {BasicMapStatus} from "@/components/Form/Attributes";
import {useFormContext} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useUserStore} from "@/stores/user";
import * as Config from "@/config";
import {basicMapStore} from "@/stores/basicMap";
import {useBasicMap} from "@/utils/useBasicMap";

export const EditCertificationContainer = () => {
  const {register, formState: {errors},} = useFormContext();
  const params = useParams();
  const {
    fetchSubmissionStatus,
    submissionStatus,
    fetchCertificationStatus,
    certificationStatus,
    fetchCertification,
    certification,
    reset
  } = basicMapStore();
  const user = useUserStore(state => state.user);
  const {isEmptyObject} = useBasicMap();
  const isCertification = user?.group_id === Config.CERTIFICATION_GROUP_ID;
  useEffect(() => {
    fetchSubmissionStatus();
    fetchCertificationStatus();
    fetchCertification(Number(params.dataId));
    return () => {
      reset();
    }
  }, [])

  if (isEmptyObject(submissionStatus) || isEmptyObject(certification)) return <></>;

  return (
    <div className="certification-tbl">
      <table className="uk-width-1-1">
        <thead>
        <tr>
          <th className="hidden"></th>
          <th className="hidden"></th>
          <th className="width-15p">日付</th>
          <th className="width-15p">担当者</th>
          <th className="width-15p">ステータス</th>
          <th>備考</th>
        </tr>
        </thead>
        <tbody>
        <tr className="">
          <th>作成会社</th>
          <th>検定提出日</th>
          <td>
            <input
              disabled={isCertification}
              defaultValue={certification?.submission_date ?? ""}
              type="date" {...register(`basic_map.submission_date`)} />
          </td>
          <td>
            <input
              disabled={isCertification}
              defaultValue={certification?.submission_charge ?? ""}
              {...register(`basic_map.submission_charge`)} />
          </td>
          <td>
            <BasicMapStatus name="basic_map.submission_status" options={submissionStatus}
                            props={{defaultValue: certification?.submission_status, disabled: isCertification}}/>
          </td>
          <td><textarea
            className="uk-width-1-1"
            disabled={isCertification}
            defaultValue={certification?.submission_remarks ?? ""}
            {...register(`basic_map.submission_remarks`)} /></td>
        </tr>
        <tr className="">
          <th rowSpan={2}>検定機関</th>
          <th>受領日</th>
          <td><input
            disabled={!isCertification}
            defaultValue={certification?.receipt_date ?? ""}
            type="date" {...register(`basic_map.receipt_date`)} /></td>
          <td><input
            disabled={!isCertification}
            defaultValue={certification?.receipt_charge ?? ""}
            {...register(`basic_map.receipt_charge`)} /></td>
          <td rowSpan={2}>
            <BasicMapStatus name="basic_map.certification_status" options={certificationStatus}
                            props={{defaultValue: certification?.certification_status, disabled: !isCertification}}/>
          </td>
          <td><textarea
            className="uk-width-1-1"
            disabled={!isCertification}
            defaultValue={certification?.receipt_remarks ?? ""}
            {...register(`basic_map.receipt_remarks`)} /></td>
        </tr>
        <tr className="">
          <th>検定日</th>
          <td><input
            disabled={!isCertification}
            defaultValue={certification?.certification_date ?? ""}
            type="date" {...register(`basic_map.certification_date`)} /></td>
          <td><input
            disabled={!isCertification}
            defaultValue={certification?.certification_charge ?? ""}
            {...register(`basic_map.certification_charge`)} /></td>
          <td><textarea
            className="uk-width-1-1"
            disabled={!isCertification}
            defaultValue={certification?.certification_remarks ?? ""}
            {...register(`basic_map.certification_remarks`)} /></td>
        </tr>
        <tr className="hidden-record">
          <td colSpan={6}></td>
        </tr>
        <tr className="">
          <th colSpan={2}>検定指摘への回答</th>
          <td colSpan={4}>
            <textarea
              className="uk-width-1-1"
              disabled={isCertification}
              defaultValue={certification?.answer ?? ""}
              {...register(`basic_map.answer`)} /></td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}