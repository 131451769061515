import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {axios} from '@/lib/axios'
import {useForm, FormProvider} from 'react-hook-form';
import {useLoadingStore} from '@/stores/loading'
import {useProjectStore} from '@/stores/project'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {DataDocumentInputs, FileInput, FileInputDummy, InputFile} from '@/components/Form/DataDocuments'
import {useUserStore} from '@/stores/user'
import moment from 'moment'
import * as Config from '@/config'
import * as Common from '@/utils/common'
import BlockModal from '@/components/BlockModal'
import ConfirmModal from '@/components/ConfirmModal'
import {useBlockerStore} from '@/stores/blocker'
import {useMessageModalStore} from '@/stores/message'
import {MESSAGE_NO_E37, MESSAGE_NO_E40} from '@/config'
import {byteConverter} from '@/utils/common'

export default function FileNew() {
  const hiddenFileInput = useRef<HTMLInputElement>(null); // ファイル選択ボタンの装飾

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const user = useUserStore(state => state.user);
  const [isBlocker, setBlocker] = useBlockerStore(state => [state.isBlocker, state.setBlocker]);
  const methods = useForm();

  const [inputFiles, setInputFiles] = useState<InputFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isCancelEditConfirm, setIsCancelEditConfirm] = useState(false);

  const uploadFile = async (inputFile: InputFile, formAttributes: any, formInputs: any[]) => {
    const formData = new FormData();
    Object.entries(formInputs).forEach(([key, value]) => {
      if (key === "key") {
        formData.append(key, value + inputFile.tempPath);
      } else formData.append(key, value);
    });
    formData.append('file', inputFile.file);

    const response = await axios.post(formAttributes.action, formData,  {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  };

  async function post() {
    setErrorMessage("");
    methods.clearErrors();
    setLoading(true);

    const errorFile = inputFiles.find(file => {
      return Common.byteConverter(file.file.size, "MB") > 500;
    });

    if (inputFiles.length > 100 || errorFile) {
      setErrorMessage(Config.MESSAGE_NO_E40);
      setLoading(false);
      return
    }

    try {
      const s3 = await axios.get('/api/v1/s3/getSignedPostUrl', {}).then(result => {
        return result.data;
      });
      const uploadPromises = inputFiles.map(file => uploadFile(file, s3.formAttributes, s3.formInputs));
      const results = await Promise.all(uploadPromises);
    } catch (error) {
      setErrorMessage("アップロードに失敗しました。");
      setLoading(false);
      return
    }

    const inputData = methods.getValues();
    const dataDocuments = inputData.dataDocuments.map((dataDocument: any, index: number) => {
      return {
        ...dataDocument,
        original_filename: inputFiles[index].file.name,
        tempPath: inputFiles[index].tempPath,
      }
    });
    const postData = {
      data_documents: dataDocuments,
    };
    const result = await axios.post('/api/v1/dataDocuments/add', postData
    ).then(result => {
      return result.data;
    }).catch(error => {
      if (error?.response?.data?.errors) {
        const errors: object = error?.response?.data?.errors;
        setErrorMessage("入力エラーがあります");
        for (const [index, inputErrors] of Object.entries(errors)) {
          for (const key of Object.keys(inputErrors)) {
            methods.setError(`dataDocuments.${index}.${key}`, {type: 'sever', message: Common.getNestedValue(inputErrors[key])});
          }
        }
      } else if (error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setMessage(Config.MESSAGE_NO_E37);
      }
      return false;
    });
    setLoading(false);
    if (result)
      navigate("/admin/document");
  }

  useEffect(() => {
    setBlocker(Config.isDataEdit(Config.Data.FileNew));
  }, [location.pathname]);

  const onClickCancel = () => {
    setBlocker(false);
    setIsCancelEditConfirm(true);
  }

  const onClickSave = () => {
    setBlocker(false);
    post();
  }

  const onClickFileSelect = () => {
    if (hiddenFileInput.current)
      hiddenFileInput.current.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUploadInfo(Array.from(e.target.files || []));
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setUploadInfo(Array.from(e.dataTransfer.files || []));
  };

  const setUploadInfo = (files: File[]) => {
    const inputFiles: InputFile[] = [];
    const inputs: DataDocumentInputs[] = [];
    const name = `${user?.last_name}${user?.first_name}`;
    const date = new Date();
    files.map(file => {
      inputFiles.push({
        file: file,
        tempPath: file.name + "_" + Math.random().toString(36).substring(2, 15),
        preview: URL.createObjectURL(file),
      });
      inputs.push({
        filename: file.name,
        remarks: "",
      });
    });
    methods.reset({
      dataDocuments: inputs
    });

    setInputFiles(inputFiles);
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div>
      <FormProvider {...methods}>
        <div className="sub-header">
          <div className="uk-flex-between uk-flex uk-flex-middle uk-margin-small-top">
            <div
              onDrop={onDrop}
              onDragOver={onDragOver}
              className="uk-flex uk-flex-middle"
            >
              <button className="uk-button--m uk-button-cancel" onClick={onClickFileSelect}>ファイル選択</button>
              <input type="file" className="uk-hidden" onChange={onFileChange} ref={hiddenFileInput} multiple />
              <div className="uk-margin-small-left">{inputFiles.length > 0 ? `${inputFiles.length}ファイル` : `選択してください`}</div>
            </div>
            <div>
              <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickCancel}>キャンセル</button>
              <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave} disabled={inputFiles.length < 1} >保存</button>
            </div>
          </div>
        </div>

        <div className="container">
          {errorMessage.length > 0 && <div className="error-box">{errorMessage}</div>}
          <div className="file-tbl-wrap file-tbl-new uk-margin-small-top">
            <div className="file-tbl">
              <table>
                {inputFiles.length === 0 && <FileInputDummy />}
                {inputFiles.map(((inputFile, index) => {
                  return <FileInput key={`${inputFile.file.name}.${index}`} index={index} inputFile={inputFile} />
                }))}
              </table>
            </div>
          </div>
        </div>
        {/*<button onClick={()=> {console.log(methods.getValues())}}>debug</button>*/}
      </FormProvider>
      <BlockModal />
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="OK"
        isShow={isCancelEditConfirm}
        onConfirm={() => {
          setIsCancelEditConfirm(false);
          navigate("/admin/document");
        }}
        onCancel={() => {
          setIsCancelEditConfirm(false);
          setBlocker(true);
        }}
      />
    </div>
  );
}