import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { searchUsers } from '@/api/users';
import { MESSAGE_NO_E08 } from '@/config';
import { User } from '@/stores/user';

interface Option {
  value: number;
  label: string;
}
interface SearchBarProps {
  name: string;
  defaultUser: User | null;
}

const UserSearchBar: React.FC<SearchBarProps> = ({ name, defaultUser }) => {
  const { register, control, formState: { errors }, } = useFormContext();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const error = errors.draft_user_id?.message;

  const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    searchUsers(inputValue).then((response) => {
      const users: any[] = response.data.users;

      // Transform data into options format
      const transformedOptions: Option[] = users.map((item) => ({
        value: item.id,
        label: `${item.last_name} ${item.first_name} ${item.email_address}`,
      }));

      callback(transformedOptions);
    }).catch(error => {
      console.error('Error searching users', error);
      callback([]);
    });
  };
  
  return (
    <Controller
      control={control}
      {...register(name)}
      render={({ field }) => (
        <div>
          <AsyncSelect
            {...field}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onChange={(value) => {
              setSelectedOption(value);
              field.onChange(value?.value);
            }}
            classNames={{
              control: (state) => error ? 'error-form' : '',
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: '0 5px',
                minHeight: 'unset',
                height: 'auto',
              }),
              input: (provided) => ({
                ...provided,
                height: '25px',
                padding: 0,
                margin: 0,
              }),
              indicatorsContainer: () => ({
                display: 'none', // Hide the select icon
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: 0,
              }),
            }}
            placeholder={defaultUser ? `${defaultUser.last_name} ${defaultUser.first_name}` : ''}
            value={selectedOption}
          />
          {error && <span className="error">{error.toString()}</span>}
        </div>
      )}
    />
  );
};

export default UserSearchBar;