import 'leaflet/dist/leaflet.css';
import {MapContainer, TileLayer, GeoJSON} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ geojsonData }) => {

  // 中央座標計算
  let center = [0, 0];
  if (geojsonData.features) {
    const coordinates = geojsonData.features.map(feature => {
      const { geometry } = feature;
      if (geometry.type == 'Point') {
        return [geometry.coordinates];
      } else if (geometry.type === 'LineString') {
        return geometry.coordinates;
      } else if (geometry.type === 'Polygon') {
        return geometry.coordinates[0];
      }
    }).flat(1);
    center = coordinates.reduce((acc, curr) => [acc[0] + curr[0], acc[1] + curr[1]], [0, 0]);
    center = [center[1] / coordinates.length, center[0] / coordinates.length];
  }

  const pointToLayer = (feature, latlng) => {
    if (feature.geometry.type === 'Point') {
      const { _iconAnchor, _iconSize, _iconUrl, _markerType } = feature.properties;

      const customIcon = L.icon({
        iconUrl: _iconUrl,
        iconSize: _iconSize,
        iconAnchor: _iconAnchor,
      });
      return L.marker(latlng, { icon: customIcon });
    } else {
      return L.marker(latlng);
    }
  };

  const style = (feature) => {
    const { _color, _fillColor, _fillOpacity, _opacity, _weight, _dashArray } = feature.properties;
    return {
      fillColor: _fillColor,
      weight: _weight,
      opacity: _opacity,
      color: _color,
      fillOpacity: _fillOpacity,
      dashArray: _dashArray,
    };
  }

  return (
    <div>
      <MapContainer
        key={JSON.stringify(geojsonData)}
        className="uk-position-absolute uk-width-1-1 uk-height-1-1 uk-position-z-index-zero uk-position-top"
        center={center}
        zoom={13}
        minZoom={1}
        maxZoom={20}
        scrollWheelZoom={true}>
        <TileLayer
          url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
        />
        <GeoJSON 
          data={geojsonData} 
          style={style}
          pointToLayer={pointToLayer}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
