import { axios } from '@/lib/axios';

interface GetApplyRequestParams {
  data_id: number;
  project_category_id: number;
}

export const getApply = ( params: GetApplyRequestParams  ) => {
  return axios.get('/api/v1/dataApplies/getApply', { params });
};

export const postApplyAreas = ( params: any ) => {
  return axios.post(`/api/v1/datas/postApplyAreas`, params);
};