import React, { HTMLInputTypeAttribute } from 'react';
import { RegisterOptions, DeepMap, FieldError } from 'react-hook-form';

interface props {
  name: string;
  register: any;
  rules?: RegisterOptions;
  errors: DeepMap<any, FieldError>;
  placeholder?: string;
  inputType?: HTMLInputTypeAttribute | undefined;
  min?: string;
  max?: string;
  autocomplete?: string;
}

export const TextBox: React.FC<props> = ( {
                                            name,
                                            register,
                                            rules,
                                            errors,
                                            placeholder,
                                            inputType,
                                            min,
                                            max,
                                            autocomplete
                                          } ) => {
  return (
    <>
      <input name={name} {...register(name, rules)} placeholder={placeholder} type={inputType} min={min} max={max}
             autoComplete={autocomplete}/>
      {/*{errors[name] && <p>{errors[name].message}</p>}*/}
    </>
  );
};
