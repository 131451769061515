import React, { useEffect, useState, ChangeEvent } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SubHeader from '../../components/SubHeader';
import ConfirmModal from '@/components/ConfirmModal';
import { useLoadingStore } from "@/stores/loading";
import {axios} from '@/lib/axios';
import * as Config from '@/config'
import {useMessageModalStore} from '@/stores/message'
import * as Common from '@/utils/common'
import {useCustomerStore} from '@/stores/customer';
import {User, useUserStore} from '@/stores/user'
import qs from 'qs';
import CustomSelect from '@/components/CustomSelect';
import ReactPaginate from 'react-paginate';
import { SubmitHandler, useForm } from "react-hook-form";

interface Customer {
  id: number,
  customer_number: string,
  group_name: string,
  department_name: string,
  section_name: string,
  unit_name: string,
  last_name: string,
  first_name: string,
  customer_type_id: number,
  customer_group_type_id: number,
  customer_payment_type_id: number,
  approval_status: string
  customer_type: string,
  customer_group_type: string,
  customer_payment_type: string,
  end_using_date: string,
  checked: boolean,
}
interface CustomerTypes {
  value: string,
  label: string,
}
interface CustomerGroupTypes {
  value: string,
  label: string,
}
interface CustomerPaymentTypes {
  value: string,
  label: string,
}

export default function CustomersList() {
  const navigate = useNavigate();
  const setLoading = useLoadingStore(state => state.setLoading);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerTypes, setCustomerTypes] = useState<CustomerTypes[]>([]);
  const [customerGroupTypes, setCustomerGroupTypes] = useState<CustomerGroupTypes[]>([]);
  const [customerPaymentTypes, setCustomerPaymentTypes] = useState<CustomerPaymentTypes[]>([]);

  const [selectedCustomer, setSelectCustomer] = useState<number | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useUserStore(state => state.user);

  const [customerQueryParams, setCustomerQueryParams] = useCustomerStore(state => [
    state.queryParams,
    state.setQueryParams,
  ]);
  // 検索項目
  const [customerNumber, setCustomerNumber] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [customerTypeId, setCustomerTypeId] = useState<any[]>([]);
  const [customerGroupTypeId, setCustomerGroupTypeId] = useState<string>("");
  const [customerPaymentTypeId, setCustomerPaymentTypeId] = useState<any[]>([]);
  const [approvalStatus, setApprovalStatus] = useState<any[]>([]);
  const [resetForm, setResetForm] = useState<boolean>(false);
  // 検索結果
  const [searchQueryParam, setSearchQueryParam] = useState<any>(undefined);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  // 検索結果の入力項目
  const [checkIds, setSelectedCustomerIds] = useState<any[]>([]);
  const [paging, setPaging] = useState<any>(undefined);

//  const [selectedPageSize, setSelectedPageSize] = useState<string | undefined>('100')
  const [selectedPageSize, setSelectedPageSize] = useState<string | ''>('100')

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<any>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
    }
  );

  const pageSizeOptions = [
    {
      value: '25',
      label: '25件',
    },
    {
      value: '50',
      label: '50件',
    },
    {
      value: '100',
      label: '100件',
    },
    {
      value: '200',
      label: '200件',
    },
  ]

  const breadCrumbs = [
    { title: '顧客一覧' },
  ];

  const canCreate = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
        return true;
      case Config.PERMISSION_ROLL_ADMIN:
      case Config.PERMISSION_ROLL_USER:
        return user.user_restrictions.find((user_restriction) => {
          return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PROGRESS_NEW
            && user_restriction.category_restriction_value === 1
        }) !== undefined;
      default:
        return false;
    }
  }
  const canDelete = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
        return true;
      case Config.PERMISSION_ROLL_ADMIN:
      case Config.PERMISSION_ROLL_USER:
        return user.user_restrictions.find((user_restriction) => {
          return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PROGRESS_DEL
            && user_restriction.category_restriction_value === 1
        }) !== undefined;
      default:
        return false;
    }
  }

  useEffect(() => {
    // URLパラメータから検索条件を取得し、状態に反映する
    const params = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });
console.log(params);    
    if (typeof params.customer_number === 'string') {
      setCustomerNumber(params.customer_number);
    }
    if (typeof params.group_name === 'string') {
      setGroupName(params.group_name);
    }
    if (typeof params.last_name === 'string') {
      setLastName(params.last_name);
    }
    if (typeof params.first_name === 'string') {
      setFirstName(params.first_name);
    }
    // 団体区分
    if (typeof params.customer_group_type_id === 'string') {
      setCustomerGroupTypeId(params.customer_group_type_id);
    }
    // 問合せ者区分
    if (Array.isArray(params.customer_type_id)) {
      const numbers = params.customer_type_id.map(
        value => typeof value === "string" ? parseInt(value) : null
      ).filter(value => value != null);
      setCustomerTypeId(numbers);
    } else if (typeof params.customer_type_id === 'string') {
      setCustomerTypeId([parseInt(params.customer_type_id)]);
    }
    // 課金区分
    if (Array.isArray(params.customer_payment_type_id)) {
      const numbers = params.customer_payment_type_id.map(
        value => typeof value === "string" ? parseInt(value) : null
      ).filter(value => value != null);
      setCustomerPaymentTypeId(numbers);
    } else if (typeof params.customer_payment_type_id === 'string') {
      setCustomerPaymentTypeId([parseInt(params.customer_payment_type_id)]);
    }
    // 承認状況
    if (Array.isArray(params.approval_status)) {
      setApprovalStatus(params.approval_status);
    } else if (typeof params.approval_status === 'string') {
      setApprovalStatus([params.approval_status]);
    }
    if (typeof params.page_size === 'string') {
      setSelectedPageSize(params.page_size);
    }
  }, [searchParams]);

  useEffect(() => {
    axios.get('/api/v1/customers/getCustomerTypes')
    .then(result => {
      setCustomerTypes(result.data.customerTypes);
      setCustomerGroupTypes(result.data.customerGroupTypes);
      setCustomerPaymentTypes(result.data.customerPaymentTypes);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  // 検索処理
  function doSearch(params: any, page=1) {
    //console.log("------ doSearch -----");
    console.log("----- params ------");
    console.log(params);

    setLoading(true);
    axios.get('/api/v1/customers/getCustomers', {
      //params
      params: params
    }).then(result => {
      console.log(result.data)
      console.log(result.data.pgiongoarams)
      setCustomers(result.data.customers);
      setSearchQueryParam(result.data.params);
      setPaging(result.data.paging);
      setSearchParams(qs.stringify(params));
      setCustomerQueryParams(params);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  const [checkAll, setCheckAll] = useState(false);
  const [checkStates, setCheckStates] = useState(Array(20).fill(false));

  // チェックボックスの状態が変更されたときに呼び出される関数
  const handleCheckboxChange = (id: number) => {
    const updatedCustomers = customers.map(customer => {
      if (customer.id === id) {
        return { ...customer, checked: !customer.checked };
      }
      return customer;
    });
    setCustomers(updatedCustomers);

    // チェックされた顧客のIDを更新
    const selectedIds = updatedCustomers.filter(customer => customer.checked).map(customer => customer.id);
    setSelectedCustomerIds(selectedIds);

    const newCheckStates = checkStates.map((value, i) => i === id ? !value : value);
    setCheckStates(newCheckStates);
    if (newCheckStates.every((value) => value === true)) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  // 一括削除
  function deleteIds() {
    setModalConfirm({callback: () => {
      setLoading(true);
      axios.post('/api/v1/customers/deleteByIds', {
        ids: Array.from(checkIds),
      }).then(result => {
        doSearch({
          ...customerQueryParams,
          page: 1
        });
      }).catch(error => {
        setMessage(Config.MESSAGE_NO_E39);
      }).finally(() => {
        setLoading(false);
      });
    }});
  }

  const onClickNew = () => {
    navigate("/customer/basicinfo/new")
  }

  const onClickCheckAll = () => {
    setCheckAll(!checkAll);
    if (checkAll) {
      setCheckStates(Array(25).fill(false));
    } else {
      setCheckStates(Array(25).fill(true));
    }
  }

  const onClickDeleteIds = () => {
    deleteIds();
  }

  const onClickUsageHistory = () => {
    navigate("/customer/apiUsage")
  }

  useEffect(() => {
    if (customerQueryParams) {
      setSearchQueryParam(customerQueryParams);
    }
    doSearch(customerQueryParams, 1);
  }, [selectedPageSize]);

  const onSelectedPageSize = (pageSize: string) => {
    setSelectedPageSize(pageSize);
    doSearch({
      ...customerQueryParams,
      page_size: pageSize,
      page: 1
    });

  }

  const onPageChange = (page: number) => {
    doSearch({
      ...customerQueryParams,
      page: page
    });
  }

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    } else {
      setIsShowErrorModal(false);
    }
  }, [errorMessage]);

  console.log("-----customers-----");
  console.log(customers);
  console.log(searchQueryParam);

  function getCustomerStatusLabel(databaseValue: string) {
    const statusObject = Config.CUSTOMER_STATUS.find(status => status.value == databaseValue);
    return statusObject ? statusObject.label : "";
  }

  const onSearch = () => {
    const currentSelectedPageSize = selectedPageSize; // 最新のselectedPageSizeの値を保持する
    const params = {
      customer_number: customerNumber,
      group_name: groupName,
      last_name: lastName,
      first_name: firstName,
      customer_group_type_id: customerGroupTypeId,
      customer_type_id: customerTypeId,
      customer_payment_type_id: customerPaymentTypeId,
      approval_status: approvalStatus,
      page_size: currentSelectedPageSize
    }
    //console.log("-----検索ボタン押下-----");
    //console.log(params);
    doSearch(params, 1);
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} />
      </div>

      <div className="container client-container uk-margin-small-top">
        <form>
          <table className="condition-tbl">
            <tbody>
            <tr className="header">
              <th>顧客番号</th>
              <th>法人・団体名</th>
              <th>姓</th>
              <th>名</th>
              <th>問合せ者区分</th>
              <th>団体区分</th>
              <th>課金区分</th>
              <th>承認状況</th>
            </tr>
            <tr>
              <td>
                <input
                  className="w-100"
                  type="text"
                  value={customerNumber}
                  onChange={(e) => setCustomerNumber(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </td>
              <td>
                <input
                  className="w-100"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </td>
              <td>
                <input
                  className="w-100"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </td>
              <td>
                <div className="uk-flex uk-flex-wrap">
                  {customerTypes?.map(customerType => (
                    <label key={customerType.value} className="uk-margin-small-right">
                      <input
                        type="checkbox"
                        name="customer_type_id[]"
                        value={customerType.value}
                        onChange={(e) => {
                          const selectedValue = parseInt(e.target.value, 10);
                          const updatedIds = [...customerTypeId];
                          if (e.target.checked) {
                            updatedIds.push(selectedValue);
                          } else {
                            const index = updatedIds.indexOf(selectedValue);
                            if (index !== -1) {
                              updatedIds.splice(index, 1);
                            }
                          }
                          setCustomerTypeId(updatedIds);
                        }}
                        checked={customerTypeId.includes(customerType.value)}
                      />
                      {customerType.label}
                    </label>
                  ))}
                </div>
              </td>
              <td>
                <div>
                  <select 
                    className={`${customerGroupTypeId ? '' : 'placeholder'} ${errors.area_id ? "error-form" : ""}`}
                    defaultValue={""}
                    value={customerGroupTypeId}
                    name="customer_group_type_id"
                    onChange={(e) => {
                      setCustomerGroupTypeId(e.target.value);
                    }}
                  >
                    <option value="">団体区分選択</option>
                    {customerGroupTypes?.map(( item, index ) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
              <td>
                <div className="uk-flex uk-flex-wrap">
                  {customerPaymentTypes?.map(customerPaymentType => (
                    <label key={customerPaymentType.value} className="uk-margin-small-right">
                      <input
                        type="checkbox"
                        name="customer_payment_type_id[]"
                        value={customerPaymentType.value}
                        onChange={(e) => {
                          const selectedValue = parseInt(e.target.value, 10);
                          let updatedIds = [...customerPaymentTypeId, selectedValue];
                          if (customerPaymentTypeId.includes(selectedValue)) {
                            updatedIds = updatedIds.filter(day => day != selectedValue);
                          }
                          setCustomerPaymentTypeId(updatedIds);
                        }}
                        checked={customerPaymentTypeId.includes(customerPaymentType.value)}
                      />
                      {customerPaymentType.value}:{customerPaymentType.label}
                    </label>
                  ))}
                </div>
              </td>
              <td>
                <div className="uk-flex uk-flex-wrap">
                  {Config.CUSTOMER_STATUS?.map(customerStatus => (
                    <label key={customerStatus.value} className="uk-margin-small-right">
                      <input
                        type="checkbox"
                        name="approval_status[]"
                        value={customerStatus.value}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const updatedIds = [...approvalStatus];
                          if (e.target.checked) {
                            updatedIds.push(selectedValue);
                          } else {
                            const index = updatedIds.indexOf(selectedValue);
                            if (index !== -1) {
                              updatedIds.splice(index, 1);
                            }
                          }
                          setApprovalStatus(updatedIds);
                        }}
                        checked={approvalStatus.includes(customerStatus.value)}
                      />
                      {customerStatus.value}:{customerStatus.label}
                    </label>
                  ))}
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan={8}>
                <div className="uk-flex uk-flex-center">
                  <button type="button" className="uk-button--m uk-button-refer" onClick={() => onSearch()}>検索</button>
                  <button className="uk-button--m uk-button-cancel uk-margin-left">クリア</button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
        <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
          <div className="uk-inline">
            <select className="table-length" defaultValue={selectedPageSize} onChange={(e) => onSelectedPageSize(e.target.value)}>
              <option value="" disabled>表示件数</option>
              {pageSizeOptions.map((o: any) => {
                return <option value={o.value}>{o.label}</option>
              })}
            </select>
          </div>
          <div>
            {canCreate(user) &&
                <>
                  <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickUsageHistory}>利用履歴</button>
                </>
            }
            {canDelete(user) && <button className="uk-button--m uk-margin-left uk-button-cancel" onClick={onClickDeleteIds} disabled={checkIds.length == 0}>一括削除</button>}
            {canCreate(user) &&
                <>
                    <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成</button>
                </>
            }
          </div>
        </div>

        <div className="client-tbl-wrap mt-5">
          <table className="client-tbl">
            <thead>
            <tr className="header">
              <th>
                <div className="border-box"/>
                <div className="uk-flex uk-flex-center">
                  <input
                    type="checkbox"
                    onChange={onClickCheckAll}
                    checked={checkAll}
                  />
                </div>
              </th>
              <th>
                <div className="border-box"/>
                顧客番号
              </th>
              <th>
                <div className="border-box"/>
                法人・団体名
              </th>
              <th>
                <div className="border-box"/>
                部署名
              </th>
              <th>
                <div className="border-box"/>
                課名
              </th>
              <th>
                <div className="border-box"/>
                室名または係名
              </th>
              <th>
                <div className="border-box"/>
                姓
              </th>
              <th>
                <div className="border-box"/>
                名
              </th>
              <th>
                <div className="border-box"/>
                利用終了日
              </th>
              <th>
                <div className="border-box"/>
                問合せ者区分
              </th>
              <th>
                <div className="border-box"/>
                団体区分
              </th>
              <th>
                <div className="border-box"/>
                課金区分
              </th>
              <th>
                <div className="border-box"/>
                承認状況
              </th>
            </tr>
            </thead>
            <tbody>
            {customers?.map(( item, index ) => (
              <tr className={checkStates[item.id] ? "selected" : ""}>
                <td className={checkStates[item.id] ? "selected" : ""}>
                  <div className="border-box"/>
                  <div className="uk-flex uk-flex-center">
                    <input
                      type="checkbox"
                      checked={checkStates[item.id]}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </div>
                </td>
                <td className={"uk-text-left" + (checkStates[item.id] ? " selected" : "")}>
                  <div className="border-box"/>
                  <Link to={`/customer/basicinfo/detail/${item.id}`}>{item.customer_number}</Link>
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.group_name}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.department_name}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.section_name}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.unit_name}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.last_name}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.first_name}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.end_using_date}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.customer_type_id}.{item.customer_type}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.customer_group_type_id}.{item.customer_group_type}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {item.customer_payment_type_id}.{item.customer_payment_type}
                </td>
                <td className="uk-text-left">
                  <div className="border-box"/>
                  {getCustomerStatusLabel(item.approval_status)}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      {paging &&
          <>
            <div
              className="uk-text-center mt-5">全{paging.count}件（{paging.page}/{paging.pageCount}）
            </div>
            <div className="pagination-container">
              <a onClick={() => {
                onPageChange(1)
              }}>FIRST</a>
              <ReactPaginate
                forcePage={paging.page - 1} // ReactPaginate→0始まり CakePHP→1始まり
                onPageChange={( selectedItem: { selected: number } ) => onPageChange(selectedItem.selected + 1)}
                pageRangeDisplayed={5}
                pageCount={paging.pageCount}
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                previousLabel='<'
                nextLabel='>'
                breakLabel='...'
              />
              <a onClick={() => {
                onPageChange(paging.pageCount)
              }}>LAST</a>
            </div>
            </>
        }
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
      <ConfirmModal
        text={Config.MESSAGE_NO_E05}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if (modalConfirm) modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  );
}
