import React, { useState } from 'react';
import { RegisterOptions, DeepMap, FieldError, Merge, FieldErrorsImpl } from 'react-hook-form';

interface Option {
  label: string;
  value: string;
}

interface props {
  name: string;
  options: Option[];
  register: any;
  className?: string;
  rules?: RegisterOptions;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  value?: string | ReadonlyArray<string> | number | undefined;
}

export const SelectBox: React.FC<props> = ( { name, options, register, className, rules, error, value} ) => {
  return (
    <>
      <select className={`${className} ${error ? "error-form" : ""}`}
        value={value}
        {...register(name, rules)} >
        <option value="">選択してください</option>
        {options.map(( option, index ) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="error">{`${error}`}</p>}
    </>
  );
};