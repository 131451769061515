import {useLocation, useNavigate} from 'react-router-dom';
import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
import { useAxiosRouteStore } from '@/stores/axiosRoute';
import {useUserStore} from '@/stores/user'

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(config => {
    config.headers.Accept = 'application/json';
    return config
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            useAxiosRouteStore.getState().setAxiosRoute('/login');
            useUserStore.getState().setUser(undefined);
        }

        return Promise.reject(error);
    }
);

axios.defaults.withCredentials = true