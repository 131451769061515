import React, {useEffect, useRef, useState} from "react";
import {basicMapStore} from "@/stores/basicMap";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {useBasicMap} from "@/utils/useBasicMap";
import * as Common from "@/utils/common";
import {UpdatedCityInput, UpdatedCityList} from "@/components/Attributes/BasicMap/UpdatedCityList";
import {useUserStore} from "@/stores/user";
import {BasicMapCity, GetDataBasicMapCitiesParams} from "@/components/Attributes/BasicMap/types";

interface Props {
  GetDataBasicMapCitiesParams: GetDataBasicMapCitiesParams
  attribute: any
}

interface FormErrors {
  basic_map_cities?: {
    message?: {
      message: string;
    };
    [key: number]: {
      message?: string;
    };
  };
}

export const EditUpdatedMunicipalitiesContainer = (props: Props) => {
  const {
    register,
    setValue,
    control,
    formState: {errors},
  } = useFormContext<any>();
  const user = useUserStore(state => state.user);

  const [open, setOpen] = useState(false);
  const [newBasicMapData, setNewBasicMapData] = useState({} as BasicMapCity);
  const [resetTrigger, setResetTrigger] = useState(0);

  const {
    fields,
    replace,
    append,
    remove
  } = useFieldArray<{
    basic_map_cities: BasicMapCity[]
  }, "basic_map_cities", "fieldId">({
    control,
    name: 'basic_map_cities',
    keyName: "fieldId",
  })

  const typedErrors = errors as unknown as FormErrors;
  const canAddBasicMapData = fields.length <= 60;
  const dataAttributeValueId = props.GetDataBasicMapCitiesParams.data_attribute_value_id;
  const textareaRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const {
    dicCities,
    fetchDicCities,
    updatedCityStatus,
    fetchUpdatedCityStatus,
    basicMapCities,
    fetchBasicMapCities,
    reset
  } = basicMapStore();
  const {isEmptyObject, findBasicMapOption, adjustTextareaHeight} = useBasicMap();

  const selectedAreaId = useWatch({
    control,
    name: 'area_id',
  });
  const watchBasicMap = useWatch({
    control,
    name: 'basic_map_cities',
  });

  const onClickAddBasicMapData = () => {
    const newData: BasicMapCity = {
      ...newBasicMapData,
      id: undefined,
      created: new Date().toISOString(),
      created_name: `${user?.last_name}${user?.first_name}`,
    } as BasicMapCity;
    append(newData);

    setNewBasicMapData({});
    setResetTrigger(prevState => prevState + 1);
  }

  const updateNewBasicMapData = (updates: BasicMapCity) => {
    setNewBasicMapData(prevData => ({
      ...prevData,
      ...updates
    }));
  };

  //更新市町村マスタデータ取得
  useEffect(() => {
    fetchUpdatedCityStatus();
    return () => {
      reset();
      remove();
    }
  }, []);

  //data_attribute_value_id取得後市町村取得
  useEffect(() => {
    if (dataAttributeValueId) {
      fetchBasicMapCities(props.GetDataBasicMapCitiesParams);
    }
  }, [dataAttributeValueId]);

  //更新市町村データ取得時とマスタ変更時にform値の更新
  useEffect(() => {
    if (dicCities) {
      replace(basicMapCities);
    }
  }, [basicMapCities, dicCities]);

  //テキストエリアサイズ自動変更
  useEffect(() => {
    textareaRefs.current.forEach(textarea => {
      if (textarea) {
        adjustTextareaHeight(textarea);
      }
    });
  }, [fields])

  //更新対象市町村のdata_attribute_valuesの値反映
  useEffect(() => {
    if (dicCities) {
      let DAV = "";
      watchBasicMap?.forEach((data: BasicMapCity) => {
        const cityName = dicCities.find(city => city.code == data.update_target_city_code)?.name;
        if (cityName) {
          DAV = (DAV.length == 0) ? cityName : DAV + "," + cityName;
        }
      })
      setValue(`attributes.${props.attribute.id}`, DAV);
    }
  }, [watchBasicMap, dicCities]);

  //エリアid変更で市町村マスタ取得
  useEffect(() => {
    if (selectedAreaId) {
      fetchDicCities(Number(selectedAreaId));
    }
  }, [selectedAreaId])

  if (isEmptyObject(updatedCityStatus) || !dicCities) return null;

  return (
    <>
      <tr className="updated-municipalities-container uk-margin-small-top toggle-wrapper">
        <td colSpan={2} className="toggle-area">
          <div className="uk-text-right">
            <button type="button"
                    className="uk-button uk-button-text uk-text-primary"
                    onClick={() => setOpen((prev) => !prev)}
            >
              {open ? "▲" : "▼"} 市区町村内訳
            </button>
          </div>
          {typedErrors.basic_map_cities && typedErrors.basic_map_cities["message"] &&
              <div className="error">{`${typedErrors.basic_map_cities["message"]?.message}`}</div>}
          <div className={`toggle-contents uk-overflow-auto ${!open && "hidden"}`}>
            <table className="mt-5 mb-30">
              <thead>
              <tr>
                <th className="uk-text-center">
                  <div className="border-box"/>
                  更新対象市区町村名
                  <UpdatedCityList cities={dicCities}/>
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  作業担当
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  作成日時
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  都市部（㎢）
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  平野部（㎢）
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  その他（㎢）
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  ステータス
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  最終更新担当
                </th>
                <th className="uk-text-center width-10p">
                  <div className="border-box"/>
                  最終更新日時
                </th>
                <th className="uk-text-center">
                  <div className="border-box"/>
                  備考
                </th>
                <th className="uk-text-center border-right-2">
                  <div className="border-box"/>
                </th>
              </tr>
              </thead>
              <tbody>
              {fields?.map((data, index) => {
                const unEditable = data?.initial_registration_flag === 1;
                return (
                  <tr key={index}>
                    <td className="uk-text-center">
                      <input
                        type="hidden"
                        {...register(`basic_map_cities[${index}].id`)}
                        value={data?.id ?? undefined}/>
                      <input
                        type="hidden"
                        {...register(`basic_map_cities[${index}].initial_registration_flag`)}
                        value={unEditable ? 1 : 0}/>
                      {unEditable ? data?.update_target_city_name
                        :
                        <>
                          <UpdatedCityInput
                            cities={dicCities} name={`basic_map_cities[${index}].update_target_city_code`}
                            defaultCode={String(data?.update_target_city_code) ?? ""}
                          />
                          {typedErrors.basic_map_cities && typedErrors.basic_map_cities[index] &&
                              <div className="error">{`${typedErrors.basic_map_cities[index]?.message}`}</div>}
                        </>
                      }
                    </td>
                    <td className="uk-text-center">{data?.created_name}</td>
                    <td className="uk-text-center">{data?.created ? Common.formatDate(String(data?.created)) : ""}</td>
                    <td className="uk-text-center">
                      {unEditable ? data?.urban_area :
                        <input
                          defaultValue={data?.urban_area ?? ""}
                          {...register(`basic_map_cities[${index}].urban_area`)} />
                      }
                    </td>
                    <td className="uk-text-center">
                      {unEditable ? data?.plain_area :
                        <input
                          defaultValue={data?.plain_area ?? ""}
                          {...register(`basic_map_cities[${index}].plain_area`)} />
                      }
                    </td>
                    <td className="uk-text-center">
                      {unEditable ? data?.other_area :
                        <input
                          defaultValue={data?.other_area ?? ""}
                          {...register(`basic_map_cities[${index}].other_area`)} />
                      }
                    </td>
                    <td className="uk-text-center">
                      {unEditable && data?.status ? findBasicMapOption(data?.status, updatedCityStatus) :
                        <select
                          defaultValue={data?.status ?? ""}
                          {...register(`basic_map_cities[${index}].status`)}>
                          {updatedCityStatus?.map(option => (
                            <option key={option.id} value={option.id}>{option.name}</option>
                          ))}
                        </select>
                      }
                    </td>
                    <td className="uk-text-center">{data?.modified_name}</td>
                    <td
                      className="uk-text-center">{data?.modified ? Common.formatDate(String(data?.modified)) : ""}</td>
                    <td className="uk-text-center">
                      {unEditable ? data?.remarks :
                        <textarea
                          {...register(`basic_map_cities[${index}].remarks`)}
                          defaultValue={data?.remarks ?? ""}
                          ref={(el) => {
                            textareaRefs.current[index] = el;
                            if (el) adjustTextareaHeight(el);
                          }}
                          onChange={(ev) => {
                            adjustTextareaHeight(ev.currentTarget);
                            setValue(`basic_map_cities[${index}].remarks`, ev.target.value);
                          }}/>
                      }
                    </td>
                    <td className="uk-text-center">
                      <a hidden={unEditable} onClick={() => remove(index)}>削除</a>
                    </td>
                  </tr>
                )
              })}
              <tr className="record-space">
                <td colSpan={11}></td>
              </tr>
              <tr>
                <td className="uk-text-center">
                  <input
                    key={`new-update_target_city_code-${resetTrigger}`}
                    list="updated-city-list"
                    value={dicCities.find(city => city.code == newBasicMapData.update_target_city_code)?.name}
                    onChange={e => updateNewBasicMapData(
                      {update_target_city_code: dicCities.find(city => city.name == e.target.value)?.code ?? undefined}
                    )}/>
                </td>
                <td className="uk-text-center"></td>
                <td className="uk-text-center"></td>
                <td className="uk-text-center">
                  <input
                    key={`new-urban_area-${resetTrigger}`}
                    value={newBasicMapData.urban_area}
                    onChange={e => updateNewBasicMapData(
                      {urban_area: Number(e.target.value)}
                    )}/>
                </td>
                <td className="uk-text-center">
                  <input
                    key={`new-plain_area-${resetTrigger}`}
                    value={newBasicMapData.plain_area}
                    onChange={e => updateNewBasicMapData(
                      {plain_area: Number(e.target.value)}
                    )}/>
                </td>
                <td className="uk-text-center">
                  <input
                    key={`new-other_area-${resetTrigger}`}
                    value={newBasicMapData.other_area}
                    onChange={e => updateNewBasicMapData(
                      {other_area: Number(e.target.value)}
                    )}/>
                </td>
                <td className="uk-text-center">
                  <select
                    key={`new-status-${resetTrigger}`}
                    value={newBasicMapData.status}
                    onChange={e => updateNewBasicMapData(
                      {status: Number(e.target.value)}
                    )}>
                    {updatedCityStatus.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </td>
                <td className="uk-text-center"></td>
                <td className="uk-text-center"></td>
                <td className="uk-text-center">
                <textarea
                  key={`new-remarks-${resetTrigger}`}
                  value={newBasicMapData.remarks}
                  ref={(el) => {
                    if (el) adjustTextareaHeight(el);
                  }}
                  onChange={(ev) => {
                    adjustTextareaHeight(ev.currentTarget);
                    updateNewBasicMapData({remarks: ev.target.value})
                  }}/>
                </td>
                <td className="uk-text-center">
                  <button className="uk-button--m uk-button-refer width-4r" type="button"
                          onClick={onClickAddBasicMapData}
                          disabled={!canAddBasicMapData}>
                    追加
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  )
}