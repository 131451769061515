import {useEffect, useState} from "react";
import {axios} from "@/lib/axios";
import * as Config from "@/config";
import {drmPfInfo} from "@/containers/customer/drmPf/types";
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";
import {useNavigate, useParams} from "react-router-dom";
import {useCustomerStore} from "@/stores/customer";
import {getDrmPfBaseData} from "@/api/customer";
import {DrmPfPage} from "@/config";

export function useDrmPfDetail() {
  const params = useParams();
  const id = params.customerId;
  const navigate = useNavigate();
  const onClickEdit = () => {
    navigate(`/customer/drmpf/edit/${id}`);
  }

  const [approved, setApproved] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const setDrmPfCommonState = useCustomerStore(state => state.setDrmPfCommonState);

  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [creditCardStatus, setCreditCardStatus] = useState<string | null>(null);
  const [creditStatus, setCreditStatus] = useState<string | null>(null);
  const [isSecondaryUse, setIsSecondaryUse] = useState<string | null>(null);
  const [useMethod, setUseMethod] = useState<string | null>(null);
  const [usePurpose, setUsePurpose] = useState<string | null>(null);
  const [systemStructure, setSystemStructure] = useState<string | null>(null);
  const [isAgree, setIsAgree] = useState<string | null>(null);
  const [isBilling, setIsBilling] = useState<string | null>(null);
  const [drmPfInfo, setDrmPfInfo] = useState<drmPfInfo[]>([]);
  const [applyUsingDate, setApplyUsingDate] = useState<string | null>(null);
  const [startUsingMonth, setStartUsingMonth] = useState<string | null>(null);
  const [businessType, setBusinessType] = useState<string | null>(null);
  const [billingType, setBillingType] = useState<string | null>(null);
  const [customerNumber, setCustomerNumber] = useState<string | undefined>(undefined);
  const [approvalStatus, setApprovalStatus] = useState<string | undefined>(undefined);


  useEffect(() => {
    getDrmPfBaseData(id)
      .then(result => {
        console.log(result.data);
        setDrmPfInfo(result.data.drmpf);
        setApprovalStatus(result.data.drmpf[0]['approval_status']);
        if (result.data.drmpf[0]['customer_number'] != null) {
          setCustomerNumber(result.data.drmpf[0]['customer_number']);
        } else {
          setCustomerNumber("");
        }
        // 事業所区分
        if (result.data.drmpf[0]['business_entity_type'] != null) {
          const entry_type = Config.BUSINESS_ENTITY_TYPE.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['business_entity_type']);
          })
          setBusinessType(entry_type[0]['label']);
        } else {
          setBusinessType('');
        }
        // 請求先
        if (result.data.drmpf[0]['billing_post_type'] != null) {
          const billing_type = Config.BILLING_POST_TYPE.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['billing_post_type']);
          })
          setBillingType(billing_type[0]['label']);
        } else {
          setBillingType('');
        }
        // 決済方法
        if (result.data.drmpf[0]['payment_method'] != null) {
          const payment_method = Config.PAYMENT_METHOD.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['payment_method']);
          });
          setPaymentMethod(payment_method[0]['label']);
        } else {
          setPaymentMethod('');
        }
        // クレジットカード登録状況
        if (result.data.drmpf[0]['payment_method'] == Config.PAYMENT_METHOD_CREDIT_CARD) {
          if (result.data.drmpf[0]['payment_method_register_status'] == Config.PAYMENT_METHOD_REGISTER_STATUS_COMPLETE) {
            setCreditCardStatus('登録完了');
          } else {
            setCreditCardStatus('未登録');
          }
        } else {
          setCreditCardStatus('-');
        }
        // 与信審査
        if (result.data.drmpf[0]['company_credit_status'] != null) {
          const credit_status = Config.CREDIT_STATUS.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['company_credit_status']);
          });
          setCreditStatus(credit_status[0]['label']);
        } else {
          setCreditStatus('-');
        }
        // 利用目的
        if (result.data.drmpf[0]['use_purpose'] != null) {
          // 道路管理者
          if (result.data.drmpf[0]['customer_type_id'] == Config.CUSTOMER_TYPE_ROAD_MANAGER) {
            const use_purpose = Config.USE_PURPOSE.filter((output, index) => {
              return output.value.includes(result.data.drmpf[0]['use_purpose']);
            });
            if (use_purpose.length > 0) {
              setUsePurpose(use_purpose[0]['label']);
            }
            // 道路管理者以外
          } else if (result.data.drmpf[0]['customer_type_id'] == Config.CUSTOMER_TYPE_NOT_ROAD_MANAGER) {
            const use_purpose = Config.USE_PURPOSE2.filter((output, index) => {
              return output.value.includes(result.data.drmpf[0]['use_purpose']);
            });
            if (use_purpose.length > 0) {
              setUsePurpose(use_purpose[0]['label']);
            }
          }
        } else {
          setUsePurpose('');
        }
        // システム体系
        if (result.data.drmpf[0]['system_structure'] != null && result.data.drmpf[0]['system_structure'] != 0) {
          const system_structure = Config.SYSTEM_STRUCTURE.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['system_structure']);
          });
          setSystemStructure(system_structure[0]['label']);
        } else {
          setSystemStructure('');
        }
        // DRM-PFの二次利用
        if (result.data.drmpf[0]['is_secondary_use'] != null) {
          if (result.data.drmpf[0]['is_secondary_use'] == Config.IS_SECONDARY_USE) {
            setIsSecondaryUse('1.利用する');
          } else {
            setIsSecondaryUse('0.利用しない');
          }
        } else {
          setIsSecondaryUse('0.利用しない');
        }
        // 二次利用方法
        if (result.data.drmpf[0]['secondary_use_method'] != null) {
          const use_method = Config.USE_METHOD.filter((output, index) => {
            return output.value.includes(result.data.drmpf[0]['secondary_use_method']);
          });
          setUseMethod(use_method[0]['label']);
        } else {
          setUseMethod('');
        }
        // 同意
        if (result.data.drmpf[0]['is_terms_use_agree'] != null) {
          setIsAgree(Config.IS_AGREE[result.data.drmpf[0]['is_terms_use_agree']]);
        }
        if (result.data.drmpf[0]['is_billing_outsourcing'] != null) {
          setIsBilling(Config.IS_AGREE[result.data.drmpf[0]['is_billing_outsourcing']]);
        }

        if (result.data.drmpf[0]['apply_using_date'] != null) {
          const apply_using_date = new Date(result.data.drmpf[0]['apply_using_date']);
          setApplyUsingDate(`${apply_using_date.getFullYear()}年${apply_using_date.getMonth() + 1}月${apply_using_date.getDate()}日`);
        } else {
          setApplyUsingDate('');
        }
        if (result.data.drmpf[0]['start_using_month'] != null) {
          const start_using_month = new Date(result.data.drmpf[0]['start_using_month']);
          setStartUsingMonth(`${start_using_month.getFullYear()}年${start_using_month.getMonth() + 1}月`);
        } else {
          setStartUsingMonth('');
        }
      }).catch(error => {
      alert(error.message);
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setDrmPfCommonState({customerNumber: customerNumber})
  }, [customerNumber]);

  useEffect(() => {
    const info = drmPfInfo[0];
    setDrmPfCommonState({modified: info?.modified, modified_by: info?.modified_by})
  }, [drmPfInfo]);


  const onConfirm = async () => {
    /*
    setApproved(true);
    setIsShowModal(false);

    // 仮置き
    if (drmPfInfo[0]?.payment_method == '1') {
      setCreditCardStatus('1.登録待ち');
    } else if (drmPfInfo[0]?.payment_method == '15' || drmPfInfo[0]?.payment_method == '16') {
      setCreditStatus('2.申請中');
    }
    */
    if (drmPfInfo[0]?.customer_payment_type_id == null) {
      alert('決済方法が登録されていない為、承認できません。');
    } else {

      // 無償でない場合、請求管理ロボに連携
      if (drmPfInfo[0]?.customer_payment_type_id != Config.CUSTOMER_PAYMENT_TYPE_FREE) {
        axios.get(`/api/v1/billingRobo/billingBulkUpsert?id=${id}&is_new=1`, {
          params: {
            id: id,
          }
        })
          .then(result => {
            console.log(result.data);
            //alert(result.data.status);
            if (result.data.status == 1) {
              setApproved(true);
              setIsShowModal(false);
              setApprovalStatus(Config.CUSTOMER_STATUS_APPROVAL);
              console.log('API リクエストが成功しました');
              window.location.reload();
            } else if (result.data.status == -3) {
              alert('決済方法が登録されていない為、承認できません。');
            } else if (result.data.status == -4) {
              alert("与信審査の登録に失敗しました。\n事業体区分が法人であることを確認し、会社代表者名、法人番号、会社URLの入力をご確認ください。");
            } else if (result.data.status >= 1000) {
              alert('請求管理ロボの連携に失敗しました。');
            }
          }).catch(error => {
          alert(error.message);
          setMessage(Config.MESSAGE_NO_E37);
        }).finally(() => {
          setLoading(false);
        });

        // 無償の場合、ID・PW発行
      } else {

        // ID・パスワード発行
        axios.get(`/api/v1/customers/setIdPassword?id=${id}`, {
          params: {
            id: id,
          }
        })
          .then(result => {
            console.log(result.data);
            //if (result.data.status != 1) {
            //  alert('ID・パスワードの登録に失敗しました。');
            //}
            if (result.data.status == -22) {
              alert(result.data.message);
              setMessage(Config.MESSAGE_NO_E37);
            } else {
              setApproved(true);
              setIsShowModal(false);
              setApprovalStatus(Config.CUSTOMER_STATUS_APPROVAL);
              console.log('API リクエストが成功しました');
            }
          }).catch(error => {
          alert(error.message);
          setMessage(Config.MESSAGE_NO_E37);
        }).finally(() => {
          setLoading(false);
        });
      }
    }
  };

  return {
    customerNumber,
    drmPfInfo,
    onClickEdit,
    isShowModal,
    onConfirm,
    setIsShowModal,
    approvalStatus,
    paymentMethod,
    creditCardStatus,
    creditStatus,
    usePurpose,
    systemStructure,
    isSecondaryUse,
    useMethod,
    applyUsingDate,
    startUsingMonth,
    billingType,
    businessType,
    isAgree,
    isBilling,
  };
}


export function CurrentTab(pathname: string) {
  if (pathname.includes('/api')) {
    return DrmPfPage.Api;
  } else if (pathname.includes('/edit')) {
    return DrmPfPage.Edit;
  } else {
    return DrmPfPage.Detail;
  }
}