import SubHeader from '@/components/SubHeader';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { searchTicket } from "@/api/ticket";
import { useLoadingStore } from "@/stores/loading";
import { useForm } from "react-hook-form";
import * as Config from "@/config";
import { useUserStore } from "@/stores/user";
import { useProjectStore } from "@/stores/project";
import * as Common from "@/utils/common";

interface Ticket {
  data_id: number
  data_ticket_id: number
  link_value: number
  area_name: string
  block_name: string
  comment: string
}

interface Area {
  id: number
  name: string
  block_list: Block[]
}

interface Block {
  id: number
  name: string
}

interface SearchParams {
  // area_id: number | string | null
  // block_id: number | string | null
  keyword: string | null
}

//Area,Blockによる検索は削除になったが、今後追加されるかもしれないためコメントアウト
export default function TicketSearch() {
  const setLoading = useLoadingStore(state => state.setLoading)
  const user = useUserStore(state => state.user);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const navigate = useNavigate();
  const params = useParams();
  const canTickets = Common.canTicket(selectedProjectCategory?.id, user);

  const breadCrumbs = [
    { title: 'チケット検索' },
  ];
  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<SearchParams>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      // area_id: null,
      // block_id: null,
      keyword: null
    }
  })

  // const selectedAreaId = watch('area_id')
  // const selectedBlockId = watch('block_id')
  const [keyword, setKeyword] = useState<string | null>(null)

  const [tickets, setTickets] = useState<Ticket[]>()
  // const [areaList, setAreaList] = useState<Area[]>([])
  // const [filteredBlocks, setFilteredBlocks] = useState<Block[]>([])

  const onSubmit = ( data: SearchParams ) => {
    setLoading(true)
    searchTicket(Number(params.projectCategoryId), data.keyword).then(result => {
      setTickets(result.data.tickets)
      // setAreaList(result.data.area_list)
    }).catch(error => {
    }).finally(() => {
      setLoading(false)
      setKeyword(data.keyword)
    })
  }

  const onClickReset = ( e: any ) => {
    e.preventDefault()
    reset()
  }

  useEffect(() => {
    if (canTickets < Config.RESTRICTION_HISTORY_TICKET_VIEW.value) {
      console.log("Ticket")
      navigate('/progress');
    }
  }, [])

  // useEffect(() => {
  //   const selectedArea = areaList.find(( area ) => area.id == selectedAreaId)
  //   const blocks = selectedArea ? selectedArea.block_list : []
  //   setFilteredBlocks(blocks)
  //   setValue("block_id", null)
  // }, [selectedAreaId])

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs}/>
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="condition-tbl">
            <tbody>
            {/*<tr className="header">*/}
            {/*  <td colSpan={4}>エリア</td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td colSpan={4}>*/}
            {/*    <div className="uk-flex">*/}
            {/*      <select className={`${selectedAreaId ? '' : 'placeholder'}`} {...register("area_id")}>*/}
            {/*        <option value="">問合せ区分1</option>*/}
            {/*        {areaList.map(( o, index ) => {*/}
            {/*          return <option key={index} value={o.id}>{o.name}</option>*/}
            {/*        })}*/}
            {/*      </select>*/}
            {/*      <select*/}
            {/*        className={`${selectedBlockId ? '' : 'placeholder'} uk-margin-left`} {...register("block_id")}>*/}
            {/*        <option value="">問合せ区分2</option>*/}
            {/*        {filteredBlocks.map(( o, index ) => {*/}
            {/*          return <option key={index} value={o.id}>{o.name}</option>*/}
            {/*        })}*/}
            {/*      </select>*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            <tr className="header">
              <td colSpan={4}>キーワード</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div className="uk-flex">
                  <input className="uk-width-1-4" {...register("keyword")}/>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-flex uk-flex-center">
                  <button className="uk-button--m uk-button-refer" type="submit">検索</button>
                  <button className="uk-button--m uk-button-cancel uk-margin-left"
                          onClick={( e ) => onClickReset(e)}>クリア
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div className="container">
        <div className="ticket-search-result">
          {tickets?.map(( ticket, index ) => (
            <div key={index} className="ticket-search-result-item">
              <Link to={`/progress/${params.projectCategoryId}/${ticket.data_id}/ticket`}>{ticket.link_value}</Link>
              <div>問合せ区分1：{ticket.area_name}</div>
              <div>問合せ区分2：{ticket.block_name}</div>
              <div className="uk-margin-small-top">
                <HighlightKeywords text={ticket.comment} keyword={keyword}/>
              </div>
              <hr/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

type HighlightKeywordsProps = {
  text: string;
  keyword: string | null;
};

const HighlightKeywords: React.FC<HighlightKeywordsProps> = ( { text, keyword } ) => {

  const keywords = keyword ? keyword.split(/[\s　]+/) : []
  const escapeRegExp = ( str: string ) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const keywordsRegex = new RegExp(keywords.map(escapeRegExp).join('|'), 'gi');

  const parts = text.split(keywordsRegex);
  return (
    <>
      {parts.map(( part, index ) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && (
            <span className="uk-text-danger">{text.match(keywordsRegex)?.[index]}</span>
          )}
        </React.Fragment>
      ))}
    </>
  );
};