import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useLoadingStore} from '@/stores/loading'
import {useProjectStore} from '@/stores/project'
import {useUserStore} from '@/stores/user'
import {useForm, useWatch} from 'react-hook-form'
import {DataDocumentInputs, InputFile} from '@/components/Form/DataDocuments'
import {axios} from '@/lib/axios'
import * as Common from '@/utils/common'
import * as Config from '@/config'
import moment from 'moment'
import CustomSelect from '@/components/CustomSelect'
import {Document, Page} from 'react-pdf'
import {useBlockerStore} from '@/stores/blocker'
import BlockModal from '@/components/BlockModal'
import ConfirmModal from '@/components/ConfirmModal'
import {useMessageModalStore} from '@/stores/message'
import {MESSAGE_NO_E32, MESSAGE_NO_E37} from '@/config'

export default function FileEdit() {
  const hiddenFileInput = useRef<HTMLInputElement>(null); // ファイル選択ボタンの装飾

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory,
  ]);
  const user = useUserStore(state => state.user);
  const [isBlocker, setBlocker] = useBlockerStore(state => [state.isBlocker, state.setBlocker]);
  const {
    register,
    reset,
    getValues,
    clearErrors,
    setError,
    control,
    setValue,
    formState: {errors}
  } = useForm();

  const [inputFile, setInputFile] = useState<InputFile>();
  const [dataDocument, setDataDocument] = useState<any | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isCancelEditConfirm, setIsCancelEditConfirm] = useState(false);
  const [isFileChange, setIsFileChange] = useState(false);

  function getInputs() {
    setLoading(true);
    axios.get('/api/v1/dataDocuments/inputs', {
      params: {
        data_documents_id: params.fileId,
      }
    }).then(result => {
      const dataDocument = result.data.dataDocument;
      const s3Urls = result.data.s3Urls;
      dataDocument["s3FilePath"] = s3Urls[dataDocument["id"]]["filepath"];
      setDataDocument(dataDocument);
      reset({
        filename: dataDocument.filefilename,
        document_name: dataDocument.document_name,
        release_date: dataDocument.release_date,
        end_date: dataDocument.end_date,
        remarks: dataDocument.remarks,
      })
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  const uploadFile = async (inputFile: InputFile, formAttributes: any, formInputs: any[]) => {
    const formData = new FormData();
    Object.entries(formInputs).forEach(([key, value]) => {
      if (key === "key") {
        formData.append(key, value + inputFile.tempPath);
      } else formData.append(key, value);
    });
    formData.append('file', inputFile.file);

    const response = await axios.post(formAttributes.action, formData,  {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  };

  async function post() {
    setErrorMessage("");
    clearErrors();
    setLoading(true);
    const inputData = getValues();
    let fileData = {};
    if (inputFile) {
      console.log(Common.byteConverter(inputFile.file.size, "MB"))
      if (Common.byteConverter(inputFile.file.size, "MB") > 500) {
        setErrorMessage(Config.MESSAGE_NO_E40);
        setLoading(false);
        return
      }

      try {
        const s3 = await axios.get('/api/v1/s3/getSignedPostUrl', {}).then(result => {
          return result.data;
        });
        const results = await uploadFile(inputFile, s3.formAttributes, s3.formInputs);
      } catch (error) {
        setErrorMessage("アップロードに失敗しました。");
        setLoading(false);
        return
      }
      fileData = {
        filename: inputFile.file.name,
        original_filename: inputFile.file.name,
        tempPath: inputFile.tempPath,
      };
    }
    const postData = {
      id: params.fileId,
      isUpload: inputFile !== undefined,
      data_document: {
        ...inputData,
        ...fileData,
      },
    };
    const result = await axios.post('/api/v1/dataDocuments/edit', postData
    ).then(result => {
      return result.data.result;
    }).catch(error => {
      if (error?.response?.data?.errors) {
        const errors = error?.response?.data?.errors;
        setErrorMessage("入力エラーがあります");
        for (const key of Object.keys(errors)) {
          setError(`${key}`, {type: 'sever', message: Common.getNestedValue(errors[key])})
        }
      } else if (error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setMessage(Config.MESSAGE_NO_E37);
      }
      return false;
    });
    setLoading(false);
    if (result)
      navigate("/admin/document");
  }

  useEffect(() => {
    getInputs();
    setBlocker(Config.isDataEdit(Config.Data.FileEdit));
  }, [location.pathname]);

  const onClickCancel = () => {
    setBlocker(false);
    setIsCancelEditConfirm(true);
  }

  const onClickSave = () => {
    setBlocker(false);
    post();
  }

  const onClickFileSelect = () => {
    if (hiddenFileInput.current)
      hiddenFileInput.current.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files)
      setUploadInfo(e.target.files[0]);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files)
      setUploadInfo(e.dataTransfer.files[0]);
  };

  const setUploadInfo = (file: File) => {
    const name = `${user?.last_name}${user?.first_name}`;
    const date = new Date();

    const inputFile: InputFile = {
      file: file,
      tempPath: file.name + "_" + Math.random().toString(36).substring(2, 15),
      preview: URL.createObjectURL(file),
    };

    setInputFile(inputFile);
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="sub-header">
        <div className="uk-flex-between uk-flex uk-flex-middle uk-margin-small-top">
          <div className="uk-inline">
          </div>
          <div>
            <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickCancel}>キャンセル</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
          </div>
        </div>
      </div>
      <div className="container">
        {errorMessage.length > 0 && <div className="error-box">{errorMessage}</div>}
        <div className="file-tbl-wrap uk-margin-small-top">
          {dataDocument &&
              <div className="file-tbl">
                  <table>
                      <tr>
                          <th rowSpan={5} className="media">
                              <div
                                  onDrop={onDrop}
                                  onDragOver={onDragOver}
                                  className="media_flex"
                              >
                                  <button className="uk-button--m uk-button-cancel" onClick={() => setIsFileChange(true)}>ファイル選択</button>
                                  <input type="file" className="uk-hidden" onChange={onFileChange} ref={hiddenFileInput} />
                              </div>
                          </th>
                          <th className="label">ファイル名</th>
                          <td className="content">{inputFile ? "自動取得" : dataDocument.filename}</td>
                          <th className="label">元ファイル名</th>
                          <td className="content">{inputFile ? inputFile.file.name : dataDocument.original_filename}</td>
                      </tr>
                      <tr>
                          <th className="label">資料名<span className="error">※</span></th>
                          <td colSpan={3} className="content">
                              <input
                                  className={`uk-width-1-1 ${errors.document_name ? "error-form" : ""}`}
                                  {...register(`document_name`)}
                              />
                            {errors.document_name && <div className="error">{`${errors.document_name?.message}`}</div>}
                          </td>
                      </tr>
                      <tr>
                          <th className="label">更新年月日（公開日）<span className="error">※</span></th>
                          <td className="content">
                              <input
                                  type="date"
                                  className={`uk-width-1-1 ${errors.release_date ? "error-form" : ""}`}
                                  {...register(`release_date`)}
                              />
                            {errors.release_date && <div className="error">{`${errors.release_date?.message}`}</div>}
                          </td>
                          <th className="label">公開終了日</th>
                          <td className="content">
                              <input
                                  type="date"
                                  className={`uk-width-1-1 ${errors.end_date ? "error-form" : ""}`}
                                  {...register(`end_date`)}
                              />
                            {errors.end_date && <div className="error">{`${errors.end_date?.message}`}</div>}
                          </td>
                      </tr>
                      <tr>
                          <th className="label">更新日時</th>
                          <td className="content">{moment(dataDocument.modified).format(Config.DATE_FORMAT)}</td>
                          <th className="label">更新者</th>
                          <td className="content">{dataDocument.modified_user && `${dataDocument.modified_user.last_name}${dataDocument.modified_user.first_name}`}</td>
                      </tr>
                      <tr>
                          <th className="label">備考</th>
                          <td colSpan={3} className="content">
                              <textarea
                                  className={`uk-width-1-1 ${errors.remarks ? "error-form" : ""}`}
                                  {...register(`remarks`)}
                              />
                            {errors.remarks && <div className="error">{`${errors.remarks.message}`}</div>}
                          </td>
                      </tr>
                  </table>
              </div>
          }
        </div>
      </div>
      <BlockModal />
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="OK"
        isShow={isCancelEditConfirm}
        onConfirm={() => {
          setIsCancelEditConfirm(false);
          navigate("/admin/document");
        }}
        onCancel={() => {
          setIsCancelEditConfirm(false);
          setBlocker(true);
        }}
      />
      <ConfirmModal
        text={Config.MESSAGE_NO_E32}
        confirmButtonText="OK"
        isShow={isFileChange}
        onConfirm={() => {
          onClickFileSelect();
          setIsFileChange(false);
        }}
        onCancel={() => {
          setIsFileChange(false);
        }}
      />
    </div>
  );
}