import React, { ChangeEvent, useEffect, useState } from 'react';
import { Control, DeepMap, FieldError, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import { GroupInfo } from "@/containers/admin/Groups/GroupEdit";

interface Block {
  id: number;
  code: string;
  name: string;
  checked: boolean;
}

interface Area {
  id: number;
  code: string;
  name: string;
  checked: boolean;
  blocks: Block[];
}

type option = Area | Block;

interface props {
  name: string;
  areaName?: string;
  options: option[] | undefined;
  register: UseFormRegister<any>;
  rules?: Record<string, any>;
  setValue: UseFormSetValue<any>;
  errors: DeepMap<any, FieldError>;
  control?: Control<GroupInfo, any>
}

export const GroupsCheckBox: React.FC<props> = ( {
                                                   name,
                                                   areaName,
                                                   options,
                                                   register,
                                                   rules,
                                                   errors,
                                                   setValue,
                                                   control
                                                 } ) => {
  const [allSelected, setAllSelected] = useState(false);

  //optionsがareaか判定
  const isArea = ( option: option ): option is Area => {
    return (option as Area).blocks !== undefined;
  }

  // 一括選択ボタン処理
  const toggleAll = () => {
    const newAllSelected = !allSelected;
    setAllSelected(newAllSelected);
    options?.forEach(( option, index ) => {
      setValue(`${name}.${index}.checked`, newAllSelected);
    });
  };

  //areaがfalseになった時、紐付くblock.checkedの値をfalseにする
  const onChangeArea = ( e: ChangeEvent<HTMLInputElement>, index: number, option: option ) => {
    const { onChange } = register(`${name}.${index}.checked`, rules);
    onChange(e);
    const onChecked = e.target.checked
    if (isArea(option) && !onChecked) {
      option?.blocks.forEach(( o, index2 ) => {
        setValue(`${name}.${index}.blocks.${index2}.checked`, false);
      });
    }
  }

  return (
    <>
      {areaName ?
        <div className="uk-grid-small uk-grid">
          <div>{areaName}</div>
          <label>
            <div><input type="checkbox" checked={allSelected} onChange={toggleAll}/></div>
            一括選択
          </label>
        </div>
        :
        <label>
          <div><input type="checkbox" checked={allSelected} onChange={toggleAll}/></div>
          一括選択
        </label>
      }

      <div className="uk-grid-small uk-grid mt-5">
        {options?.map(( option, index ) => {
          return (
            <label key={index}>
              <div>
                <input
                  type="checkbox"
                  {...register(`${name}.${index}.checked`, rules)}
                  onChange={e => onChangeArea(e, index, option)}
                />
              </div>
              {option.code}:{option.name}
            </label>
          )
        })}
      </div>
    </>
  );
};